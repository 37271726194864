import { createSlice } from "@reduxjs/toolkit";
import {
  getStoresApi,
  getStoreInfoApi,
  updateStoreInfoApi,
  createStoreApi,
  updateFetchSwitchApi,
  itemIdFileFormatApi,
  getSourcingSetupApi,
  updateSourcingSetupApi,
  // updateStoreSyncSwitchApi,
  deleteStoreApi,
  editStoreInfoApi,
  updateStoreListingDescriptionTemplateApi
} from "./storesApi";

const initialState = {
  updateStatus: "",
  createStoreStatus: "",
  fetchStatus: "",
  itemIdFileFormatStatus: "",
  totalStoresResults: 0,
  storeIdPairs: [],
  stores: [],
  storesStatus: "",
  deleteStatus: "",
  updateStoreSourcingSetupStatus: "",
  storeSourcingSetup: {},
  storeInfo: {},
  updateStoreListingDescriptionTemplateStatus: ","
};

const storesSlice = createSlice({
  name: "storesSlice",
  initialState,
  reducers: {
    resetStoreInfo: (state) => {
      state.storeInfo = {};
      state.storeSourcingSetup = {};
      state.updateStoreSourcingSetupStatus = "";
    }
  },
  extraReducers: (builder) => {
    builder
      // createStoreApi
      .addCase(createStoreApi.pending, (state) => {
        state.createStoreStatus = "pending";
      })
      .addCase(createStoreApi.fulfilled, (state, { payload }) => {
        state.createStoreStatus = "fulfilled";
      })
      .addCase(createStoreApi.rejected, (state) => {
        state.createStoreStatus = "rejected";
      })
      // getStoresApi
      .addCase(getStoresApi.pending, (state) => {
        state.storesStatus = "pending";
      })
      .addCase(getStoresApi.fulfilled, (state, { payload }) => {
        if (payload.data.type === "id-pairs") {
          state.storeIdPairs = payload.data.stores;
        } else {
          state.stores = payload.data.stores;
          state.totalStoresResults = payload.data.totalStoresResults;
          state.storesStatus = "fulfilled";
        }
      })
      // getStoreInfoApi
      .addCase(getStoreInfoApi.fulfilled, (state, { payload }) => {
        if (payload) {
          state.storeInfo = payload.data;
        }
      })
      // updateStoreInfoApi
      .addCase(updateStoreInfoApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateStoreInfoApi.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateStoreInfoApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // editStoreInfoApi
      .addCase(editStoreInfoApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(editStoreInfoApi.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(editStoreInfoApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // deleteStoreApi
      .addCase(deleteStoreApi.pending, (state) => {
        state.deleteStatus = "pending";
      })
      .addCase(deleteStoreApi.fulfilled, (state) => {
        state.deleteStatus = "fulfilled";
      })
      .addCase(deleteStoreApi.rejected, (state) => {
        state.deleteStatus = "rejected";
      })
      // updateFetchSwitchApi
      .addCase(updateFetchSwitchApi.pending, (state) => {
        state.fetchStatus = "pending";
      })
      .addCase(updateFetchSwitchApi.fulfilled, (state, { payload }) => {
        state.fetchStatus = "fulfilled";
      })
      .addCase(updateFetchSwitchApi.rejected, (state) => {
        state.fetchStatus = "rejected";
      })
      // itemIdFileFormatApi
      .addCase(itemIdFileFormatApi.pending, (state) => {
        state.itemIdFileFormatStatus = "pending";
      })
      .addCase(itemIdFileFormatApi.fulfilled, (state) => {
        state.itemIdFileFormatStatus = "fulfilled";
      })
      .addCase(itemIdFileFormatApi.rejected, (state) => {
        state.itemIdFileFormatStatus = "rejected";
      })
      // getSourcingSetup
      .addCase(getSourcingSetupApi.fulfilled, (state, { payload }) => {
        state.storeSourcingSetup = payload.data.sourcingSetup;
      })
      // updateSourcingSetupApi
      .addCase(updateSourcingSetupApi.pending, (state) => {
        state.updateStoreSourcingSetupStatus = "pending";
      })
      .addCase(updateSourcingSetupApi.fulfilled, (state) => {
        state.updateStoreSourcingSetupStatus = "fulfilled";
      })
      .addCase(updateSourcingSetupApi.rejected, (state) => {
        state.updateStoreSourcingSetupStatus = "rejected";
      })
      // updateStoreListingDescriptionTemplateApi
      .addCase(updateStoreListingDescriptionTemplateApi.pending, (state) => {
        state.updateStoreListingDescriptionTemplateStatus = "pending";
      })
      .addCase(updateStoreListingDescriptionTemplateApi.fulfilled, (state) => {
        state.updateStoreListingDescriptionTemplateStatus = "fulfilled";
      })
      .addCase(updateStoreListingDescriptionTemplateApi.rejected, (state) => {
        state.updateStoreListingDescriptionTemplateStatus = "rejected";
      });
  },
});

export const { resetStoreInfo } = storesSlice.actions;
export default storesSlice.reducer;
