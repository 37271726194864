import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  IconButton,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  Fab,
  Autocomplete,
  Switch
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  DataGrid,
} from "@mui/x-data-grid";
import { MuiFileInput } from 'mui-file-input';

import {
  Delete,
  ArrowBackIosNew,
  Autorenew,
  Download,
  Close,
  EditOutlined
} from "@mui/icons-material";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";

import {
  listingUploadCreationApi,
  replaceCreationUploadApi,
  getCreationUploadsApi,
  deleteCreationUploadApi,
  itemSpecificsUploadApi,
  getItemTitleFormatsApi,
  addItemTitleFormatsApi,
  deleteItemTitleFormatsApi,
  getItemPartNamesApi,
  getItemPartNamesFileApi,
  getCategoryToPartNameMappingApi,
  updateCategoryToPartNameMappingApi,
  getItemPartNamesToCategoryMappingFileApi,
  deleteCategoryToPartNameMappingApi,
  itemSpecificsUploadFormatApi,
  getSkuMappingInfoApi,
  uploadSkuMappingApi,
  getInventoryCreationInfoApi,
  inventoryCreationFileAnalyzerStartApi,
  downloadInventoryCreationItemsApi,
  externallyAssignedProductIdentifiersUploadApi,
  externallyAssignedProductIdentifiersDownloadApi
} from "features/listings/listingsApi";
import {
  getCategoryApi
} from "features/marketplaces/marketplacesApi";
import { getStoresApi } from "features/stores/storesApi";

import {
  resetCategories
} from "features/marketplaces/marketplacesSlice";


const ListingCreationSettings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    listingUploadCreationStatus,
    replaceUploadCreationStatus,
    getUploadCreationStatus,
    deleteUploadCreationStatus,
    creationUploads,
    itemSpecificsLastUploadDate,
    skuMappingLastUploadDate,
    skuMappingUploadStatus,
    itemSpecificsUploadStatus,
    titleFormats,
    getItemTitleFormatStatus,
    addItemTitleFormatStatus,
    // deleteItemTitleFormatStatus,
    itemPartNames,
    getItemPartNamesFileStatus,
    getItemPartNamesToCategoryMappingFileStatus,
    updateCategoryToPartNameMappingStatus,
    deleteCategoryToPartNameMappingStatus,
    categoryToPartNameMappings,
    inventoryCreationLastUploadDate,
    downloadInventoryCreationItemsStatus,
    downloadIdentifiersItemsStatus
  } = useSelector((state) => state.listings);
  const {
    categories,
    subCategories,
    categoryParentId,
    categoryName,
    leafCategory
  } = useSelector((state) => state.marketplace);

  const [itemsFile, setItemsFile] = useState(null);
  const [itemSpecificsFile, setItemSpecificsFile] = useState(null);
  const [skuMappingFile, setSkuMappingFile] = useState(null);
  const [skuMappingFileTypeMultiple, setSkuMappingFileTypeMultiple] = useState(false);

  const [identifiersFile, setIdentifiersFile] = useState(null);
  const [inventoryCreationFile, setInventoryCreationFile] = useState(null);

  const [selectedTitleFormatCategory, setSelectedTitleFormatCategory] = useState("");
  const [newTitleFormat, setNewTitleFormat] = useState("");
  const [selectedUpload, setSelectedUpload] = useState("");

  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [categoryToPartNameMapping, setCategoryToPartNameMapping] = useState({
    category: "",
    partNames: []
  });

  const [inputValue2, setInputValue2] = useState('');
  const [filteredOptions2, setFilteredOptions2] = useState([]);
  const [selectedCategoryMapping, setSelectedCategoryMapping] = useState({
    category: "",
    partNames: []
  });

  const [selectedDeleteTitleFormat, setSelectedDeleteTitleFormat] = useState("");
  
  const [uploadItemSpecificsDialogOpen, setUploadItemSpecificsDialogOpen] = useState(false);

  const [identifiersFileUploadDialogOpen, setIdentifiersFileUploadDialogOpen] = useState(false);
  const [inventoryCreationFileUploadDialogOpen, setInventoryCreationFileUploadDialogOpen] = useState(false);

  const [uploadItemsFileDialogOpen, setUploadItemsFileDialogOpen] = useState(false);
  const [replaceUploadedFileDialogOpen, setReplaceUploadedFileDialogOpen] = useState(false);
  const [deleteUploadedFileDialogOpen, setDeleteUploadedFileDialogOpen] = useState(false);

  const [addTitleFormatsDialogOpen, setAddTitleFormatsDialogOpen] = useState(false);
  const [deleteTitleFormatsDialogOpen, setDeleteTitleFormatsDialogOpen] = useState(false);

  const [saveCategoryMappingDialogOpen, setSaveCategoryMappingDialogOpen] = useState(false);
  const [updateCategoryMappingDialogOpen, setUpdateCategoryMappingDialogOpen] = useState(false);
  const [deleteCategoryMappingDialogOpen, setDeleteCategoryMappingDialogOpen] = useState(false);
  const [uploadSkuMappingDialogOpen, setUploadSkuMappingDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getStoresApi({ type: 'id-pairs', marketplace: "ebay" }));

    dispatch(getCategoryApi({ categoryId: "" }));
    dispatch(getItemTitleFormatsApi());
    dispatch(getCreationUploadsApi());
    dispatch(getItemPartNamesApi());
    dispatch(getCategoryToPartNameMappingApi());
    dispatch(getSkuMappingInfoApi());
    dispatch(getInventoryCreationInfoApi());
  }, []);

  useEffect(() => {
    if (selectedTitleFormatCategory && !leafCategory) {
      dispatch(getCategoryApi({
        categoryId: selectedTitleFormatCategory
      }));
    }
  }, [selectedTitleFormatCategory]);

  useEffect(() => {
    if (categoryToPartNameMapping.category && !leafCategory) {
      dispatch(getCategoryApi({
        categoryId: categoryToPartNameMapping.category
      }));
    }
  }, [categoryToPartNameMapping]);

  useEffect(() => {
    if (replaceUploadCreationStatus !== "pending") {
      dispatch(getCreationUploadsApi());
    }
  }, [replaceUploadCreationStatus]);

  useEffect(() => {
    if (deleteUploadCreationStatus !== "pending") {
      dispatch(getCreationUploadsApi());
    }
  }, [deleteUploadCreationStatus]);

  useEffect(() => {
    if (listingUploadCreationStatus !== "pending") {
      dispatch(getCreationUploadsApi());
    }
  }, [listingUploadCreationStatus]);

  useEffect(() => {
    if (updateCategoryToPartNameMappingStatus !== "pending") {
      dispatch(getCategoryToPartNameMappingApi());
    }
  }, [updateCategoryToPartNameMappingStatus]);

  useEffect(() => {
    if (deleteCategoryToPartNameMappingStatus !== "pending") {
      dispatch(getCategoryToPartNameMappingApi());
    }
  }, [deleteCategoryToPartNameMappingStatus]);

  useEffect(() => {
    // Simulate fetching options from the server for the first Autocomplete
    if (inputValue.length > 2) {

      const filtered = itemPartNames.filter((option) =>
        option?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );

      setFilteredOptions(filtered);
    }
  }, [inputValue]);

  useEffect(() => {
    // Simulate fetching options from the server for the second Autocomplete
    if (inputValue2.length > 2) {

      const filtered = itemPartNames.filter((option) =>
        option?.toLowerCase()?.includes(inputValue2?.toLowerCase())
      );

      setFilteredOptions2(filtered);
    }
  }, [inputValue2]);

  const handleBackCategory = (type) => {
    dispatch(getCategoryApi({
      categoryId: categoryParentId
    }));

    if (type === "titleFormat") {
      setSelectedTitleFormatCategory(categoryParentId);
    } else if (type === "categoryToPartNameMapping") {
      setCategoryToPartNameMapping({
        ...categoryToPartNameMapping,
        category: categoryParentId
      })
    }
  };

  const handleTitleFormatsDialogClose = () => {
    setSelectedTitleFormatCategory("");
    setNewTitleFormat("");

    dispatch(resetCategories());
    dispatch(getCategoryApi({ categoryId: "" }));
  };

  const handleCategoriesDialogClose = () => {
    setCategoryToPartNameMapping({
      category: "",
      partNames: []
    });

    setSelectedCategoryMapping({
      category: "",
      partNames: []
    });

    dispatch(resetCategories());
    dispatch(getCategoryApi({ categoryId: "" }));
  };

  const handleEditTitleFormat = (categoryId) => {
    setSelectedTitleFormatCategory(categoryId);
    setNewTitleFormat(titleFormats?.find(obj => obj.categoryId === categoryId)?.format || "");
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title="Listing Creation Settings" subtitle="Edit And Manage Your Listing Creation Settings" />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "85%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Supplier SKU Mapping" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Typography fontWeight="bold" variant="h4" p="3px">
              Select SKU To Partslink Mapping File.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "25px",
                  justifyContent: "space-between"
                }}
              >
                {skuMappingLastUploadDate &&
                  <TextField
                    label="Last SKU Mapping Single Upload Date"
                    defaultValue={skuMappingLastUploadDate.skuMappingSingle}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: "48%"
                    }}
                    color="secondary"
                  />
                }
                {skuMappingLastUploadDate &&
                  <TextField
                    label="Last SKU Mapping Multiple Upload Date"
                    defaultValue={skuMappingLastUploadDate.skuMappingMultiple}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: "48%"
                    }}
                    color="secondary"
                  />
                }
              </Box>
              <Box>
                <Typography variant="h6" p="3px">{`Select SKU Mapping File To Upload`}</Typography>
                <FormControlLabel
                  control={<Switch
                    color="secondary"
                    checked={skuMappingFileTypeMultiple}
                    onChange={() => setSkuMappingFileTypeMultiple(!skuMappingFileTypeMultiple)}
                  />}
                  label="File For Set's and Kit's"
                  sx={{
                    margin: "15px 0"
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "25px"
                  }}
                >
                  <MuiFileInput
                    value={skuMappingFile}
                    onChange={(file) => setSkuMappingFile(file)}
                    sx={{
                      width: "70%"
                    }}
                    color="secondary"
                  />
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    sx={{
                      width: "30%"
                    }}
                    loading={skuMappingUploadStatus === "pending"}
                    onClick={() => setUploadSkuMappingDialogOpen(true)}
                  >
                    Upload File
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Items File Uploads" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Typography fontWeight="bold" variant="h4" p="3px">
              Manage Item Upload Files
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "25px"
              }}
            >
              <MuiFileInput
                color="secondary"
                value={itemsFile}
                onChange={(file) => setItemsFile(file)}
                sx={{
                  width: "70%"
                }}
              />
              <LoadingButton
                variant="contained"
                color="secondary"
                sx={{
                  width: "30%"
                }}
                loading={listingUploadCreationStatus === "pending"}
                onClick={() => setUploadItemsFileDialogOpen(true)}
              >
                Upload File
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                padding: "15px 0",
                gap: "25px"
              }}
            >
              <Box
                height="380px"
                sx={{
                  marginTop: "1rem",
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: theme.palette.primary[600],
                    color: theme.palette.secondary[100],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    backgroundColor: theme.palette.primary[600],
                    color: theme.palette.secondary[100],
                    borderTop: "none",
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${theme.palette.secondary[200]} !important`,
                  },
                }}
              >
                <DataGrid
                  sx={{
                    "& .MuiDataGrid-cell": {
                      borderBottom: `1px solid ${theme.palette.secondary[200]} !important`,
                    },
                    "& .MuiDataGrid-virtualScrollerContent ": {
                      background: theme.palette.primary[600]
                    },
                    "& .MuiDataGrid-cell:focus": {
                      outline: `none !important`,
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-checkboxInput": {
                      color: `${theme.palette.secondary[400]} !important`,
                    },
                  }}
                  getRowId={(row) => row.id}
                  rows={creationUploads || []}
                  columns={[
                    {
                      field: "name",
                      headerName: "File",
                      flex: 1.5,
                    },
                    {
                      field: "uploadedDate",
                      headerName: "Uploaded Date",
                      flex: 1,
                    },
                    {
                      field: "updatedDate",
                      headerName: "Updated Date",
                      flex: 1,
                    },
                    {
                      field: "actions",
                      headerName: "Actions",
                      flex: 1,
                      renderCell: (params) => (
                        <Box
                          sx={{
                            width: "100%",
                            '& > *': {
                              margin: '0 5px',
                            },
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setReplaceUploadedFileDialogOpen(true);
                              setSelectedUpload(params.row.id);
                            }}
                          >
                            <Autorenew sx={{
                              color: "#fff"
                            }} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setDeleteUploadedFileDialogOpen(true);
                              setSelectedUpload(params.row.id);
                            }}
                          >
                            <Delete sx={{
                              color: "#fff"
                            }} />
                          </IconButton>
                        </Box >
                      )
                    }
                  ]}
                  loading={getUploadCreationStatus === "pending"}
                  density="comfortable"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Item Specifics File Upload" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Typography fontWeight="bold" variant="h4" p="3px">
              Select Item Specifics Upload
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px"
              }}
            >
              {itemSpecificsLastUploadDate &&
                <TextField
                  id="outlined-read-only-input"
                  label="Last Item Specifics Upload Date"
                  defaultValue={itemSpecificsLastUploadDate}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  color="secondary"
                />
              }
              <Box>
                <Typography variant="h6" p="3px">{`Select Item Specifics File To Upload`}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "25px"
                  }}
                >
                  <MuiFileInput
                    value={itemSpecificsFile}
                    onChange={(file) => setItemSpecificsFile(file)}
                    sx={{
                      width: "70%"
                    }}
                  />
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    sx={{
                      width: "30%"
                    }}
                    loading={itemSpecificsUploadStatus === "pending"}
                    onClick={() => setUploadItemSpecificsDialogOpen(true)}
                  >
                    Upload File
                  </LoadingButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center"
                }}
              >
                <LoadingButton
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: "30%",
                    padding: "10px"
                  }}
                  endIcon={<Download />}
                  onClick={() => dispatch(itemSpecificsUploadFormatApi())}
                >
                  Format
                </LoadingButton>
                <Typography variant="h6" p="3px">Download file format of supplier</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title={<span> Categories To Part Name <br /> Mapping </span>} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Typography fontWeight="bold" variant="h4" p="3px">
              Manage Category To Part Name Mapping
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "25px",
              }}
            >
              <LoadingButton
                variant="contained"
                color="secondary"
                sx={{
                  width: "20%"
                }}
                loading={getItemPartNamesFileStatus === "pending"}
                onClick={() => dispatch(getItemPartNamesFileApi())}
                startIcon={<Download color="black" />}
              >
                Part Names
              </LoadingButton>
              <Typography fontWeight="medium" variant="body" p="3px">
                Contains all current parts names
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "25px",
              }}
            >
              <LoadingButton
                variant="contained"
                color="secondary"
                sx={{
                  width: "20%"
                }}
                loading={getItemPartNamesToCategoryMappingFileStatus === "pending"}
                onClick={() => dispatch(getItemPartNamesToCategoryMappingFileApi())}
                startIcon={<Download color="black" />}
              >
                Part Names
              </LoadingButton>
              <Typography fontWeight="medium" variant="body" p="3px">
                Contains all current category to parts name mappings
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px"
              }}
            >
              <Typography fontWeight="bold" variant="h6" p="3px" mb="-10px">
                {
                  `Current Category: ${subCategories.length > 0 ? categoryName : "eBay Motors"}`
                }
              </Typography>
              {subCategories.length < 1
                ?
                <FormControl sx={{ width: "80%" }}>
                  <InputLabel
                    id="select-categories-category-label"
                    color="secondary"
                  >Select Category</InputLabel>
                  <Select
                    color="secondary"
                    labelId="select-categories-category-label"
                    value={categoryToPartNameMapping.category}
                    renderValue={(id) => {
                      return categories?.find(obj => obj.id === id)?.name || "";
                    }}
                    label="Select Category"
                    onChange={(e) => setCategoryToPartNameMapping({
                      ...categoryToPartNameMapping,
                      category: e.target.value
                    })}
                  >
                    {categories.length &&
                      categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                :
                <Box
                  sx={{
                    display: "flex",
                    gap: "4rem",
                    alignItems: "center"
                  }}
                >
                  <FormControl sx={{ width: "80%" }}>
                    <InputLabel
                      id="select-categories-sub-categories-label"
                      color="secondary"
                    >Select Category</InputLabel>
                    <Select
                      color="secondary"
                      labelId="select-categories-sub-categories-label"
                      value={categoryToPartNameMapping.category}
                      renderValue={(id) => {
                        return subCategories?.find(obj => obj.id === id)?.name || "";
                      }}
                      label="Select Category"
                      onChange={(e) => setCategoryToPartNameMapping({
                        ...categoryToPartNameMapping,
                        category: e.target.value
                      })}                      >
                      {subCategories.length &&
                        subCategories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => handleBackCategory("categoryToPartNameMapping")}
                  >
                    <ArrowBackIosNew />
                  </Fab>
                </Box>
              }
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Autocomplete
                  sx={{
                    width: "80%"
                  }}
                  color="secondary"
                  multiple
                  options={inputValue ? filteredOptions : []}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                  value={categoryToPartNameMapping.partNames}
                  onChange={(event, selectedValues) => {
                    setCategoryToPartNameMapping((prevMapping) => ({
                      ...prevMapping,
                      partNames: selectedValues.map((option) => option)
                    }));
                  }}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Part Names"
                      placeholder="Part Names"
                      color="secondary"
                    />
                  )}
                />
              </Box>
              <LoadingButton
                variant="contained"
                color="secondary"
                sx={{
                  width: "20%"
                }}
                loading={updateCategoryToPartNameMappingStatus === "pending"}
                onClick={() => setSaveCategoryMappingDialogOpen(true)}
              >
                Save Mapping
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                padding: "15px 0",
                gap: "25px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <FormControl sx={{ width: "80%" }}>
                  <InputLabel
                    id="select-mapping-label"
                    color="secondary"
                  >Category Mappings</InputLabel>
                  <Select
                    color="secondary"
                    labelId="select-mapping-label"
                    value={selectedCategoryMapping.category}
                    renderValue={(selected) => {
                      return categoryToPartNameMappings.find(({ category }) => category === selected)?.categoryName || "";
                    }}
                    label="Category Mappings"
                    onChange={(e) => {
                      const partNames = categoryToPartNameMappings.find(({ category }) => category === e.target.value).partNames;

                      setSelectedCategoryMapping({
                        category: e.target.value,
                        partNames
                      });
                    }}
                  >
                    {categoryToPartNameMappings &&
                      categoryToPartNameMappings.map(({ category, categoryName }) => (
                        <MenuItem key={category} value={category}>
                          {categoryName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {
                  selectedCategoryMapping.category &&
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "20%"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "50%"
                      }}
                    >
                      <Fab
                        size="small"
                        color="secondary"
                        onClick={() => setSelectedCategoryMapping({
                          category: "",
                          partNames: []
                        })}
                      >
                        <Close />
                      </Fab>
                      <Fab
                        size="small"
                        color="secondary"
                        onClick={() => setDeleteCategoryMappingDialogOpen(true)}
                      >
                        <Delete />
                      </Fab>
                    </Box>
                  </Box>
                }
              </Box>
              {
                selectedCategoryMapping.category &&
                <Autocomplete
                  sx={{
                    width: "80%"
                  }}
                  color="secondary"
                  multiple
                  options={inputValue2 ? filteredOptions2 : []}
                  inputValue={inputValue2}
                  onInputChange={(event, newInputValue) => setInputValue2(newInputValue)}
                  value={selectedCategoryMapping.partNames}
                  onChange={(event, selectedValues) => {
                    setSelectedCategoryMapping((prevMapping) => ({
                      ...prevMapping,
                      partNames: selectedValues
                    }));
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Part Names"
                      placeholder="Part Names"
                      color="secondary"
                    />
                  )}
                />
              }
              {
                selectedCategoryMapping.category &&
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: "20%"
                  }}
                  loading={updateCategoryToPartNameMappingStatus === "pending"}
                  onClick={() => setUpdateCategoryMappingDialogOpen(true)}
                >
                  Update Mapping
                </LoadingButton>
              }
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Categories Title Formats" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Typography fontWeight="bold" variant="h4" p="3px">
              Manage Categories Title Formats
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px"
              }}
            >
              <Typography fontWeight="bold" variant="h6" p="3px">
                {
                  `Current Category: ${categoryToPartNameMappings.find(({ category }) => category === selectedTitleFormatCategory)?.categoryName || "None"}`
                }
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  id="select-category-label"
                  color="secondary"
                >Select Category</InputLabel>
                <Select
                  color="secondary"
                  labelId="select-category-label"
                  value={selectedTitleFormatCategory}
                  renderValue={(selected) => {
                    return categoryToPartNameMappings.find(({ category }) => category === selected)?.categoryName || "";
                  }}
                  label="Select Category"
                  onChange={(e) => setSelectedTitleFormatCategory(e.target.value)}
                >
                  {categoryToPartNameMappings &&
                    categoryToPartNameMappings.map(({ category, categoryName }) => (
                      <MenuItem key={category} value={category}>
                        {categoryName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                color="secondary"
                label="Enter Title Format"
                sx={{ width: '100%' }}
                value={newTitleFormat}
                onChange={(e) => setNewTitleFormat(e.target.value)}
              />
              <LoadingButton
                variant="contained"
                color="secondary"
                sx={{
                  width: "20%"
                }}
                loading={addItemTitleFormatStatus === "pending"}
                onClick={() => setAddTitleFormatsDialogOpen(true)}
              >
                Add Format
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                padding: "15px 0",
                gap: "25px"
              }}
            >
              <Box
                height="380px"
                sx={{
                  marginTop: "1rem",
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: theme.palette.primary[600],
                    color: theme.palette.secondary[100],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: theme.palette.primary.light,
                  },
                  "& .MuiDataGrid-footerContainer": {
                    backgroundColor: theme.palette.primary[600],
                    color: theme.palette.secondary[100],
                    borderTop: "none",
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${theme.palette.secondary[200]} !important`,
                  },
                }}
              >
                <DataGrid
                  sx={{
                    "& .MuiDataGrid-cell": {
                      borderBottom: `1px solid ${theme.palette.secondary[200]} !important`,
                    },
                    "& .MuiDataGrid-virtualScrollerContent ": {
                      background: theme.palette.primary[600]
                    },
                    "& .MuiDataGrid-cell:focus": {
                      outline: `none !important`,
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-checkboxInput": {
                      color: `${theme.palette.secondary[400]} !important`,
                    },
                  }}
                  getRowId={(row) => row.categoryId}
                  rows={titleFormats || []}
                  columns={[
                    {
                      field: "categoryName",
                      headerName: "Category",
                      flex: 1,
                    },
                    {
                      field: "format",
                      headerName: "Format",
                      flex: 5,
                    },
                    {
                      field: "actions",
                      headerName: "Actions",
                      flex: 1,
                      renderCell: (params) => (
                        <Box
                          sx={{
                            width: "100%",
                            '& > *': {
                              margin: '0 5px',
                            },
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setDeleteTitleFormatsDialogOpen(true);
                              setSelectedDeleteTitleFormat(params.row.categoryId);
                            }}
                          >
                            <Delete sx={{
                              color: "#fff"
                            }} />
                          </IconButton>
                          <IconButton
                            onClick={() => handleEditTitleFormat(params.row.categoryId)}
                          >
                            <EditOutlined sx={{
                              color: "#fff"
                            }} />
                          </IconButton>
                        </Box >
                      )
                    }
                  ]}
                  loading={getItemTitleFormatStatus === "pending"}
                  density="comfortable"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Inventory Upload Analyzer" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Typography fontWeight="bold" variant="h4" p="3px">
              Upload Inventory File
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px"
              }}
            >
              {inventoryCreationLastUploadDate &&
                <TextField
                  id="outlined-read-only-input"
                  label="Last Inventory Upload Date"
                  defaultValue={inventoryCreationLastUploadDate}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  color="secondary"
                />
              }
              <Box>
                <Typography variant="h6" p="3px">{`Select Inventory File To Upload`}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "25px"
                  }}
                >
                  <MuiFileInput
                    value={inventoryCreationFile}
                    onChange={(file) => setInventoryCreationFile(file)}
                    sx={{
                      width: "70%"
                    }}
                  />
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    sx={{
                      width: "30%"
                    }}
                    // loading={itemSpecificsUploadStatus}
                    onClick={() => setInventoryCreationFileUploadDialogOpen(true)}
                  >
                    Upload File
                  </LoadingButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center"
                }}
              >
                <LoadingButton
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: "30%",
                    padding: "10px"
                  }}
                  loading={downloadInventoryCreationItemsStatus === "pending"}
                  endIcon={<Download />}
                  onClick={() => dispatch(downloadInventoryCreationItemsApi())}
                >
                  Analyzed Data
                </LoadingButton>
                <Typography variant="h6" p="3px">Download Analyzed Data</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="External Identifiers" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Typography fontWeight="bold" variant="h4" p="3px">
              Upload Identifiers File {`(UPC, EAN)`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px"
              }}
            >
              <Box>
                <Typography variant="h6" p="3px">{`Select Identifiers File To Upload`}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "25px"
                  }}
                >
                  <MuiFileInput
                    value={identifiersFile}
                    onChange={(file) => setIdentifiersFile(file)}
                    sx={{
                      width: "70%"
                    }}
                  />
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    sx={{
                      width: "30%"
                    }}
                    onClick={() => setIdentifiersFileUploadDialogOpen(true)}
                  >
                    Upload File
                  </LoadingButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center"
                }}
              >
                <LoadingButton
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: "30%",
                    padding: "10px"
                  }}
                  loading={downloadIdentifiersItemsStatus === "pending"}
                  endIcon={<Download />}
                  onClick={() => dispatch(externallyAssignedProductIdentifiersDownloadApi())}
                >
                  Identifiers
                </LoadingButton>
                <Typography variant="h6" p="3px">Download Identifiers</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Items File Uploads" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
          </Box>
        </Box> */}
      </Box>
      <Dialog
        open={replaceUploadedFileDialogOpen}
        onClose={() => setReplaceUploadedFileDialogOpen(false)}
        aria-labelledby="dialog-creation"
        aria-describedby="dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400],
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography fontWeight="bold" variant="h4" p="3px" mb="-10px">
                Select File To Replace Upload
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                  padding: "15px 0",
                  gap: "25px"
                }}
              >
                <MuiFileInput
                  color="secondary"
                  value={itemsFile}
                  onChange={(file) => setItemsFile(file)}
                  sx={{
                    width: "100%"
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setReplaceUploadedFileDialogOpen(false)} color="secondary">
              Close
            </Button>
            <Button
              onClick={() => {
                dispatch(replaceCreationUploadApi({
                  file: itemsFile,
                  uploadId: selectedUpload
                }));

                dispatch(getCreationUploadsApi());

                setItemsFile(null);
                setSelectedUpload(null);
                setReplaceUploadedFileDialogOpen(false);
              }}
              color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <DialogBox
        open={uploadSkuMappingDialogOpen}
        onClose={() => setUploadSkuMappingDialogOpen(false)}
        onConfirm={() => {
          dispatch(uploadSkuMappingApi({ file: skuMappingFile, multiple: skuMappingFileTypeMultiple }));

          setSkuMappingFile(null);
          setSkuMappingFileTypeMultiple(false);
          setUploadSkuMappingDialogOpen(false);
        }}
        titleText="Upload Confirmation"
        messageText="Are you sure you wish to upload this mapping file?"
        confirmText="Confirm"
      />
      <DialogBox
        open={uploadItemsFileDialogOpen}
        onClose={() => setUploadItemsFileDialogOpen(false)}
        onConfirm={() => {
          dispatch(listingUploadCreationApi({
            file: itemsFile
          }));

          dispatch(getCreationUploadsApi());

          setItemsFile(null);
          setUploadItemsFileDialogOpen(false);
        }}
        titleText="Upload Items File Confirmation"
        messageText="Are you sure you wish to upload this file?"
        confirmText="Confirm"
      />
      <DialogBox
        open={deleteUploadedFileDialogOpen}
        onClose={() => setDeleteUploadedFileDialogOpen(false)}
        onConfirm={() => {
          dispatch(deleteCreationUploadApi(selectedUpload));

          setSelectedUpload(null);
          setDeleteUploadedFileDialogOpen(false);
        }}
        titleText="Delete Uploaded File Confirmation"
        messageText="Are you sure you wish to delete this uploaded file?"
        confirmText="Confirm"
      />
      <DialogBox
        open={uploadItemSpecificsDialogOpen}
        onClose={() => setUploadItemSpecificsDialogOpen(false)}
        onConfirm={() => {
          dispatch(itemSpecificsUploadApi({
            file: itemSpecificsFile,
          }));
          setItemSpecificsFile(null);
          setUploadItemSpecificsDialogOpen(false);
        }}
        titleText="Upload Item Specifics File Confirmation"
        messageText="Are you sure you wish to upload this item specifics file?"
        confirmText="Confirm"
      />
      <DialogBox
        open={saveCategoryMappingDialogOpen}
        onClose={() => setSaveCategoryMappingDialogOpen(false)}
        onConfirm={() => {
          dispatch(updateCategoryToPartNameMappingApi({ mapping: categoryToPartNameMapping }));

          handleCategoriesDialogClose(false);
          setSaveCategoryMappingDialogOpen(false);
        }}
        titleText="Save Confirmation"
        messageText="Are you sure you wish to save this mapping?"
        confirmText="Confirm"
      />
      <DialogBox
        open={updateCategoryMappingDialogOpen}
        onClose={() => setUpdateCategoryMappingDialogOpen(false)}
        onConfirm={() => {
          dispatch(updateCategoryToPartNameMappingApi({ mapping: selectedCategoryMapping }));

          handleCategoriesDialogClose(false);
          setUpdateCategoryMappingDialogOpen(false);
        }}
        titleText="Update Confirmation"
        messageText="Are you sure you wish to update this mapping?"
        confirmText="Confirm"
      />
      <DialogBox
        open={deleteCategoryMappingDialogOpen}
        onClose={() => setDeleteCategoryMappingDialogOpen(false)}
        onConfirm={() => {
          dispatch(deleteCategoryToPartNameMappingApi(selectedCategoryMapping.category));

          handleCategoriesDialogClose(false);
          setDeleteCategoryMappingDialogOpen(false);
        }}
        titleText="Delete Confirmation"
        messageText="Are you sure you wish to delete this mapping?"
        confirmText="Confirm"
      />
      <DialogBox
        open={addTitleFormatsDialogOpen}
        onClose={() => setAddTitleFormatsDialogOpen(false)}
        onConfirm={() => {
          dispatch(addItemTitleFormatsApi({
            categoryId: selectedTitleFormatCategory,
            format: newTitleFormat
          }));

          handleTitleFormatsDialogClose(false);
          setAddTitleFormatsDialogOpen(false);

          dispatch(getItemTitleFormatsApi());
        }}
        titleText="Add Confirmation"
        messageText="Are you sure you wish to add this title format?"
        confirmText="Confirm"
      />
      <DialogBox
        open={deleteTitleFormatsDialogOpen}
        onClose={() => setDeleteTitleFormatsDialogOpen(false)}
        onConfirm={() => {
          dispatch(deleteItemTitleFormatsApi({ categoryId: selectedDeleteTitleFormat }));

          setDeleteTitleFormatsDialogOpen(false);
          setSelectedDeleteTitleFormat("");

          dispatch(getItemTitleFormatsApi());
        }}
        titleText="Delete Confirmation"
        messageText="Are you sure you wish to delete this title format?"
        confirmText="Confirm"
      />
      <DialogBox
        open={inventoryCreationFileUploadDialogOpen}
        onClose={() => setInventoryCreationFileUploadDialogOpen(false)}
        onConfirm={() => {
          dispatch(inventoryCreationFileAnalyzerStartApi({
            file: inventoryCreationFile,
          }));

          setInventoryCreationFile(null);
          setInventoryCreationFileUploadDialogOpen(false);
        }}
        titleText="Upload Item Specifics File Confirmation"
        messageText="Are you sure you wish to upload this item specifics file?"
        confirmText="Confirm"
      />
      <DialogBox
        open={identifiersFileUploadDialogOpen}
        onClose={() => setIdentifiersFileUploadDialogOpen(false)}
        onConfirm={() => {
          dispatch(externallyAssignedProductIdentifiersUploadApi({
            file: identifiersFile,
          }));

          setIdentifiersFile(null);
          setIdentifiersFileUploadDialogOpen(false);
        }}
        titleText="Upload Identifiers File Confirmation"
        messageText="Are you sure you wish to upload this identifiers file?"
        confirmText="Confirm"
      />
    </Box >
  );
}

export default ListingCreationSettings;