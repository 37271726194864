import { createSlice } from "@reduxjs/toolkit";
import {
  getSuppliersApi,
  getSupplierInfoApi,
  inventoryFileFormatApi,
  inventoryUploadApi,
  fixedShippingFormatApi,
  fixedShippingUploadApi,
  updateShippingWeightsApi,
  getInventoryDataApi,
  deleteInventoryUploadApi,
  downloadInventoryUploadApi,
  updateSupplierInfoApi,
  searchItemsApi
} from "./suppliersApi";

const initialState = {
  supplierIdPairs: [],
  // suppliers
  totalSuppliers: 0,
  suppliers: [],
  suppliersStatus: "",
  // supplier edit
  supplierInfo: {
    weightShippingCost: []
  },
  updateStatus: "",
  inventoryFormatStatus: "",
  shippingFormatStatus: "",
  // inventory timeline
  totalSupplierInventoryResults: 0,
  supplierUploadedInventories: [],
  inventoriesStatus: "",
  deletedInventoryStatus: "",
  sourcingSetupStatus: "",
  items: [],
  totalItems: 0,
  searchItemsStatus: ""
};

const suppliersSlice = createSlice({
  name: "suppliersSlice",
  initialState,
  reducers: {
    resetSupplierInfo: (state) => {
      state.supplierInfo = {
        weightShippingCost: []
      };
    },
    resetItems: (state) => {
      state.items = [];
      state.totalItems = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      // getSuppliersApi
      .addCase(getSuppliersApi.pending, (state) => {
        state.suppliersStatus = "pending";
      })
      .addCase(getSuppliersApi.fulfilled, (state, { payload }) => {
        if (payload) {
          if (payload.data.type === "id-pairs") {
            state.supplierIdPairs = payload.data.suppliers;
          } else {
            state.totalSuppliers = payload.data.totalSuppliers;
            state.suppliers = payload.data.suppliers;
            state.suppliersStatus = "fulfilled";
          }
        }
      })
      // getSupplierInfoApi
      .addCase(getSupplierInfoApi.fulfilled, (state, { payload }) => {
        state.supplierInfo = payload.data;
      })
      // updateSupplierInfoApi
      .addCase(updateSupplierInfoApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateSupplierInfoApi.fulfilled, (state) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateSupplierInfoApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // inventoryFileFormatApi
      .addCase(inventoryFileFormatApi.pending, (state) => {
        state.inventoryFormatStatus = "pending";
      })
      .addCase(inventoryFileFormatApi.fulfilled, (state) => {
        state.inventoryFormatStatus = "fulfilled";
      })
      .addCase(inventoryFileFormatApi.rejected, (state) => {
        state.inventoryFormatStatus = "rejected";
      })
      // inventoryUploadApi
      .addCase(inventoryUploadApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(inventoryUploadApi.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(inventoryUploadApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // fixedShippingFormatApi
      .addCase(fixedShippingFormatApi.pending, (state) => {
        state.shippingFormatStatus = "pending";
      })
      .addCase(fixedShippingFormatApi.fulfilled, (state) => {
        state.shippingFormatStatus = "fulfilled";
      })
      .addCase(fixedShippingFormatApi.rejected, (state) => {
        state.shippingFormatStatus = "rejected";
      })
      // fixedShippingUploadApi
      .addCase(fixedShippingUploadApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(fixedShippingUploadApi.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(fixedShippingUploadApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // updateShippingWeightsApi
      .addCase(updateShippingWeightsApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateShippingWeightsApi.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateShippingWeightsApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // getInventoryDataApi
      .addCase(getInventoryDataApi.pending, (state) => {
        state.inventoriesStatus = "pending";
      })
      .addCase(getInventoryDataApi.fulfilled, (state, { payload }) => {
        state.supplierUploadedInventories = payload.data.supplierUploadedInventories;
        state.totalSupplierInventoryResults = payload.data.totalSupplierInventoryResults;
        state.inventoriesStatus = "fulfilled";
      })
      .addCase(getInventoryDataApi.rejected, (state) => {
        state.inventoriesStatus = "rejected";
      })
      // deleteInventoryUploadApi
      .addCase(deleteInventoryUploadApi.pending, (state) => {
        state.deletedInventoryStatus = "pending";
      })
      .addCase(deleteInventoryUploadApi.fulfilled, (state) => {
        state.deletedInventoryStatus = "fulfilled";
      })
      .addCase(deleteInventoryUploadApi.rejected, (state) => {
        state.deletedInventoryStatus = "rejected";
      })
      // downloadInventoryUploadApi
      .addCase(downloadInventoryUploadApi.fulfilled, (state) => {
      })
      // searchItemsApi
      .addCase(searchItemsApi.pending, (state) => {
        state.searchItemsStatus = "pending";
      })
      .addCase(searchItemsApi.fulfilled, (state, { payload }) => {
        state.searchItemsStatus = "fulfilled";

        state.items = payload.data.results;
        state.totalItems = payload.data.totalResults;
      })
      .addCase(searchItemsApi.rejected, (state) => {
        state.searchItemsStatus = "rejected";
      })
  },
});

export const { resetSupplierInfo, resetItems } = suppliersSlice.actions;
export default suppliersSlice.reducer;