import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import Header from "components/Header";
import DialogBox from "components/DialogBox";
import StoreForm from "components/StoreForm";

import { useDispatch, useSelector } from "react-redux";
import {
  getStoreInfoApi,
  editStoreInfoApi
} from "features/stores/storesApi";

const EditStore = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { storeInfo, updateStatus } = useSelector((state) => state.stores);

  const { storeId } = useParams();

  const [suppliers, setSuppliers] = useState([]);
  const [storeMarketplace, setStoreMarketplace] = useState([]);
  const [formValues, setFormValues] = useState({
    storeName: "",
    storeUsername: "",
    storeEmail: "",
    storePassword: "",
    storeRedirectUri: "",
    storeShipStationId: "",
  });

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getStoreInfoApi(storeId));
  }, []);

  useEffect(() => {
    if (storeInfo.storeName) {
      setFormValues({
        storeName: storeInfo.storeName,
        storeUsername: storeInfo.storeUsername,
        storeEmail: storeInfo.storeEmail,
        storePassword: "",
        storeRedirectUri: storeInfo.storeRedirectUri,
        storeShipStationId: storeInfo.storeShipStationId,
      });

      setStoreMarketplace(storeInfo.storeMarketplace);
      setSuppliers(storeInfo.suppliers.map((obj) => obj._id));
    }
  }, [storeInfo]);

  const handleFormSubmit = () => {
    dispatch(editStoreInfoApi({ storeId, info: { ...formValues, supplierIds: suppliers } }));
    setConfirmDialogOpen(false)
    navigate('/stores');
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={storeInfo.storeName ? `Edit ${storeInfo.storeName}` : "Edit Store"} subtitle="Edit Your Store" />
      <Box m="3rem 0">
        {formValues.storeName && (
          <StoreForm
            type={'edit'}
            formValues={formValues}
            setFormValues={setFormValues}
            suppliers={suppliers}
            setSuppliers={setSuppliers}
            setConfirmDialogOpen={setConfirmDialogOpen}
            storeStatus={updateStatus}
            confirmText={"Save"}
            passwordPlaceholder={"Password ( eBay store password | Keep empty if you dont want to change )"}
            storeMarketplace={storeMarketplace}
          />
        )}
      </Box>
      <DialogBox
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleFormSubmit}
        titleText="Save Confirmation"
        messageText="Are you sure you want to Save these changes?"
        confirmText="Save"
      />
    </Box>
  );
};

export default EditStore;