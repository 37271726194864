import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment-timezone";
import socket from "app/socket";

import {
  Box,
  Button,
  Fab,
  Checkbox,
  ListItemText,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MuiFileInput } from 'mui-file-input';
import {
  DataGrid,
  GridToolbarContainer
} from "@mui/x-data-grid";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import {
  Delete,
  Download,
  PlayArrow,
  Check,
  OpenInNewOutlined,
  Tune,
  ArrowCircleRight,
  ReportSharp,
  EditOutlined
} from "@mui/icons-material";

import Header from "components/Header";
import DialogBox from "components/DialogBox";
import ProgressBar from "components/ProgressBar";
import FlexBetween from "components/FlexBetween";

import { getStoresApi } from "features/stores/storesApi";
import {
  getAnalzyerInfoApi,
  updateAnalzyerInfoApi,
  initializeAnalyzerApi,
  getAnalyzerLogsApi,
  deleteAnalyzerLogApi,
  downloadAnalyzerReportApi,
  pushAnalyzerReportApi,
  getAnalzyerScheduleApi,
  updateAnalzyerScheduleApi,
  analyzerSpecificItemsFormatApi,
  downloadAnalyzerPushResultApi,
  itemDimensionsFileFormatApi,
  itemDimensionsUploadApi
} from "features/jobs/jobsApi";
import {
  getCategoryToPartNameMappingApi
} from "features/listings/listingsApi";

const Analyzer = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { storeIdPairs } = useSelector((state) => state.stores);
  const {
    deleteAnalyzerLogStatus,
    analzyerInfo,
    analyzerLogsStatus,
    analyzerLogs,
    totalAnalyzerLogs,
    scheduleInfo,
  } = useSelector((state) => state.jobs);

  const [stores, setStores] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [competingMargins, setCompetingMargins] = useState({
    minimum: 0,
    maximum: 0,
  });

  const [customItemSpecifics, setCustomItemSpecifics] = useState([]);
  const [newCustomItemSpecific, setNewCustomItemSpecific] = useState({
    name: '',
    value: ''
  });
  const [deleteCustomItemSpecificsSelected, setDeleteCustomItemSpecificsSelected] = useState([]);

  const [ignoredItemSpecifics, setIgnoredItemSpecifics] = useState([]);
  const [newIgnoredItemSpecific, setNewIgnoredItemSpecific] = useState("");
  const [deleteIgnoredItemSpecificsSelected, setDeleteIgnoredItemSpecificsSelected] = useState([])

  const [imageSelectionOffsets, setImageSelectionOffsets] = useState([]);
  const [newImageSelectionOffset, setNewImageSelectionOffset] = useState({
    seller: "",
    startOffset: 0,
    endOffset: 0,
  });
  const [deleteImageSelectionOffsets, setDeleteImageSelectionOffsets] = useState([])

  const [sellersSelected, setSellersSelected] = useState([]);
  const [newSeller, setNewSeller] = useState("");
  const [deleteSellersSelected, setDeleteSellersSelected] = useState([]);;

  const [selectedReport, setSelectedReport] = useState("");
  const [selectedReportOptions, setSelectedReportOptions] = useState([]);
  const [selectedPushResult, setSelectedPushResult] = useState("");

  const [schedule, setSchedule] = useState(true);
  const [scheduleDaily, setScheduleDaily] = useState(moment().tz("America/Los_Angeles"));
  const [scheduleDailyBoolean, setScheduleDailyBoolean] = useState(false);
  const [customSchedules, setCustomSchedules] = useState([]);
  const [cycleListingCount, setCycleListingCount] = useState("0");
  const [cycleSelectedStores, setCycleSelectedStores] = useState([]);
  const [selectedSchedulerReportOptions, setSelectedSchedulerReportOptions] = useState([]);

  const [specificItemsFile, setSpecificItemsFile] = useState(null);

  const [dimensionsFile, setDimensionsFile] = useState(null);

  const [sellerSelectionDialogBoxOpen, setSellerSelectionDialogBoxOpen] = useState(false);

  const [competingMarginsDialogBoxOpen, setCompetingMarginsDialogBoxOpen] = useState(false);
  const [saveCompetingMarginsDialogBoxOpen, setSaveCompetingMarginsDialogBoxOpen] = useState(false);

  const [startAnalyzerDialogBoxOpen, setStartAnalyzerDialogBoxOpen] = useState(false);

  const [reportOptionsDialogBoxOpen, setReportOptionsDialogBoxOpen] = useState(false);
  const [pushToListingsDialogBoxOpen, setPushToListingsDialogBoxOpen] = useState(false);

  const [itemSpecificsDialogBoxOpen, setItemSpecificsDialogBoxOpen] = useState(false);
  const [imagesDialogBoxOpen, setImagesDialogBoxOpen] = useState(false);

  const [schedulerDialogBoxOpen, setSchedulerDialogBoxOpen] = useState(false);
  const [schedulerSaveDialogBoxOpen, setSchedulerSaveDialogBoxOpen] = useState(false);

  const [downloadReportDialogBoxOpen, setDownloadReportDialogBoxOpen] = useState(false);
  const [deleteReportDialogBoxOpen, setDeleteReportDialogBoxOpen] = useState(false);

  const [dimensionsUploadDialogBoxOpen, setDimensionsUploadDialogBoxOpen] = useState(false);

  const [analyzerProgress, setAnalyzerProgress] = useState(0);
  const [reportProgress, setReportProgress] = useState("");
  const [pushProgress, setPushProgress] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  useEffect(() => {
    dispatch(getAnalzyerInfoApi());
    dispatch(getStoresApi({ type: 'id-pairs' }));
    dispatch(getCategoryToPartNameMappingApi());
    dispatch(getAnalzyerScheduleApi());
  }, []);

  useEffect(() => {
    dispatch(getAnalyzerLogsApi({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }));
  }, [paginationModel])

  useEffect(() => {
    if (deleteAnalyzerLogStatus !== "pending") {
      dispatch(getAnalyzerLogsApi({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize
      }));
    }
  }, [deleteAnalyzerLogStatus])

  useEffect(() => {
    if (analzyerInfo?.sellers) {
      setSellers(analzyerInfo?.sellers);
    }
    if (analzyerInfo?.competingMargins) {
      setCompetingMargins(analzyerInfo?.competingMargins);
    }
    if (analzyerInfo?.ignoredItemSpecifics) {
      setIgnoredItemSpecifics(analzyerInfo?.ignoredItemSpecifics);
    }
    if (analzyerInfo?.customItemSpecifics) {
      setCustomItemSpecifics(analzyerInfo?.customItemSpecifics);
    }
    if (analzyerInfo?.imageSelectionOffsets) {
      setImageSelectionOffsets(analzyerInfo?.imageSelectionOffsets);
    }
  }, [analzyerInfo]);

  useEffect(() => {
    if (Object.keys(scheduleInfo).length) {
      setSchedule(scheduleInfo.schedule);
      setScheduleDaily(moment(scheduleInfo.scheduleDaily).tz('America/Los_Angeles'));
      setScheduleDailyBoolean(scheduleInfo.scheduleDailyBoolean);
      setCustomSchedules(scheduleInfo.customSchedules.map(obj => ({
        ...obj,
        schedule: moment(obj.schedule).tz('America/Los_Angeles')
      })));
      setCycleListingCount(scheduleInfo.cycleListingCount);
      setCycleSelectedStores(scheduleInfo.cycleSelectedStores);
      setSelectedSchedulerReportOptions(scheduleInfo.pushOptions);
    }
  }, [scheduleInfo]);

  useEffect(() => {
    socket.on("analyzerProgress", (data) => {
      setAnalyzerProgress(data.progress);
    });

    socket.on("reportProgress", (data) => {
      setReportProgress(data.progress);
    });

    socket.on("pushProgress", (data) => {
      setPushProgress(data.progress);
    });

    return () => {
      socket.off("analyzerProgress");
      socket.off("reportProgress");
      socket.off("pushProgress");
    };
  }, []);

  useEffect(() => {
    if (analyzerProgress == 1 || !analyzerProgress) {
      dispatch(getAnalyzerLogsApi({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize
      }));
    }
  }, [analyzerProgress]);

  useEffect(() => {
    if (!reportProgress) {
      dispatch(getAnalyzerLogsApi({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize
      }));
    }
  }, [reportProgress]);

  useEffect(() => {
    if (!pushProgress) {
      dispatch(getAnalyzerLogsApi({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize
      }));
    }
  }, [pushProgress]);

  const columns = [
    { field: "id", headerName: "Id", flex: 1.4 },
    { field: "status", headerName: "Status", flex: 0.8 },
    { field: "startDate", headerName: "start Date", flex: 1.2 },
    { field: "endDate", headerName: "End Date", flex: 1.2 },
    { field: "stores", headerName: "Stores", flex: 1.2 },
    { field: "listingsType", headerName: "Listings Type", flex: 1 },
    { field: "totalCount", headerName: "Total Count", flex: 1 },
    { field: "pushedCount", headerName: "Pushed Count", flex: 1 },
    { field: "notPushedCount", headerName: "Not Pushed Count", flex: 1 },
    {
      headerName: "actions",
      flex: 2,
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="download"
            size="medium"
            onClick={() => {
              setSelectedReport(params.row.id);
              setDownloadReportDialogBoxOpen(true)
            }}
          >
            <Download color="secondary" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="medium"
            onClick={() => {
              setSelectedReport(params.row.id);
              setDeleteReportDialogBoxOpen(true)
            }}
          >
            <Delete color="secondary" />
          </IconButton>
          {
            params.row.listingsTypeId === "active" &&
            <IconButton
              aria-label="options"
              size="medium"
              onClick={() => {
                setSelectedReport(params.row.id);
                setReportOptionsDialogBoxOpen(true)
              }}
            >
              <Tune color="secondary" />
            </IconButton>
          }
        </Box>
      ),
    },
  ];

  const handleUpdateSeller = (option) => {
    if (option === "add") {
      dispatch(updateAnalzyerInfoApi({ analzyerInfo: { seller: newSeller } }));
      dispatch(getAnalzyerInfoApi());

      setNewSeller("");
    } else if (option === "delete") {

      dispatch(updateAnalzyerInfoApi({
        analzyerInfo: {
          sellers: sellers.filter((seller) => deleteSellersSelected.indexOf(seller) === -1)
        }
      }));
      dispatch(getAnalzyerInfoApi());
      setDeleteSellersSelected([]);
    }
  };

  const handleUpdateIgnoredItemSpecific = (option) => {
    if (option === "add") {
      dispatch(updateAnalzyerInfoApi({
        analzyerInfo: {
          ignoredItemSpecifics: [
            ...ignoredItemSpecifics,
            newIgnoredItemSpecific
          ]
        }
      }));

      dispatch(getAnalzyerInfoApi());
      setNewIgnoredItemSpecific("");
    } else if (option === "delete") {
      dispatch(updateAnalzyerInfoApi({
        analzyerInfo: {
          ignoredItemSpecifics: ignoredItemSpecifics.filter((ignoredItemSpecific) => deleteIgnoredItemSpecificsSelected.indexOf(ignoredItemSpecifics) === -1)
        }
      }));

      dispatch(getAnalzyerInfoApi());
      setDeleteIgnoredItemSpecificsSelected([]);
    }
  };

  const handleUpdateCustomItemSpecifics = (option) => {
    if (option === "add") {
      dispatch(updateAnalzyerInfoApi({
        analzyerInfo: {
          customItemSpecifics: [
            ...customItemSpecifics,
            newCustomItemSpecific
          ]
        }
      }));

      dispatch(getAnalzyerInfoApi());
      setNewCustomItemSpecific({
        name: "",
        value: ""
      });
    } else if (option === "delete") {
      dispatch(updateAnalzyerInfoApi({
        analzyerInfo: {
          customItemSpecifics: customItemSpecifics.filter((obj) => deleteCustomItemSpecificsSelected.indexOf(obj.name) === -1)
        }
      }));

      dispatch(getAnalzyerInfoApi());
      setDeleteCustomItemSpecificsSelected([]);
    }
  };

  const handleUpdateImageSelectionOffsets = (option) => {
    if (option === "add") {
      dispatch(updateAnalzyerInfoApi({
        analzyerInfo: {
          imageSelectionOffsets: [
            ...(imageSelectionOffsets.find(obj => obj.seller === newImageSelectionOffset.seller)
              ? imageSelectionOffsets.map(obj => {
                if (obj.seller === newImageSelectionOffset.seller) {
                  return newImageSelectionOffset;
                }

                return obj;
              })
              : [
                ...imageSelectionOffsets,
                newImageSelectionOffset
              ]
            ),
          ]
        }
      }));

      dispatch(getAnalzyerInfoApi());
      setNewImageSelectionOffset({
        seller: "",
        startOffset: 0,
        endOffset: 0,
      });
    } else if (option === "delete") {
      dispatch(updateAnalzyerInfoApi({
        analzyerInfo: {
          imageSelectionOffsets: imageSelectionOffsets
            .filter((obj) => deleteImageSelectionOffsets.indexOf(obj.seller) === -1)
        }
      }));

      dispatch(getAnalzyerInfoApi());
      setDeleteImageSelectionOffsets([]);
    }
  };

  const startAnalyzer = () => {
    dispatch(initializeAnalyzerApi({
      file: specificItemsFile,
      stores,
      sellers: sellersSelected
    }));

    setStartAnalyzerDialogBoxOpen(false);
    setSpecificItemsFile(null);

    dispatch(getAnalyzerLogsApi({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }));
  };

  const startPushReport = () => {
    dispatch(pushAnalyzerReportApi({
      marketplace: analyzerLogs.find((obj) => obj.id === selectedReport)?.marketplace,
      logId: selectedReport,
      options: selectedReportOptions
    }));

    setPushToListingsDialogBoxOpen(false);

    setSelectedReportOptions([]);
    handleReportOptionsDialogBoxClose();
  };

  const handleReportOptionsSwitchChange = (option) => {
    const updatedOptions = [...selectedReportOptions];

    const optionIndex = updatedOptions.indexOf(option);

    if (optionIndex > -1) {
      updatedOptions.splice(optionIndex, 1);
    } else {
      updatedOptions.push(option);
    }

    setSelectedReportOptions(updatedOptions);
  };

  const handleSchedulerReportOptionsSwitchChange = (option) => {
    const updatedOptions = [...selectedSchedulerReportOptions];

    const optionIndex = updatedOptions.indexOf(option);

    if (optionIndex > -1) {
      updatedOptions.splice(optionIndex, 1);
    } else {
      updatedOptions.push(option);
    }

    setSelectedSchedulerReportOptions(updatedOptions);
  };

  const handleCompetingMarginsSave = () => {
    dispatch(updateAnalzyerInfoApi({ analzyerInfo: { competingMargins } }));
    dispatch(getAnalzyerInfoApi());

    setSaveCompetingMarginsDialogBoxOpen(false);
  };

  const handleReportOptionsDialogBoxClose = () => {
    setReportOptionsDialogBoxOpen(false);

    setSelectedReport("");
    setSelectedReportOptions([]);
  };

  const handleReportDownloadDialogBoxClose = () => {
    setDownloadReportDialogBoxOpen(false);
    setSelectedReport("");
  };

  const handleReportDeleteDialogBoxClose = () => {
    setDeleteReportDialogBoxOpen(false);
    setSelectedReport("");
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Analyzer" subtitle="Analyze And Update Listings" />
      <Box
        sx={{
          display: "flex",
          margin: "15px 0",
          marginBottom: "1rem",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            width: "60%"
          }}
        >
          <FormControl sx={{ width: "40%" }}>
            <InputLabel
              id="select-store-range-label"
              color="secondary"
            >Stores</InputLabel>
            <Select
              labelId="select-store-range-label"
              multiple
              label="Stores"
              value={stores}
              onChange={(e) => setStores(e.target.value)}
              renderValue={(selected) => {
                return selected.map((id, index) => {
                  const store = storeIdPairs.find((store) => store._id === id);
                  return store ? store.storeName : '';
                }).join(', ');
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
              color="secondary"
            >
              {storeIdPairs.map((obj) => (
                <MenuItem key={obj._id} value={obj._id}>
                  <Checkbox
                    color="secondary"
                    checked={stores.indexOf(obj._id) > -1}
                  />
                  <ListItemText primary={obj.storeName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            color="secondary"
            variant="contained"
            sx={{
              width: "20%",
              padding: "10px"
            }}
            onClick={() => setSellerSelectionDialogBoxOpen(true)}
            endIcon={<OpenInNewOutlined />}
          >
            Sellers
          </Button>
          <Button
            color="secondary"
            variant="contained"
            sx={{
              width: "20%",
              padding: "10px"
            }}
            onClick={() => setCompetingMarginsDialogBoxOpen(true)}
            endIcon={<OpenInNewOutlined />}
          >
            Competing Margins
          </Button>
          <Button
            color="secondary"
            variant="contained"
            sx={{
              width: "20%",
              padding: "10px"
            }}
            onClick={() => setSchedulerDialogBoxOpen(true)}
            endIcon={<OpenInNewOutlined />}
          >
            Scheduler
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "40%"
          }}
        >
          {
            analyzerProgress > 0 &&
            <ProgressBar
              width={"50%"}
              progress={analyzerProgress}
              text="Analyzing"
            />
          }
          {
            reportProgress &&
            <ProgressBar
              width={"50%"}
              progress={reportProgress}
              text="Report"
            />
          }
          {
            pushProgress > 0 &&
            <ProgressBar
              width={"50%"}
              progress={pushProgress}
              text="Push"
            />
          }
          <Button
            color="secondary"
            variant="contained"
            sx={{
              width: "40%",
              padding: "10px"
            }}
            endIcon={<PlayArrow />}
            onClick={() => setStartAnalyzerDialogBoxOpen(true)}
          >
            Start Analyzer
          </Button>
        </Box>
      </Box>
      <Box
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{
            // marginTop: "15px",
            "& .MuiDataGrid-cell": {
              borderColor: "#fff",
            }
          }}
          loading={analyzerLogsStatus === "pending"}
          rows={analyzerLogs || []}
          rowCount={totalAnalyzerLogs}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          paginationMode="server"
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <FlexBetween width="100%">
                  <FlexBetween width="40%" mb="20px">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setItemSpecificsDialogBoxOpen(true)}
                      sx={{
                        width: "32%"
                      }}
                    >
                      Item Specifics
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setImagesDialogBoxOpen(true)}
                      sx={{
                        width: "32%"
                      }}
                    >
                      Images
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setDimensionsUploadDialogBoxOpen(true)}
                      sx={{
                        width: "32%"
                      }}
                    >
                      Dimensions Upload
                    </Button>
                  </FlexBetween>
                </FlexBetween>
              </GridToolbarContainer>
            ),
          }}
        />
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={sellerSelectionDialogBoxOpen}
        onClose={() => setSellerSelectionDialogBoxOpen(false)}
      >
        <DialogTitle>Seller Selection</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0"
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                id="select-seller-range-label"
                color="secondary"
              >Sellers</InputLabel>
              <Select
                labelId="select-seller-range-label"
                multiple
                label="Sellers"
                value={sellersSelected}
                onChange={(e) => setSellersSelected(e.target.value)}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
                color="secondary"
              >
                {sellers.map((seller) => (
                  <MenuItem key={seller} value={seller}>
                    <Checkbox
                      color="secondary"
                      checked={sellersSelected.indexOf(seller) > -1}
                    />
                    <ListItemText primary={seller} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0"
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginBottom: "10px"
              }}
            >
              Enter New Seller Username
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                alignItems: "center"
              }}
            >
              <TextField
                color="secondary"
                label="New Seller"
                variant="outlined"
                sx={{
                  width: "80%"
                }}
                value={newSeller}
                onChange={(e) => setNewSeller(e.target.value)}
              />
              <Fab
                size="small"
                color="secondary"
                onClick={() => handleUpdateSeller("add")}
              >
                <Check />
              </Fab>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0"
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginBottom: "10px"
              }}
            >
              Select Sellers to Delete
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                alignItems: "center"
              }}
            >
              <FormControl sx={{ width: "80%" }}>
                <InputLabel
                  id="select-seller-range-label"
                  color="secondary"
                >Delete Sellers</InputLabel>
                <Select
                  labelId="select-seller-range-label"
                  multiple
                  label="Delete Sellers"
                  value={deleteSellersSelected}
                  onChange={(e) => setDeleteSellersSelected(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  color="secondary"
                >
                  {sellers.map((seller) => (
                    <MenuItem key={seller} value={seller}>
                      <Checkbox
                        color="secondary"
                        checked={deleteSellersSelected.indexOf(seller) > -1}
                      />
                      <ListItemText primary={seller} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Fab
                size="small"
                color="secondary"
                onClick={() => handleUpdateSeller("delete")}
              >
                <Delete />
              </Fab>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setSellerSelectionDialogBoxOpen(false)}
          >Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={itemSpecificsDialogBoxOpen}
        onClose={() => setItemSpecificsDialogBoxOpen(false)}
      >
        <DialogTitle>Manage Item Specifics</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "48%",
                flexDirection: "column"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0"
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    id="select-seller-range-label"
                    color="secondary"
                  >Custom Item Specifics</InputLabel>
                  <Select
                    labelId="select-seller-range-label"
                    label="Custom Item Specifics"
                    color="secondary"
                  >
                    {customItemSpecifics.map((customItemSpecific) => (
                      <MenuItem key={customItemSpecific.name}>
                        {`${customItemSpecific.name}: ${customItemSpecific.value}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0"
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  Enter New Custom Item Specific
                </Typography>
                <TextField
                  color="secondary"
                  label="Item Specific Name"
                  variant="outlined"
                  sx={{
                    width: "80%",
                    marginBottom: "10px"
                  }}
                  value={newCustomItemSpecific.name}
                  onChange={(e) => setNewCustomItemSpecific({
                    ...newCustomItemSpecific,
                    name: e.target.value
                  })}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "40px",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    color="secondary"
                    label="Item Specific Value"
                    variant="outlined"
                    sx={{
                      width: "80%"
                    }}
                    value={newCustomItemSpecific.value}
                    onChange={(e) => setNewCustomItemSpecific({
                      ...newCustomItemSpecific,
                      value: e.target.value
                    })}
                  />
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => handleUpdateCustomItemSpecifics("add")}
                  >
                    <Check />
                  </Fab>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0"
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: "10px"
                  }}
                >
                  Select Custom Item Specifics to Delete
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "40px",
                    alignItems: "center"
                  }}
                >
                  <FormControl sx={{ width: "80%" }}>
                    <InputLabel
                      id="select-ignore-item-specifics-label"
                      color="secondary"
                    >Delete Item Specifics</InputLabel>
                    <Select
                      labelId="select-ignore-item-specifics-label"
                      multiple
                      label="Delete Item Specifics"
                      value={deleteIgnoredItemSpecificsSelected}
                      onChange={(e) => setDeleteIgnoredItemSpecificsSelected(e.target.value)}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                      color="secondary"
                    >
                      {customItemSpecifics
                        .map(obj => obj.name)
                        .map((customItemSpecific) => (
                          <MenuItem key={customItemSpecific} value={customItemSpecific}>
                            <Checkbox
                              color="secondary"
                              checked={deleteCustomItemSpecificsSelected.indexOf(customItemSpecific) > -1}
                            />
                            <ListItemText primary={customItemSpecific} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => handleUpdateCustomItemSpecifics("delete")}
                  >
                    <Delete />
                  </Fab>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "48%",
                flexDirection: "column"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0"
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    id="select-seller-range-label"
                    color="secondary"
                  >Ignored Item Specifics</InputLabel>
                  <Select
                    labelId="select-seller-range-label"
                    label="Ignored Item Specifics"
                    color="secondary"
                  >
                    {ignoredItemSpecifics.map((ignoredItemSpecific) => (
                      <MenuItem key={ignoredItemSpecific}>
                        {ignoredItemSpecific}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0"
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: "10px"
                  }}
                >
                  Enter New Item Specifics To Ignore
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "40px",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    color="secondary"
                    label="New Ignored Item Specific"
                    variant="outlined"
                    sx={{
                      width: "80%"
                    }}
                    value={newIgnoredItemSpecific}
                    onChange={(e) => setNewIgnoredItemSpecific(e.target.value)}
                  />
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => handleUpdateIgnoredItemSpecific("add")}
                  >
                    <Check />
                  </Fab>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0"
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: "10px"
                  }}
                >
                  Select Ignored Item Specifics to Delete
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "40px",
                    alignItems: "center"
                  }}
                >
                  <FormControl sx={{ width: "80%" }}>
                    <InputLabel
                      id="select-ignore-item-specifics-label"
                      color="secondary"
                    >Delete Ignored Item Specifics</InputLabel>
                    <Select
                      labelId="select-ignore-item-specifics-label"
                      multiple
                      label="Delete Ignored Item Specifics"
                      value={deleteIgnoredItemSpecificsSelected}
                      onChange={(e) => setDeleteIgnoredItemSpecificsSelected(e.target.value)}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                      color="secondary"
                    >
                      {ignoredItemSpecifics.map((ignoredItemSpecific) => (
                        <MenuItem key={ignoredItemSpecific} value={ignoredItemSpecific}>
                          <Checkbox
                            color="secondary"
                            checked={deleteIgnoredItemSpecificsSelected.indexOf(ignoredItemSpecific) > -1}
                          />
                          <ListItemText primary={ignoredItemSpecific} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => handleUpdateIgnoredItemSpecific("delete")}
                  >
                    <Delete />
                  </Fab>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setItemSpecificsDialogBoxOpen(false)}
          >Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={imagesDialogBoxOpen}
        onClose={() => setImagesDialogBoxOpen(false)}
      >
        <DialogTitle>Manage Images</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0"
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    id="select-seller-range-label"
                    color="secondary"
                  >Select Seller</InputLabel>
                  <Select
                    labelId="select-seller-range-label"
                    label="Select Seller"
                    color="secondary"
                    value={newImageSelectionOffset.seller}
                    onChange={(e) => {
                      const obj = imageSelectionOffsets.find(v => v.seller === e.target.value);

                      if (obj) {
                        setNewImageSelectionOffset({
                          seller: e.target.value,
                          startOffset: obj.startOffset,
                          endOffset: obj.endOffset
                        })
                      } else {
                        setNewImageSelectionOffset({
                          seller: e.target.value,
                          startOffset: 0,
                          endOffset: 0
                        })
                      }
                    }}
                  >
                    {sellers.map((seller) => (
                      <MenuItem key={seller} value={seller}>
                        {seller}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0"
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  Enter Seller Images Offsets
                </Typography>
                <TextField
                  color="secondary"
                  label="Start Offset"
                  variant="outlined"
                  sx={{
                    width: "80%",
                    marginBottom: "10px"
                  }}
                  value={newImageSelectionOffset.startOffset}
                  onChange={(e) => setNewImageSelectionOffset({
                    ...newImageSelectionOffset,
                    startOffset: e.target.value
                  })}
                  type="number"
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "40px",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    color="secondary"
                    label="End Offset"
                    variant="outlined"
                    sx={{
                      width: "80%"
                    }}
                    value={newImageSelectionOffset.endOffset}
                    onChange={(e) => setNewImageSelectionOffset({
                      ...newImageSelectionOffset,
                      endOffset: e.target.value
                    })}
                    type="number"
                  />
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => handleUpdateImageSelectionOffsets("add")}
                  >
                    <Check />
                  </Fab>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0"
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: "10px"
                  }}
                >
                  Select Sellers Image Offsets to Delete
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "40px",
                    alignItems: "center"
                  }}
                >
                  <FormControl sx={{ width: "80%" }}>
                    <InputLabel
                      id="select-ignore-item-specifics-label"
                      color="secondary"
                    >Delete Sellers Image Offsets</InputLabel>
                    <Select
                      labelId="select-ignore-item-specifics-label"
                      multiple
                      label="Delete Sellers Image Offsets"
                      value={deleteImageSelectionOffsets}
                      onChange={(e) => setDeleteImageSelectionOffsets(e.target.value)}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                      color="secondary"
                    >
                      {imageSelectionOffsets
                        .map(({ seller }) => (
                          <MenuItem key={seller} value={seller}>
                            <Checkbox
                              color="secondary"
                              checked={deleteImageSelectionOffsets.indexOf(seller) > -1}
                            />
                            <ListItemText primary={seller} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => handleUpdateImageSelectionOffsets("delete")}
                  >
                    <Delete />
                  </Fab>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setImagesDialogBoxOpen(false)}
          >Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={dimensionsUploadDialogBoxOpen}
        onClose={() => setDimensionsUploadDialogBoxOpen(false)}
      >
        <DialogTitle>Manage Item Dimensions</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0",
              gap: "15px"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="-10px">
              Select Dimensions File
            </Typography>
            <MuiFileInput
              color="secondary"
              value={dimensionsFile}
              onChange={(file) => setDimensionsFile(file)}
              sx={{
                width: "100%"
              }}
            />
            <LoadingButton
              variant="contained"
              color="secondary"
              sx={{
                width: "100%"
              }}
              onClick={() => dispatch(itemDimensionsFileFormatApi())}
              startIcon={<Download color="black" />}
            >
              Format
            </LoadingButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setDimensionsUploadDialogBoxOpen(false)}
          >Close</Button>
          <Button
            color="secondary"
            onClick={() => {
              dispatch(itemDimensionsUploadApi(dimensionsFile));
              setDimensionsUploadDialogBoxOpen(false);
            }}
          >Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={competingMarginsDialogBoxOpen}
        onClose={() => setCompetingMarginsDialogBoxOpen(false)}
      >
        <DialogTitle>Edit Competing Margins</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0"
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginBottom: "10px"
              }}
            >
              Set Minimum Margin
            </Typography>
            <TextField
              color="secondary"
              label="Minimum"
              variant="outlined"
              sx={{
                width: "100%"
              }}
              value={competingMargins.minimum}
              onChange={(e) => setCompetingMargins({
                ...competingMargins,
                minimum: e.target.value
              })}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0"
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginBottom: "10px"
              }}
            >
              Set Maximum Margin
            </Typography>
            <TextField
              color="secondary"
              label="Maximum"
              variant="outlined"
              sx={{
                width: "100%"
              }}
              value={competingMargins.maximum}
              onChange={(e) => setCompetingMargins({
                ...competingMargins,
                maximum: e.target.value
              })}
            />
          </Box>
          <Button
            color="secondary"
            variant="contained"
            sx={{
              width: "50%",
              marginTop: "1rem",
              padding: "10px"
            }}
            onClick={() => setSaveCompetingMarginsDialogBoxOpen(true)}
          >
            Save
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setCompetingMarginsDialogBoxOpen(false)}
          >Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={reportOptionsDialogBoxOpen}
        onClose={handleReportOptionsDialogBoxClose}
      >
        <DialogTitle>Report Details / Options</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0"
            }}

          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginBottom: "10px"
              }}
            >
              Sellers Selected For This Report
            </Typography>
            {
              Array.isArray(analyzerLogs.find((obj) => obj.id === selectedReport)?.sellers) &&
              <FormControl sx={{
                width: "40%",
                margin: "0 0 20px 0"
              }}>
                <InputLabel
                  id="select-seller-selected-label"
                  color="secondary"
                >Sellers Selected</InputLabel>
                <Select
                  labelId="select-seller-selected-label"
                  label="Sellers Selected"
                  color="secondary"
                  placeholder="Sellers"
                >
                  {
                    analyzerLogs.find((obj) => obj.id === selectedReport).sellers.map((seller, index) => (
                      <MenuItem key={index}>
                        {seller}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            {
              Array.isArray(analyzerLogs.find((obj) => obj.id === selectedReport)?.pushResultsFiles) &&
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px"
                }}
              >
                <FormControl sx={{
                  width: "40%",
                  margin: "0 0 20px 0"
                }}>
                  <InputLabel
                    id="select-seller-selected-label"
                    color="secondary"
                  >Push Result Files</InputLabel>
                  <Select
                    labelId="select-seller-selected-label"
                    label="Push Result Files"
                    color="secondary"
                    placeholder="Files"
                    value={selectedPushResult}
                    onChange={(e) => setSelectedPushResult(e.target.value)}
                  >
                    {
                      analyzerLogs.find((obj) => obj.id === selectedReport).pushResultsFiles.map((file, index) => (
                        <MenuItem key={index + 1} value={index + 1}>
                          {file}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {
                  selectedPushResult &&
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      width: "40%",
                    }}
                    onClick={() => dispatch(downloadAnalyzerPushResultApi({
                      logId: selectedReport,
                      resultId: selectedPushResult,
                    }))}
                    endIcon={<Download />}
                  >
                    Download Result
                  </Button>
                }
              </Box>
            }
            {
              analyzerLogs.find((obj) => obj.id === selectedReport)?.marketplace === "ebay" &&
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginTop: "25px",
                    marginBottom: "10px"
                  }}
                >
                  Select eBay Data Options to Push To Your Listings
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center"
                  }}
                >
                  <FormControlLabel
                    value="Price"
                    control={<Switch
                      checked={selectedReportOptions.indexOf("price") > -1}
                      onChange={() => handleReportOptionsSwitchChange("price")}
                      color="secondary"
                    />}
                    label="Price"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Images"
                    control={<Switch
                      checked={selectedReportOptions.indexOf("images") > -1}
                      onChange={() => handleReportOptionsSwitchChange("images")}
                      color="secondary"
                    />}
                    label="Images"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Item Specifics"
                    control={<Switch
                      checked={selectedReportOptions.indexOf("itemSpecifics") > -1}
                      onChange={() => handleReportOptionsSwitchChange("itemSpecifics")}
                      color="secondary"
                    />}
                    label="Item Specifics"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Compatibility"
                    control={<Switch
                      checked={selectedReportOptions.indexOf("compatibility") > -1}
                      onChange={() => handleReportOptionsSwitchChange("compatibility")}
                      color="secondary"
                    />}
                    label="Compatibility"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Competitor Fitment Year Range"
                    control={<Switch
                      checked={selectedReportOptions.indexOf("title") > -1}
                      onChange={() => handleReportOptionsSwitchChange("title")}
                      color="secondary"
                    />}
                    label="Competitor Fitment Year Range"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Description Template"
                    control={<Switch
                      checked={selectedReportOptions.indexOf("description") > -1}
                      onChange={() => handleReportOptionsSwitchChange("description")}
                      color="secondary"
                    />}
                    label="Description Template"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Dimensions"
                    control={<Switch
                      checked={selectedReportOptions.indexOf("dimensions") > -1}
                      onChange={() => handleReportOptionsSwitchChange("dimensions")}
                      color="secondary"
                    />}
                    label="Dimensions"
                    labelPlacement="end"
                  />
                </Box>
              </>
            }
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0"
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              sx={{
                width: "40%",
                padding: "10px"
              }}
              onClick={() => setPushToListingsDialogBoxOpen(true)}
              endIcon={<ArrowCircleRight />}
            >
              Push To Listing
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleReportOptionsDialogBoxClose}
          >Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={schedulerDialogBoxOpen}
        onClose={() => setSchedulerDialogBoxOpen(false)}
      >
        <DialogTitle>Analyzer Scheduler</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0",
              gap: "25px"
            }}
          >
            <Box
              sx={{
                width: "30%",
                display: "flex",
                marginLeft: "-1rem",
                padding: "0 20px"
              }}
            >
              <FormControlLabel
                control={<Switch
                  checked={schedule}
                  onChange={() => setSchedule(!schedule)}
                  color="secondary"
                />}
                labelPlacement="end"
                label="Schedule Analyzer"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <FormControl sx={{ width: "55%" }}>
                <InputLabel
                  id="select-store-range-label"
                  color="secondary"
                >Select Stores In Order</InputLabel>
                <Select
                  labelId="select-store-range-label"
                  multiple
                  label="Select Stores In Order"
                  value={cycleSelectedStores}
                  onChange={(e) => setCycleSelectedStores(e.target.value)}
                  renderValue={(selected) => {
                    return selected.map((id, index) => {
                      const store = storeIdPairs.find((store) => store._id === id);
                      return store ? store.storeName : '';
                    }).join(', ');
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  color="secondary"
                >
                  {storeIdPairs.map((obj) => (
                    <MenuItem key={obj._id} value={obj._id}>
                      <Checkbox
                        color="secondary"
                        checked={cycleSelectedStores.indexOf(obj._id) > -1}
                      />
                      <ListItemText primary={obj.storeName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                color="secondary"
                label="Cycle Listing Limit"
                variant="outlined"
                sx={{
                  width: "40%"
                }}
                value={cycleListingCount}
                onChange={(e) => setCycleListingCount(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                marginLeft: "-1rem",
                padding: "0 20px"
              }}
            >
              <FormControlLabel
                control={<Switch
                  checked={scheduleDailyBoolean}
                  onChange={() => setScheduleDailyBoolean(!scheduleDailyBoolean)}
                  color="secondary"
                />}
                labelPlacement="end"
                label="Schedule Daily"
              />
            </Box>
            {
              scheduleDailyBoolean &&
              <DesktopTimePicker
                label="Daily Schedule"
                slotProps={{ textField: { color: "secondary" } }}
                value={scheduleDaily}
                onChange={(newValue) => setScheduleDaily(newValue)}
                sx={{
                  width: "45%"
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            }
            {
              !scheduleDailyBoolean &&
              customSchedules.length &&
              customSchedules.map(({ day, schedule, disable }, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "60%"
                    }}
                  >
                    <TextField
                      color="secondary"
                      label="Week Day"
                      variant="outlined"
                      sx={{
                        width: "45%"
                      }}
                      defaultValue={day}
                      inputProps={{
                        readOnly: true
                      }}
                    />
                    <DesktopTimePicker
                      label="Schedule"
                      slotProps={{ textField: { color: "secondary" } }}
                      value={schedule}
                      onChange={(newValue) => setCustomSchedules([
                        ...customSchedules.map((obj, i) => {
                          if (i === index) {
                            return {
                              day,
                              schedule: newValue,
                              disable
                            }
                          }

                          return obj;
                        }),
                      ])}
                      sx={{
                        width: "45%"
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      disabled={disable}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "50%"
                    }}
                  >
                    <FormControlLabel
                      control={<Switch
                        checked={disable}
                        onChange={() => setCustomSchedules([
                          ...customSchedules.map((obj, i) => {
                            if (i === index) {
                              return {
                                day,
                                schedule,
                                disable: !disable
                              }
                            }

                            return obj;
                          }),
                        ])}
                        color="secondary"
                      />}
                      labelPlacement="end"
                      label="Disable"
                    />
                  </Box>
                </Box>
              ))
            }
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginTop: "25px",
              }}
            >
              Select eBay Data Options to Push To Your Listings
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControlLabel
                value="Price"
                control={<Switch
                  checked={selectedSchedulerReportOptions.indexOf("price") > -1}
                  onChange={() => handleSchedulerReportOptionsSwitchChange("price")}
                  color="secondary"
                />}
                label="Price"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Images"
                control={<Switch
                  checked={selectedSchedulerReportOptions.indexOf("images") > -1}
                  onChange={() => handleSchedulerReportOptionsSwitchChange("images")}
                  color="secondary"
                />}
                label="Images"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Item Specifics"
                control={<Switch
                  checked={selectedSchedulerReportOptions.indexOf("itemSpecifics") > -1}
                  onChange={() => handleSchedulerReportOptionsSwitchChange("itemSpecifics")}
                  color="secondary"
                />}
                label="Item Specifics"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Compatibility"
                control={<Switch
                  checked={selectedSchedulerReportOptions.indexOf("compatibility") > -1}
                  onChange={() => handleSchedulerReportOptionsSwitchChange("compatibility")}
                  color="secondary"
                />}
                label="Compatibility"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Competitor Fitment Year Range"
                control={<Switch
                  checked={selectedSchedulerReportOptions.indexOf("title") > -1}
                  onChange={() => handleSchedulerReportOptionsSwitchChange("title")}
                  color="secondary"
                />}
                label="Competitor Fitment Year Range"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Description Template"
                control={<Switch
                  checked={selectedSchedulerReportOptions.indexOf("description") > -1}
                  onChange={() => handleSchedulerReportOptionsSwitchChange("description")}
                  color="secondary"
                />}
                label="Description Template"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Dimensions"
                control={<Switch
                  checked={selectedSchedulerReportOptions.indexOf("dimensions") > -1}
                  onChange={() => handleSchedulerReportOptionsSwitchChange("dimensions")}
                  color="secondary"
                />}
                label="Dimensions"
                labelPlacement="end"
              />
            </Box>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                width: "50%",
                marginTop: "1rem",
                padding: "10px"
              }}
              onClick={() => setSchedulerSaveDialogBoxOpen(true)}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setSchedulerDialogBoxOpen(false)}
          >Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={startAnalyzerDialogBoxOpen}
        onClose={() => {
          setStartAnalyzerDialogBoxOpen(false);
          setSpecificItemsFile(null);
        }}
      >
        <DialogTitle>Start Analyzer Confirmation</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 0",
              gap: "15px"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="-10px">
              Select Specific Item's {`(Optional)`}
            </Typography>
            <MuiFileInput
              color="secondary"
              value={specificItemsFile}
              onChange={(file) => setSpecificItemsFile(file)}
              sx={{
                width: "100%"
              }}
            />
            <LoadingButton
              variant="contained"
              color="secondary"
              sx={{
                width: "100%"
              }}
              onClick={() => dispatch(analyzerSpecificItemsFormatApi())}
              startIcon={<Download color="black" />}
            >
              Format
            </LoadingButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setStartAnalyzerDialogBoxOpen(false);
              setSpecificItemsFile(null);
            }}
          >Close</Button>
          <Button
            color="secondary"
            onClick={startAnalyzer}
          >Confirm</Button>
        </DialogActions>
      </Dialog>
      <DialogBox
        open={pushToListingsDialogBoxOpen}
        onClose={() => setPushToListingsDialogBoxOpen(false)}
        onConfirm={startPushReport}
        titleText="Push Confirmation"
        messageText="Are you sure you wish to update your current listing?"
        confirmText="Confirm"
      />
      <DialogBox
        open={saveCompetingMarginsDialogBoxOpen}
        onClose={() => setSaveCompetingMarginsDialogBoxOpen(false)}
        onConfirm={handleCompetingMarginsSave}
        titleText="Save Confirmation"
        messageText="Are you sure you wish to save these competing margins?"
        confirmText="Confirm"
      />
      <DialogBox
        open={downloadReportDialogBoxOpen}
        onClose={handleReportDownloadDialogBoxClose}
        onConfirm={() => {
          dispatch(downloadAnalyzerReportApi({ logId: selectedReport }));
          handleReportDownloadDialogBoxClose();
        }}
        titleText="Download Confirmation"
        messageText="Are you sure you wish to download this report?"
        confirmText="Confirm"
      />
      <DialogBox
        open={deleteReportDialogBoxOpen}
        onClose={handleReportDeleteDialogBoxClose}
        onConfirm={() => {
          dispatch(deleteAnalyzerLogApi({ logId: selectedReport }));
          handleReportDeleteDialogBoxClose();
        }}
        titleText="Delete Confirmation"
        messageText="Are you sure you wish to delete this report?"
        confirmText="Confirm"
      />
      <DialogBox
        open={schedulerSaveDialogBoxOpen}
        onClose={() => setSchedulerSaveDialogBoxOpen(false)}
        onConfirm={() => {
          dispatch(updateAnalzyerScheduleApi({
            scheduleInfo: {
              schedule,
              scheduleDaily,
              scheduleDailyBoolean,
              customSchedules,
              cycleListingCount,
              cycleSelectedStores,
              pushOptions: selectedSchedulerReportOptions
            }
          }));

          setSchedulerSaveDialogBoxOpen(false);

          dispatch(getAnalzyerScheduleApi());
        }}
        titleText="Save Confirmation"
        messageText="Are you sure you wish to save the scheduler settings?"
        confirmText="Confirm"
      />
    </Box >
  );
};

export default Analyzer;