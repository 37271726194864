import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "state";

import userSlice from "features/user/userSlice";
import staffSlice from "features/staff/staffSlice";
import suppliersSlice from "features/suppliers/suppliersSlice";
import storesSlice from "features/stores/storesSlice";
import listingsSlice from "features/listings/listingsSlice";
import marketplaceSlice from "features/marketplaces/marketplacesSlice";
import ordersSlice from "features/orders/ordersSlice";
import jobsSlice from "features/jobs/jobsSlice";
import settingsSlice from "features/settings/settingsSlice";

const store = configureStore({
  reducer: {
    global: globalReducer,
    user: userSlice,
    staff: staffSlice,
    suppliers: suppliersSlice,
    stores: storesSlice,
    listings: listingsSlice,
    marketplace: marketplaceSlice,
    orders: ordersSlice,
    jobs: jobsSlice,
    settings: settingsSlice,
  },
});

export default store;