import React, { useState, useEffect } from "react";

import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  Typography,
  LinearProgress,
  useTheme
} from "@mui/material";

import Header from "components/Header";

import {
  Search,
  ArrowCircleLeft,
  ArrowCircleRight,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import { searchItemsApi } from "features/suppliers/suppliersApi";
import { resetItems } from "features/suppliers/suppliersSlice";

const SuppliersSearch = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { items, totalItems, searchItemsStatus } = useSelector((state) => state.suppliers);

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  const [openedItems, setOpenedItems] = useState([]);

  const limit = 20;

  useEffect(() => {
    if (search) {
      dispatch(searchItemsApi({ page, search }))
    }
  }, [page])

  useEffect(() => {
    dispatch(resetItems());
  }, [])

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Supplier Search" subtitle="Search Suppliers Item" />
      <Box
        sx={{
          display: "flex",
          gap: "25px",
          alignitems: "center",
          justifyContent: "space-between"
        }}
      >
        <TextField
          color="secondary"
          label="Search..."
          sx={{ mb: "0.5rem", width: "20%", margin: "25px 0" }}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => dispatch(searchItemsApi({ page, search }))}
                >
                  <Search color="secondary" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            width: "60%",
            justifyContent: "flex-end"
          }}
          fontWeight="bold"
          variant="h4"
        >{`Items: ${(page * limit) + items.length} - ${totalItems}`}</Typography>
        <Box
          sx={{
            display: "flex",
            width: "40%",
            justifyContent: "flex-end"
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "40%",
              alignItems: "center",
              gap: "20px"
            }}
          >
            <IconButton
              size="small"
              onClick={() => setPage(page - 1)}
              disabled={page === 0}
            >
              <ArrowCircleLeft />
            </IconButton >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              fontWeight="bold"
              variant="h4"
            >{page + 1}</Typography>
            <IconButton
              size="small"
              onClick={() => setPage(page + 1)}
              disabled={(page * limit) >= totalItems}
            >
              <ArrowCircleRight />
            </IconButton>
          </Box>
        </Box>
      </Box>
      {
        searchItemsStatus === "pending" &&
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="secondary" />
        </Box>
      }
      <Divider middle />
      <Box
        sx={{
          paddingBottom: "2rem"
        }}
      >
        {
          items.map(item => (
            <Box
              sx={{
                width: "60%",
                borderRadius: "5px",
                border: `1px ${theme.palette.primary.light} solid`,
                margin: "25px 0",
                padding: "15px",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Box
                sx={{
                  display: "flex"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "25px",
                    width: "90%"
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "50%"
                    }}
                    variant="h5"
                  >
                    <span
                      style={{
                        fontWeight: "bold"
                      }}
                    >Item:</span>
                    &nbsp;
                    {item.id}
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "40%"
                    }}
                    variant="h5"
                  >
                    <span
                      style={{
                        fontWeight: "bold"
                      }}
                    >Supplier:</span>
                    &nbsp;
                    {item.supplier}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "10%"
                  }}
                >
                  {
                    openedItems.includes(`${item.id}+${item.supplier}`)
                      ? (
                        <IconButton
                          size="medium"
                          onClick={() => setOpenedItems([...openedItems.filter(id => id !== `${item.id}+${item.supplier}`)])}
                        >
                          <KeyboardArrowDown fontSize="large" color="secondary" />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="medium"
                          onClick={() => setOpenedItems([...openedItems, `${item.id}+${item.supplier}`])}
                        >
                          <KeyboardArrowUp fontSize="large" color="secondary" />
                        </IconButton>
                      )
                  }
                </Box>
              </Box>
              {
                openedItems.includes(`${item.id}+${item.supplier}`) &&
                <Box
                  sx={{
                    marginTop: "25px"
                  }}
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="Item Data"
                    multiline
                    defaultValue={JSON.stringify(item, null, 2)}
                    rows={30}
                    fullWidth
                    color="secondary"
                  />
                </Box>
              }
            </Box>
          ))
        }
      </Box>
    </Box>
  );
};

export default SuppliersSearch;