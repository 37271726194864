import React from "react";

import {
  Box,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MuiFileInput } from 'mui-file-input';

import { Download } from "@mui/icons-material";

import SubHeader from "components/SubHeader";

const SupplierDataUpload = ({
  type,
  title,
  lastUploadDate,
  status,
  selectedFile,
  setSelectedFile,
  handleFormatDownload
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <SubHeader title={title} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          borderRadius: "10px",
          bgcolor: theme.palette.background.alt,
          padding: "2rem",
          gap: "2rem"
        }}
      >
        {lastUploadDate &&
          <TextField
            id="outlined-read-only-input"
            label="Last Inventory Upload Date"
            defaultValue={lastUploadDate}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            color="secondary"
          />
        }
        <Box>
          <Typography variant="h6" p="3px">{`Select ${type} File To Upload`}</Typography>
          <MuiFileInput value={selectedFile} onChange={(file) => setSelectedFile(file)} fullWidth />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center"
          }}
        >
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{
              width: "30%",
              padding: "10px"
            }}
            endIcon={<Download />}
            loading={status === "pending"}
            onClick={() => handleFormatDownload(type)}
          >
            Format
          </LoadingButton>
          <Typography variant="h6" p="3px">Download file format of supplier</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SupplierDataUpload; 