import { createSlice } from "@reduxjs/toolkit";
import {
  signInApi,
  signOutApi,
  getProfileInfoApi,
  updateProfileInfoApi,
  updateProfileImageApi,
  passwordResetEmailApi,
  // verifyPasswordResetApi
} from "./userApi";

const initialState = {
  user: null,
  signInStatus: "",
  updateStatus: "",
  profileInfo: {
    firstName: "",
    lastName: "",
    email: "",
    profileImage: ""
  },
  resetEmailLoading: false,
  resetEmailStatus: "idle"
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.signInStatus = "";
    },
    updateResetEmailStatus: (state, { payload }) => {
      state.resetEmailStatus = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // signInApi
      .addCase(signInApi.pending, (state) => {
        state.signInStatus = "Signing In...";
      })
      .addCase(signInApi.fulfilled, (state, { payload }) => {
        if (payload) {
          state.user = payload.data.userId;
          state.signInStatus = "Successful";
          localStorage.setItem('token', payload.data.token);
        } else {
          state.signInStatus = "Failed";
        }
      })
      .addCase(signInApi.rejected, (state) => {
        state.signInStatus = "Failed";
      })
      // signOut
      .addCase(signOutApi.fulfilled, (state, { payload }) => {
        if (payload) {;
          localStorage.removeItem("token");
        }
      })
      // getProfileInfoApi
      .addCase(getProfileInfoApi.fulfilled, (state, { payload }) => {
        if (payload && payload.success) {
          state.profileInfo = payload.data.profileData;
        }
      })
      // updateProfileInfoApi
      .addCase(updateProfileInfoApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateProfileInfoApi.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateProfileInfoApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // updateProfileImageApi
      .addCase(updateProfileImageApi.pending, (state) => {
        state.pfpUpdateLoading = true;
      })
      .addCase(updateProfileImageApi.fulfilled, (state) => {
        state.pfpUpdateLoading = false;
      })
      .addCase(updateProfileImageApi.rejected, (state) => {
        state.pfpUpdateLoading = false;
      })
      // passwordResetEmailApi
      .addCase(passwordResetEmailApi.pending, (state) => {
        state.resetEmailLoading = true;
      })
      .addCase(passwordResetEmailApi.fulfilled, (state) => {
        state.resetEmailStatus = 'fulfilled';
      })
      .addCase(passwordResetEmailApi.rejected, (state) => {
        state.resetEmailStatus = 'rejected';
      });
  }
});

export const { clearStatus, updateResetEmailStatus } = userSlice.actions;
export default userSlice.reducer;
