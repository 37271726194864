import api from "state/api";
import { getConfigWithBearerToken, notifyMsg } from "state/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getEbayApiRateLimitApi = createAsyncThunk("dashboard/getEbayApiRateLimit", async () => {
  try {
    const response = await api.get(`settings/dashboard/ebay-api-rate-limit`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getEbayApiRateLimitApi:", error.message);
  }
});