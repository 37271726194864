import React, { useEffect } from "react";
import { Search } from "@mui/icons-material";
import { IconButton, Button, TextField, InputAdornment } from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";

const DataGridCustomToolbar = ({ searchInput, setSearchInput, setDeleteDialogOpen, setFilterDeleteDialogOpen, setSpecificDeleteDialogOpen, handleSearch }) => {

  useEffect(() => {
    if (!searchInput) handleSearch();
  }, [searchInput]);

  return (
    <GridToolbarContainer>
      <FlexBetween width="100%">
        <FlexBetween>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <Button
            variant="text"
            sx={{
              border: "solid 1px",
              margin: "15px",
            }}
            onClick={() => setDeleteDialogOpen(true)}
          >Delete Selected</Button>
          <Button
            variant="text"
            sx={{
              border: "solid 1px",
              margin: "15px",
            }}
            onClick={() => setFilterDeleteDialogOpen(true)}
          >Filter Delete</Button>
          <Button
            variant="text"
            sx={{
              border: "solid 1px",
              margin: "15px",
            }}
            onClick={() => setSpecificDeleteDialogOpen(true)}
          >Delete Specific</Button>
        </FlexBetween>
        <TextField
          color="secondary"
          label="Search..."
          sx={{ mb: "0.5rem", width: "15rem" }}
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    handleSearch();
                  }}
                >                
                  <Search color="secondary" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FlexBetween>
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbar;
