import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  Box,
  TextField,
  Button,
  useTheme,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MuiFileInput } from 'mui-file-input';

import { CallReceived, Download } from "@mui/icons-material";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";
import CustomTextField from "components/CustomTextField";

import { useDispatch, useSelector } from "react-redux";
import {
  getStoreInfoApi,
  updateStoreInfoApi,
  deleteStoreApi,
  updateFetchSwitchApi,
  itemIdFileFormatApi,
  updateStoreListingDescriptionTemplateApi
} from "features/stores/storesApi";

const StoreEdit = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    storeInfo,
    updateStatus,
    fetchStatus,
    itemIdFileFormatStatus,
    deleteStatus,
    updateStoreListingDescriptionTemplateStatus
  } = useSelector((state) => state.stores);

  const { storeId } = useParams();

  const [refreshToken, setRefreshToken] = useState("");
  const [supplierMarkUps, setSupplierMarkUps] = useState([]);
  const [syncSwitch, setSyncSwitch] = useState(false);

  const [optimalPricingWindow, setOptimalPricingWindow] = useState({});
  const [sellerId, setSellerId] = useState("");
  const [marketplaceId, setMarketplaceId] = useState("");
  const [issueLocale, setIssueLocale] = useState("");

  const [analyzerConfig, setAnalyzerConfig] = useState({});

  const [descriptionTemplateFile, setDescriptionTemplateFile] = useState(null);

  const [fetchDialogOpen, setFetchDialogOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [specifiedItemIds, setSpecifiedItemIds] = useState(false);
  const [itemIdsFile, setItemIdsFile] = useState(null);

  useEffect(() => {
    dispatch(getStoreInfoApi(storeId));
  }, []);

  useEffect(() => {
    if (storeInfo) {
      setSupplierMarkUps(storeInfo.supplierMarkUps);
      setSyncSwitch(storeInfo.syncSwitch);
      setOptimalPricingWindow(storeInfo.optimalPricingWindow);
      setSellerId(storeInfo.sellerId);
      setMarketplaceId(storeInfo.marketplaceId);
      setIssueLocale(storeInfo.issueLocale);
      setAnalyzerConfig(storeInfo.analyzerConfig);
    }
  }, [storeInfo]);

  const handleUpdate = () => {
    dispatch(updateStoreInfoApi({ storeId, refreshToken, supplierMarkUps, syncSwitch, analyzerConfig, optimalPricingWindow, sellerId, marketplaceId, issueLocale }));

    setRefreshToken("");
    setSaveDialogOpen(false)
  };

  const handleFetch = () => {
    dispatch(updateFetchSwitchApi({ storeId, fetchSwitch: true, specifiedItemIds, itemIdsFile }));
    setFetchDialogOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteStoreApi(storeId));
    navigate('/stores');
  };

  const handleMarkUpChange = (value, supplierId) => {
    const updatedSupplierMarkUps = supplierMarkUps.map((obj) =>
      obj.supplierId === supplierId
        ? {
          ...obj,
          markUpPercent: value
        }
        : obj
    );

    setSupplierMarkUps(updatedSupplierMarkUps);
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "75%"
        }}
      >
        <Header title={storeInfo.storeName || "Store Edit"} subtitle="Edit And Manage Your Store" />
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px",
            alignSelf: "flex-end"
          }}
          onClick={() => setDeleteDialogOpen(true)}
          loading={deleteStatus === "pending"}
        >
          Delete Store
        </LoadingButton>
      </Box>
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Fetch Listings" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            {storeInfo.storeEmail &&
              <TextField
                id="outlined-read-only-input"
                label="Store Name"
                defaultValue={storeInfo.storeEmail}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                color="secondary"
              />
            }
            {storeInfo.storeUsername &&
              <TextField
                id="outlined-read-only-input"
                label="Store Username"
                defaultValue={storeInfo.storeUsername}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                color="secondary"
              />
            }
            <Typography fontWeight="bold" variant="h5" p="3px" mt="10px">Fetch Listings</Typography>
            <Box>
              <LoadingButton
                color="secondary"
                variant="contained"
                sx={{
                  width: "30%",
                  padding: "10px"
                }}
                endIcon={<CallReceived />}
                loading={fetchStatus === "pending"}
                onClick={() => setFetchDialogOpen(true)}
              >
                Fetch
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center"
              }}
            >
              <LoadingButton
                color="secondary"
                variant="contained"
                sx={{
                  width: "30%",
                  padding: "10px"
                }}
                endIcon={<Download />}
                loading={itemIdFileFormatStatus === "pending"}
                onClick={() => dispatch(itemIdFileFormatApi())}
              >
                Format
              </LoadingButton>
              <Typography variant="h6" p="3px">Download file format for fetching item numbers</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Store Settings" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <Typography fontWeight="bold" variant="h5" p="3px" mb="20px">Refresh Token</Typography>
              {
                storeInfo?.storeMarketplace === "ebay" ?
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {typeof storeInfo?.oAuthTokenInfo?.refreshTokenValid === "boolean" &&
                      <TextField
                        id="outlined-textarea"
                        label="Refresh Token Validity"
                        placeholder="Validity..."
                        defaultValue={storeInfo?.oAuthTokenInfo?.refreshTokenValid}
                        readOnly
                        sx={{
                          width: "45%"
                        }}
                        color="secondary"
                      />
                    }
                    {storeInfo?.oAuthTokenInfo?.ebayAuthCodeUri &&
                      <LoadingButton
                        color="secondary"
                        variant="contained"
                        sx={{
                          width: "35%",
                          alignSelf: "flex-end"
                        }}
                        onClick={() => window.open(storeInfo?.oAuthTokenInfo?.ebayAuthCodeUri, '_blank')}
                      >
                        Generate Refresh <br /> Token
                      </LoadingButton>}
                  </Box>
                  :
                  <>
                    {typeof storeInfo?.oAuthTokenInfo?.refreshTokenValid === "boolean" &&
                      <TextField
                        id="outlined-textarea"
                        label="Refresh Token Validity"
                        placeholder="Validity..."
                        defaultValue={storeInfo?.oAuthTokenInfo?.refreshTokenValid}
                        readOnly
                        sx={{
                          width: "45%",
                          marginBottom: "20px"
                        }}
                        color="secondary"
                      />
                    }
                    <TextField
                      id="outlined-multiline-static"
                      label="Refresh Token"
                      multiline
                      value={refreshToken}
                      onChange={(e) => setRefreshToken(e.target.value)}
                      rows={6}
                      fullWidth
                      color="secondary"
                      placeholder="Enter Your OAuth Token..."
                    />
                  </>
              }
              {/* <Typography fontWeight="bold" variant="h5" p="3px" mt="20px" mb="20px">eBay OAuth Token (only if you want to do it manually)</Typography>
              <TextField
                id="outlined-multiline-static"
                label="OAuth Token"
                multiline
                value={accessToken}
                onChange={(e) => setAccessToken(e.target.value)}
                rows={4}
                fullWidth
                color="secondary"
                placeholder="Enter Your OAuth Token..."
              /> */}
            </Box>
            <Divider middle />
            <Box>
              <Typography fontWeight="bold" variant="h5" p="3px" mb="20px">Store Mark Ups</Typography>
              {supplierMarkUps?.map((obj) => (
                <Box
                  mb="15px"
                  sx={{
                    display: "flex",
                    gap: "1rem"
                  }}
                >
                  <TextField
                    id="outlined-read-only-input"
                    label={`Supplier`}
                    defaultValue={obj.supplierNonCamelCase}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: "40%"
                    }}
                    color="secondary"
                  />
                  <TextField
                    id="outlined-textarea"
                    label="Mark Up (%)"
                    placeholder="Mark Up..."
                    value={obj.markUpPercent}
                    onChange={(e) => handleMarkUpChange(e.target.value, obj.supplierId)}
                    sx={{
                      width: "20%"
                    }}
                    type="number"
                    inputProps={{ min: 0 }}
                    color="secondary"
                  />
                </Box>
              ))}
            </Box>
            <Divider middle />
            {
              storeInfo?.storeMarketplace === "amazon" &&
              <>
                <Typography fontWeight="bold" variant="h5" p="3px">Optimal Pricing Window</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <TextField
                    label="Listing Minimum Allowed Price (%)"
                    value={optimalPricingWindow?.minimumSellerAllowedPricePercent || ""}
                    onChange={(e) => setOptimalPricingWindow({
                      ...optimalPricingWindow,
                      minimumSellerAllowedPricePercent: e.target.value
                    })}
                    sx={{
                      width: "45%"
                    }}
                    color="secondary"
                    placeholder="Enter Your Allowed Price..."
                  />
                  <TextField
                    label="Listing Maximum Allowed Price (%)"
                    value={optimalPricingWindow?.maximumSellerAllowedPricePercent || ""}
                    onChange={(e) => setOptimalPricingWindow({
                      ...optimalPricingWindow,
                      maximumSellerAllowedPricePercent: e.target.value
                    })}
                    sx={{
                      width: "45%"
                    }}
                    color="secondary"
                    placeholder="Enter Your Allowed Price..."
                  />
                </Box>
                <Divider middle />
                <Typography fontWeight="bold" variant="h5" p="3px">Store API Config</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px"
                  }}
                >
                  <TextField
                    label="Seller ID"
                    value={sellerId}
                    onChange={(e) => setSellerId(e.target.value)}
                    sx={{
                      width: "45%"
                    }}
                    color="secondary"
                    placeholder="Enter Your Seller ID..."
                  />
                  <TextField
                    label="Marketplace ID"
                    value={marketplaceId}
                    onChange={(e) => setMarketplaceId(e.target.value)}
                    sx={{
                      width: "45%"
                    }}
                    color="secondary"
                    placeholder="Enter Your Marketplace ID..."
                  />
                  <TextField
                    label="Issue Locale"
                    value={issueLocale}
                    onChange={(e) => setIssueLocale(e.target.value)}
                    sx={{
                      width: "45%"
                    }}
                    color="secondary"
                    placeholder="Enter Your Issue Locale..."
                  />
                </Box>
                <Divider middle />
              </>
            }
            <Box
              sx={{
                width: "50%",
              }}
            >
              <Typography fontWeight="bold" variant="h5" p="3px" mb="20px">Sync Store</Typography>
              <FormControl
                component="fieldset"
              >
                <FormControlLabel
                  control={<Switch
                    color="secondary"
                    checked={syncSwitch}
                    onChange={() => setSyncSwitch(!syncSwitch)}
                  />}
                  label="Sync Store"
                  labelPlacement="end"
                />
              </FormControl>
            </Box>
            {
              storeInfo?.storeMarketplace === "ebay" &&
              <>
                <Divider middle />
                <Typography fontWeight="bold" variant="h5" p="3px">Analyzer Options</Typography>
                <CustomTextField
                  label="Minimum Profit (%)"
                  value={analyzerConfig?.minimumProfitPercent || ""}
                  onChange={(e) => setAnalyzerConfig({
                    ...analyzerConfig,
                    minimumProfitPercent: e.target.value
                  })}
                  muiBoxSx={{
                    width: "45%"
                  }}
                  muiProps={{
                    placeholder: "Enter Your Percent..."
                  }}
                />
                <CustomTextField
                  label="eBay Commission (%)"
                  value={analyzerConfig?.ebayCommissionPercent || ""}
                  onChange={(e) => setAnalyzerConfig({
                    ...analyzerConfig,
                    ebayCommissionPercent: e.target.value
                  })}
                  muiBoxSx={{
                    width: "45%"
                  }}
                  muiProps={{
                    placeholder: "Enter Your Percent..."
                  }}
                />
              </>
            }
            <Divider middle />
            <Typography fontWeight="bold" variant="h5">Listing Description Template</Typography>
            <Box
              sx={{
                display: "flex",
                gap: "25px"
              }}
            >
              <MuiFileInput
                value={descriptionTemplateFile}
                onChange={(file) => setDescriptionTemplateFile(file)}
                sx={{
                  width: "70%"
                }}
              />
              <LoadingButton
                variant="contained"
                color="secondary"
                sx={{
                  width: "30%"
                }}
                loading={updateStoreListingDescriptionTemplateStatus === "pending"}
                onClick={() => {
                  dispatch(updateStoreListingDescriptionTemplateApi({ storeId, file: descriptionTemplateFile }));
                  setDescriptionTemplateFile(null);
                }}
              >
                Upload File
              </LoadingButton>
            </Box>
          </Box>
        </Box>
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px",
            alignSelf: "flex-end"
          }}
          onClick={() => setSaveDialogOpen(true)}
          loading={updateStatus === "pending"}
        >
          Save
        </LoadingButton>
      </Box>
      <Dialog
        open={fetchDialogOpen}
        onClose={() => setFetchDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Fetch Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to fetch all listings for {storeInfo.storeName}?
          </DialogContentText>
          <FormControl
            component="fieldset"
            sx={{
              marginTop: "25px",
              marginBottom: "10px",
              marginLeft: "-15px"
            }}
          >
            <FormControlLabel
              control={<Switch
                color="secondary"
                checked={specifiedItemIds}
                onChange={() => setSpecifiedItemIds(!specifiedItemIds)}
              />}
              label="Specify Item Numbers"
              labelPlacement="top"
              sx={{
                whiteSpace: 'nowrap'
              }}
            />
          </FormControl>
          {specifiedItemIds && (
            <Box width="100%">
              <MuiFileInput
                color="secondary"
                value={itemIdsFile}
                onChange={setItemIdsFile}
                fullWidth
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Button
                onClick={() => setFetchDialogOpen(false)}
                color="secondary"
              >Cancel
              </Button>
              <Button
                onClick={handleFetch}
                color="secondary"
              >Confirm Fetch
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <DialogBox
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onConfirm={handleUpdate}
        titleText="Save Confirmation"
        messageText="Are you sure you want to save this change?"
        confirmText="Save"
      />
      <DialogBox
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        titleText="Delete Confirmation"
        messageText={`Are you sure you want to delete the ${storeInfo.storeName} store?`}
        confirmText="Delete"
      />
    </Box>
  );
}

export default StoreEdit;
