import { io } from "socket.io-client";
import { notifyMsg } from "state/api";

const socket = io(process.env.REACT_APP_SOCKET_SERVER, {
  extraHeaders: { "ngrok-skip-browser-warning": true }
});

socket.on("connect", () => {
  console.log(`event: connect | session id: ${socket.id}`);
});

socket.on("connect_error", (err) => {
  console.log(`event: connect_error | reason: ${err.message}`);
});

socket.on("disconnect", (reason) => {
  console.log(`event: disconnect | reason: ${reason}`);
});

socket.onAny((event, ...args) => {
  console.log(`event: ${event} | arguments: ${args}`);
});

socket.on("notification", data => notifyMsg(data));

export default socket;