import React, { useState, useEffect } from "react";

import {
  Box,
} from "@mui/material";

import Header from "components/Header";
import DialogBox from "components/DialogBox";

import ProfileImage from "components/ProfileImage";
import ProfileForm from "components/ProfileForm";

import { useDispatch, useSelector } from "react-redux";
import {
  getProfileInfoApi,
  updateProfileInfoApi,
  updateProfileImageApi
} from "features/user/userApi";

const Account = () => {
  const dispatch = useDispatch();
  const { profileInfo, updateStatus } = useSelector((state) => state.user);

  const [formInfo, setFormInfo] = useState({});
  const [isPfpHovered, setIsPfpHovered] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);


  useEffect(() => {
    dispatch(getProfileInfoApi());
  }, []);

  const handlePfpChange = (imageFile) => {
    dispatch(updateProfileImageApi(imageFile));

    dispatch(getProfileInfoApi());
  };

  const handleSubmit = (formInfo) => {
    setFormInfo(formInfo);
    setSaveDialogOpen(true);
  };

  const handleProfileUpdate = () => {
    dispatch(updateProfileInfoApi(formInfo));
    setSaveDialogOpen(false);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Account" subtitle="Managing Your Profile" />
      <Box m="3rem 0" display="flex" alignItems="flex-start" justifyContent="space-around">
        <ProfileImage
          profileInfo={profileInfo}
          handlePfpChange={handlePfpChange}
          isPfpHovered={isPfpHovered}
          setIsPfpHovered={setIsPfpHovered}
        />
        {profileInfo.email &&
          <ProfileForm
            profileInfo={profileInfo}
            updateStatus={updateStatus}
            handleSubmit={handleSubmit}
            saveButtonText="Save"
          />
        }
      </Box>
      <DialogBox
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onConfirm={handleProfileUpdate}
        titleText="Save Confirmation"
        messageText="Are you sure you want to save this change?"
        confirmText="Confirm Save"
      />
    </Box>
  );
};

export default Account;