import React from "react";
import {
  TextField as MuiTextField,
  Box,
  Typography
} from "@mui/material";

const CustomTextField = ({ muiBoxSx, muiProps, inputProps, label, value, onChange }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "3px",
        ...muiBoxSx
      }}
    >
      <Typography variant="h6">
        {label}
      </Typography>
      <MuiTextField
        value={value}
        InputProps={{
          ...inputProps
        }}
        color="secondary"
        fullWidth
        onChange={onChange}
        {...muiProps}
      />
    </Box>
  );
};

export default CustomTextField;
