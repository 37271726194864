import React from 'react';

import { Box, LinearProgress, Typography } from '@mui/material';

const ProgressBar = ({ width, progress, text }) => {
  return (
    <Box
      sx={{
        width: width,
        height: "100%",
        position: "relative",
        margin: "0 15px"
      }}
    >
      <LinearProgress
        sx={{
          width: "100%",
          height: "52px",
          borderRadius: "5px",
        }}
        color="secondary"
        variant="determinate"
        value={typeof progress === "string"  ? 45 : progress}
      />
      <Typography
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
        }}
      >
        {
          typeof progress === "string"
            ? `${text}: ${progress}`
            : text
              ? `${text}: ${progress}%`
              : `${progress}%`
        }
      </Typography>
    </Box>
  );
};

export default ProgressBar;
