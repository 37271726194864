import api from "state/api";
import { getConfigWithBearerToken, notifyMsg } from "state/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createUserApi = createAsyncThunk("staff/createUser", async (credentials) => {
  try {
    const response = await api.post(
      "user/staff/create-user",
      credentials,
      getConfigWithBearerToken()
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error createUserApi:", error.message);
  } 
});

export const getUsersApi = createAsyncThunk("staff/getUsers", async ({ page, pageSize }) => {
  try {
    const response = await api.get(`user/staff/users?page=${page}&pageSize=${pageSize}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getUsersApi:", error.message);
  }
});