import { createSlice } from "@reduxjs/toolkit";
import {
  updateDevConfigApi,
  getDevConfigApi,
  getItemSpecificsApi,
  updateItemSpecificsApi,
  getCategoryApi
} from "./marketplacesApi";

const initialState = {
  devConfigs: {},
  updateStatus: "",
  itemSpecificsUpdate: "",
  itemSpecifics: [],
  categoryParentId: "",
  categoryName: "",
  leafCategory: false,
  subCategories: [],
  categories: [],
};

const marketplaceSlice = createSlice({
  name: "marketplaceSlice",
  initialState,
  reducers: {
    resetCategories: (state) => {
      state.categoryParentId = "";
      state.categoryName = "";
      state.leafCategory = false;
      state.subCategories = [];
      state.categories = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // updateDevConfigApi
      .addCase(updateDevConfigApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateDevConfigApi.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateDevConfigApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // getDevConfigApi
      .addCase(getDevConfigApi.fulfilled, (state, { payload }) => {
        state.devConfigs = payload.data ? payload.data : {};
      })
      // getCategoryApi
      .addCase(getCategoryApi.fulfilled, (state, { payload }) => {
        if (payload?.data?.leafCategory) {
          // hanlding
        } else if (payload?.data?.categoryParentId) {
          state.categoryParentId = payload.data.categoryParentId;
          state.categoryName = payload.data.categoryName;
          state.subCategories = payload.data.subCategories;
        } else {
          state.categories = payload.data;

          state.categoryParentId = "";
          state.subCategories = [];
        }
      })
  },
});

export const { resetCategories } = marketplaceSlice.actions;
export default marketplaceSlice.reducer;