import { createSlice } from "@reduxjs/toolkit";
import { createUserApi, getUsersApi } from "./staffApi";

const initialState = {
  createUserStatus: "",
  totalUsers: 0,
  users: []
};

const staffSlice = createSlice({
  name: "staffSlice",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.createUserStatus = "";
    }
  },
  extraReducers: (builder) => {
    builder
      // createUserApi
      .addCase(createUserApi.pending, (state) => {
        state.createUserStatus = "pending";
      })
      .addCase(createUserApi.fulfilled, (state, { payload }) => {
        state.createUserStatus = "fulfilled";
      })
      .addCase(createUserApi.rejected, (state) => {
        state.createUserStatus = "rejected";
      })
      // getUsersApi
      .addCase(getUsersApi.fulfilled, (state, { payload }) => {
        if (payload) {
          state.users = payload.data.users;
          state.totalUsers = payload.data.totalUsers;
        }
      });
  },
});

export const { clearStatus } = staffSlice.actions;
export default staffSlice.reducer;
