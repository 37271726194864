import api from "state/api";
import { getConfigWithBearerToken, notifyMsg } from "state/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSuppliersApi = createAsyncThunk("suppliers/getSuppliers", async ({ type, page, pageSize }) => {
  try {
    const response = await api.get(`suppliers/config?type=${type}&page=${page}&pageSize=${pageSize}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getSuppliersApi:", error.message);
  }
});

export const getSupplierInfoApi = createAsyncThunk("suppliers/getSupplierInfo", async (supplierId) => {
  try {
    const response = await api.get(`suppliers/config/${supplierId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getSupplierInfoApi:", error.message);
  }
});

export const updateSupplierInfoApi = createAsyncThunk("suppliers/updateSupplierInfo", async ({ supplierId, skuPrefix, backupInventoryHandlingSubtractionQuantity }) => {
  try {
    const response = await api.put(
      `suppliers/config/${supplierId}`,
      { skuPrefix, backupInventoryHandlingSubtractionQuantity },
      getConfigWithBearerToken()
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateSupplierInfoApi:", error.message);
  }
});

export const inventoryFileFormatApi = createAsyncThunk("suppliers/inventoryFileFormat", async ({ supplierId, supplier }) => {
  try {
    const response = await api.get(`suppliers/inventory/${supplierId}`, {
      responseType: 'blob',
      ...getConfigWithBearerToken()
    });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${supplier}-file-format.xlsx`;
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error inventoryFileFormatApi:', error.message);
  }
});

export const inventoryUploadApi = createAsyncThunk("suppliers/inventoryUpload", async ({ supplierId, file }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      `suppliers/inventory/${supplierId}`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error inventoryUploadApi:", error.message);
  }
});

export const fixedShippingFormatApi = createAsyncThunk("suppliers/fixedShippingFormat", async () => {
  try {
    const response = await api.get(`suppliers/shipping/fixed-shipping-format`, {
      responseType: 'blob',
      ...getConfigWithBearerToken()
    });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "fixed-shipping-format.xlsx";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error fixedShippingFormatApi:', error.message);
  }
});

export const fixedShippingUploadApi = createAsyncThunk("suppliers/fixedShippingUpload", async ({ supplierId, file }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`suppliers/shipping/fixed-shipping/${supplierId}`, formData, {
      headers: {
        ...getConfigWithBearerToken().headers,
        'Content-Type': 'multipart/form-data'
      }
    });

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fixedShippingUploadApi:", error.message);
  }
});

export const updateShippingWeightsApi = createAsyncThunk("suppliers/updateShippingWeights", async ({ supplierId, shippingWeights }) => {
  try {
    const response = await api.put(
      `suppliers/shipping/shipping-weights/${supplierId}`,
      { shippingWeights },
      getConfigWithBearerToken()
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateShippingWeightsApi:", error.message);
  }
});

export const getInventoryDataApi = createAsyncThunk("suppliers/getInventoryData", async ({ page, pageSize }) => {
  try {
    const response = await api.get(`/suppliers/inventory?page=${page}&pageSize=${pageSize}`, getConfigWithBearerToken());

    return response.data;
  } catch (error) {
    console.error("Error getInventoryDataApi:", error.message);
  }
});

export const deleteInventoryUploadApi = createAsyncThunk("suppliers/deleteInventoryUpload", async (uploadId) => {
  try {
    const response = await api.delete(`/suppliers/inventory/upload/${uploadId}`, getConfigWithBearerToken());

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleteInventoryUploadApi:", error.message);
  }
});

export const downloadInventoryUploadApi = createAsyncThunk("suppliers/downloadInventoryUpload", async ({ uploadId, supplier, fileName }) => {
  try {
    const response = await api.get(`/suppliers/inventory/upload/${uploadId}`, {
      responseType: 'blob',
      ...getConfigWithBearerToken()
    });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${supplier}-${fileName}`;
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloadInventoryUploadApi:", error.message);
  }
});

export const searchItemsApi = createAsyncThunk("suppliers/searchItems", async ({ search, page }) => {
  try {
    const response = await api.get(`suppliers/config/items?search=${search}&page=${page}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error searchItemsApi:", error.message);
  }
});