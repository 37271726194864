import api from "state/api";
import { getConfigWithBearerToken, notifyMsg } from "state/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const updateDevConfigApi = createAsyncThunk("marketplacesy/updateDevConfig", async (payload) => {
  try {
    const response = await api.put(
      "/marketplaces/dev-config",
      payload,
      getConfigWithBearerToken()
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateDevConfigApi:", error.message);
  }
});

export const getDevConfigApi = createAsyncThunk("marketplaces/getDevConfig", async () => {
  try {
    const response = await api.get(`/marketplaces/dev-config`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getDevConfigApi:", error.message);
  }
});

export const updateEbayAuthorizatioCodeApi = createAsyncThunk("marketplaces/updateEbayAuthorizatioCode", async ({ storeId, code }) => {
  try {
    const response = await api.post(`/marketplaces/ebay-authorizatio-code?code=${code}&storeId=${storeId}`, {}, getConfigWithBearerToken());
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateEbayAuthorizatioCodeApi:", error.message);
  } 
});

export const getCategoryApi = createAsyncThunk("marketplaces/getCategory", async ({ categoryId }) => {
  try {
    const response = await api.get(
      `/marketplaces/ebay/category?categoryId=${categoryId}`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getCategoryApi:", error.message);
  }
});