import React from "react";

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

const Filter = ({
  type,
  label,
  filter,
  custom,
  value,
  onChange,
}) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 135 }}>
      <InputLabel
        id={`select-${type}-range-label`}
        color="secondary"
      >{label}</InputLabel>
      <Select
        labelId={`select-${type}-range-label`}
        value={value}
        label={label}
        onChange={onChange}
        color="secondary"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {filter.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.option}
          </MenuItem>
        ))}
        {custom &&
          <MenuItem value="custom">
            Custom
          </MenuItem>
        }
      </Select>
    </FormControl>
  );
};

export default Filter;