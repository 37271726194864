import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import socket from "app/socket";

import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  IconButton,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  ImageList,
  ImageListItem,
  Checkbox,
  FormControlLabel,
  Switch,
  Fab,
  Stack,
  AntSwitch
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  DataGrid,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { MuiFileInput } from 'mui-file-input';

import {
  CollectionsOutlined,
  Delete,
  Download,
  ArrowBack,
  ArrowForward
} from "@mui/icons-material";

import Header from "components/Header";
import DialogBox from "components/DialogBox";
import FlexBetween from "components/FlexBetween";
import ProgressBar from "components/ProgressBar";

import { useDispatch, useSelector } from "react-redux";

import {
  getCreationListingsApi,
  deleteCreationListingsApi,
  startListingsCreationApi,
  listingUploadCreationApi,
  getCreationUploadsApi,
  listingsMarketplacePushApi,
  getCategoryToPartNameMappingApi,
  creationSpecificItemsFormatApi,
  updateCreationListingApi,
} from "features/listings/listingsApi";
import {
  getCategoryApi
} from "features/marketplaces/marketplacesApi";
import { getStoresApi } from "features/stores/storesApi";
import {
  initializeAnalyzerApi
} from "features/jobs/jobsApi";

const CreateListings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    creationListings,
    creationListingsTotalResults,
    creationListingsDeleteStatus,
    categoryToPartNameMappings
  } = useSelector((state) => state.listings);
  const { storeIdPairs } = useSelector((state) => state.stores);

  const [listing, setListing] = useState({});
  const [selectedListings, setSelectedListings] = useState([]);

  const [listingId, setListingId] = useState("");

  const [images, setImages] = useState([]);
  const [otherImages, setOtherImages] = useState([]);
  const [hovering, setHovering] = useState(false);
  const [itemsFile, setItemsFile] = useState(null);
  const [specificItemsFile, setSpecificItemsFile] = useState(null);

  const [marketplace, setMarketplace] = useState("ebay");
  const [selectedStores, setSelectedStores] = useState([]);
  const [categoriesCreationMethod, setCategoriesCreationMethod] = useState(false);
  const [inventoryCreation, setInventoryCreation] = useState(false);
  const [sellersSelectionOpen, setSellersSelectionOpen] = useState(false);

  const [selectedCreationCategories, setSelectedCreationCategories] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [pushListingDialogOpen, setPushListingDialogOpen] = useState(false);

  // const [itemDetailsDialogOpen, setItemDetailsDialogOpen] = useState(false);

  const [startCreationDialogOpen, setStartCreationDialogOpen] = useState(false);

  const [uploadItemsFileDialogOpen, setUploadItemsFileDialogOpen] = useState(false);

  const [deleteSelectedDialogOpen, setDeleteSelectedDialogOpen] = useState(false);
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [analyzeListingDialogOpen, setAnalyzeListingDialogOpen] = useState(false);

  const [listingCreationProgress, setListingCreationProgress] = useState(0);
  const [listingPushProgress, setListingPushProgress] = useState(0);
  const [itemSpecificsUploadProgress, setItemSpecificsUploadProgress] = useState(0);

  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 0.8,
    },
    {
      field: "partName",
      headerName: "Part Name",
      flex: 0.8
    },
    {
      field: "store",
      headerName: "Store",
      flex: 0.7,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 0.8,
    },
    {
      field: "images",
      headerName: "Images",
      flex: 0.7,
      renderCell: (params) => (
        <Tooltip title={`Images ${params.value.length}`}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative", // Add this to the parent Box
            }}
          >
            {params.value.length > 0 ? (
              <div
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={`${params.value[0]}`}
                  alt="Image"
                  style={{ height: "50px", borderRadius: "5px" }}
                />
                {hovering && (
                  <IconButton
                    size="large"
                    onClick={() => handleImageDialogOpen(params.row._id)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px", // Adjust the positioning as needed
                      background: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <CollectionsOutlined
                      fontSize="large"
                      sx={{
                        color: "#fff",
                      }}
                    />
                  </IconButton>
                )}
              </div>
            ) : (
              <IconButton
                size="large"
                onClick={() => handleImageDialogOpen(params.row._id)}
              >
                <CollectionsOutlined
                  fontSize="large"
                  sx={{
                    color: "#fff",
                  }}
                />
              </IconButton>
            )}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 0.7,
      renderCell: (params) => (
        <Tooltip title={params.row.errors.join("\n")}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              padding: "6px",
              fontWeight: "semibold",
              color: "white",
              backgroundColor: params.row.errors.length ? "#BB2722" : "none"
            }}
          >
            {params.value || "None"}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "partslink",
      headerName: "partslink",
      flex: 0.8,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1.6,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box
            sx={{
              height: '100%',
              width: '98%',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              size="small"
              defaultValue={params.value}
              InputProps={{
                readOnly: true
              }}
              fullWidth
              label={`Title ${params.value.length}/80`}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                  e.stopPropagation();
                }
              }}
              placeholder="Title..."
              color="secondary"
              type="text"
            />
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "startPrice",
      headerName: "Price",
      flex: 0.6
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 0.7
    },
    {
      field: "itemSpecificsCount",
      headerName: "Item Specifics",
      flex: 0.7
    },
    {
      field: "compatibilityCount",
      headerName: "Compatibility",
      flex: 0.7
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            '& > *': {
              margin: '0 5px',
            },
          }}
        >
          <IconButton
            onClick={() => {
              setListing(creationListings.find(obj => obj._id === params.row._id));
              setDeleteDialogOpen(true);
            }}
          >
            <Delete sx={{
              color: "#fff"
            }} />
          </IconButton>
        </Box >
      )
    },
  ];

  useEffect(() => {
    dispatch(getCreationListingsApi({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }));

    dispatch(getStoresApi({ type: 'id-pairs', marketplace }));

    dispatch(getCategoryApi({ categoryId: "" }));
    dispatch(getCategoryToPartNameMappingApi());

    socket.emit("getSupplierCreationProgress");
  }, [marketplace]);

  useEffect(() => {
    dispatch(getCreationListingsApi({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }));
  }, [paginationModel]);

  useEffect(() => {
    if (creationListingsDeleteStatus !== "pending") {
      dispatch(getCreationListingsApi({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize
      }));
    }
  }, [creationListingsDeleteStatus]);

  useEffect(() => {
    socket.on("listingCreationProgress", (data) => {
      setListingCreationProgress(data.progress);
    });

    socket.on("listingPushProgress", (data) => {
      setListingPushProgress(data.progress);
    });

    socket.on("itemSpecificsUploadProgress", (data) => {
      setItemSpecificsUploadProgress(data.progress);
    });

    socket.on("getSupplierCreationProgress", (data) => {
      setListingCreationProgress(data.progress);
    });

    socket.on("creationListingStatusData", (data) => {
      // Convert array of objects to CSV
      const arrayData = data.listingCreationStatus; // This should be an array of objects
      const csvHeaders = ["Partslink", "Status"];
      const csvRows = arrayData.map(obj => `${obj.partslink},${obj.status}`);
      const csvString = [csvHeaders.join(','), ...csvRows].join('\n');
      const filename = "creationListingStatus.csv";

      // Create a Blob from the CSV string
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      // Create a link to download the file
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;

      // Append to the document and click the link to start the download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link and revoke the object URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });

    return () => {
      socket.off("listingCreationProgress");
      socket.off("listingPushProgress");
      socket.off("itemSpecificsUploadProgress");

      socket.off("getSupplierCreationProgress");

      socket.off("creationListingStatusData");
    };
  }, []);

  useEffect(() => {
    dispatch(getCreationListingsApi({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }));
  }, [listingCreationProgress]);

  useEffect(() => {
    if (itemSpecificsUploadProgress === 0) {
      dispatch(getCreationListingsApi({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize
      }));
    }
  }, [itemSpecificsUploadProgress]);


  const handleImageDialogOpen = (id) => {
    setImages(creationListings.find(obj => obj._id === id).images);
    setOtherImages(creationListings.find(obj => obj._id === id)?.infomation?.sellerImages);
    setImageDialogOpen(true);

    setListingId(id);
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title="Create Listings" subtitle="Create Listings Manage For Your Store" />
      <Box
        m="1.5rem 0 0.5rem 0"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: marketplace === "ebay" ? "60%" : "40%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{
              width: marketplace === "ebay" ? "32%" : "48%",
              padding: "15px 15px",
              whiteSpace: 'nowrap',
              fontSize: '12px',
            }}
            onClick={() => setStartCreationDialogOpen(true)}
          >
            Start Creation
          </LoadingButton>
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{
              width: marketplace === "ebay" ? "32%" : "48%",
              padding: "15px 15px",
              whiteSpace: 'nowrap',
              fontSize: '12px',
            }}
            onClick={() => setPushListingDialogOpen(true)}
          >
            Push Listings To marketplace
          </LoadingButton>
          {
            marketplace === "ebay" &&
            <LoadingButton
              color="secondary"
              variant="contained"
              sx={{
                width: "32%",
                padding: "15px 15px",
                whiteSpace: 'nowrap',
                fontSize: '12px',
              }}
              onClick={() => setAnalyzeListingDialogOpen(true)}
            >
              Analyze Listing
            </LoadingButton>
          }
        </Box>
        {
          listingCreationProgress > 0 &&
          <ProgressBar
            width={"20%"}
            progress={listingCreationProgress}
            text="Listing Creation"
          />
        }
        {
          listingPushProgress > 0 &&
          <ProgressBar
            width={"20%"}
            progress={listingPushProgress}
            text="Marketplace Push"
          />
        }
        {
          itemSpecificsUploadProgress > 0 &&
          <ProgressBar
            width={"20%"}
            progress={itemSpecificsUploadProgress}
            text="Item Specifics Upload"
          />
        }
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20%",
            pading: "15px"
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography fontWeight="bold" variant="h4" p="3px">Amazon</Typography>
            <FormControlLabel
              control={<Switch
                color="secondary"
                checked={marketplace === "ebay"}
                onChange={() => {
                  setMarketplace(marketplace === "ebay" ? "amazon" : "ebay")
                }}
              />}
            />
            <Typography fontWeight="bold" variant="h4" p="3px">eBay</Typography>
          </Stack>
        </Box>
      </Box>
      <Box
        height="72vh"
        sx={{
          marginTop: "1rem",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              borderBottom: `1px solid ${theme.palette.secondary[200]} !important`,
            },
            "& .MuiDataGrid-cell:focus": {
              outline: `none !important`,
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-checkboxInput": {
              color: `${theme.palette.secondary[400]} !important`,
            },
          }}
          getRowId={(row) => row._id}
          rows={creationListings || []}
          columns={columns}
          rowCount={creationListingsTotalResults}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          checkboxSelection
          rowSelectionModel={selectedListings}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedListings(newSelection);
          }}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <FlexBetween width="100%">
                  <FlexBetween width="40%" mb="20px">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => navigate("/stores/create-listings/settings")}
                      sx={{
                        width: "32%"
                      }}
                    >
                      Settings
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setDeleteAllDialogOpen(true)}
                      sx={{
                        width: "32%"
                      }}
                    >
                      Delete All
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setDeleteSelectedDialogOpen(true)}
                      sx={{
                        width: "32%"
                      }}
                    >
                      Delete Selected
                    </Button>
                  </FlexBetween>
                </FlexBetween>
              </GridToolbarContainer>
            ),
          }}
          disableRowSelectionOnClick
        />
      </Box>
      <Dialog
        open={imageDialogOpen}
        onClose={() => setImageDialogOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="md"
        fullWidth
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400],
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography fontWeight="bold" variant="h4" p="3px" mb="20px">
                Manage Listing Images
              </Typography>
              <Button
                variant="contained"
                sx={{
                  width: marketplace === "ebay" ? "32%" : "48%",
                  padding: "15px 15px",
                  whiteSpace: 'nowrap',
                  fontSize: '12px',
                  marginLeft: "40px"
                }}
                onClick={() => {
                  dispatch(updateCreationListingApi({
                    listingId,
                    images
                  }));

                  dispatch(getCreationListingsApi({
                    page: paginationModel.page + 1,
                    pageSize: paginationModel.pageSize
                  }));

                  setImageDialogOpen(false)
                }}
                color="secondary"
              >
                Save
              </Button>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageList variant="masonry" sx={{ width: "90%" }} cols={3} gap={8}>
                  {images.map((image, index) => (
                    <ImageListItem key={index} sx={{ position: 'relative' }}>
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          paddingTop: `${1 * 100}%`,
                        }}
                      >
                        <img
                          srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          src={`${image}?w=248&fit=crop&auto=format`}
                          alt={image}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                      {
                        index > 0 &&
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="move backward"
                          sx={{
                            position: 'absolute',
                            bottom: '10px',
                            right: index < images.length - 1
                              ? '110px'
                              : '60px'
                          }}
                          onClick={() => {
                            const updatedImages = [...images];
                            const movedImage = updatedImages.splice(index, 1)[0];
                            updatedImages.splice(index - 1, 0, movedImage);

                            setImages(updatedImages);
                          }}
                        >
                          <ArrowBack />
                        </Fab>
                      }
                      {
                        index < images.length - 1 &&
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="move forward"
                          sx={{
                            position: 'absolute',
                            bottom: '10px',
                            right: '60px',
                          }}
                          onClick={() => {
                            const updatedImages = [...images];
                            const movedImage = updatedImages.splice(index, 1)[0];
                            updatedImages.splice(index + 1, 0, movedImage);

                            setImages(updatedImages);
                          }}
                        >
                          <ArrowForward />
                        </Fab>
                      }
                      <Fab
                        size="small"
                        color="secondary"
                        aria-label="delete"
                        sx={{
                          position: 'absolute',
                          bottom: '10px',
                          right: '10px',
                        }}
                        onClick={() => setImages(images.filter((_, number) => number !== index))}
                      >
                        <Delete />
                      </Fab>
                      <Typography
                        fontWeight="bold"
                        variant="h4"
                        p="3px"
                        sx={{
                          position: 'absolute',
                          top: '5px',
                          left: '5px',
                          color: "black"
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
              {
                Array.isArray(otherImages) && otherImages.map((sellerImages, index) => (
                  <>
                    <Typography fontWeight="bold" variant="h4" p="3px" mt="20px" mb="20px">
                      Seller {index + 1}
                    </Typography>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        width: marketplace === "ebay" ? "32%" : "48%",
                        padding: "15px 15px",
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        marginLeft: "40px"
                      }}
                      onClick={() => setImages(sellerImages)}
                    >
                      Select Images
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ImageList variant="masonry" sx={{ width: "90%" }} cols={3} gap={8}>
                        {sellerImages.map((image, index) => (
                          <ImageListItem key={index} sx={{ position: 'relative' }}>
                            <Box
                              sx={{
                                position: 'relative',
                                width: '100%',
                                paddingTop: `${1 * 100}%`,
                              }}
                            >
                              <img
                                srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${image}?w=248&fit=crop&auto=format`}
                                alt={image}
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  objectFit: "contain",
                                }}
                              />
                            </Box>
                            <Typography
                              fontWeight="bold"
                              variant="h4"
                              p="3px"
                              sx={{
                                position: 'absolute',
                                top: '5px',
                                left: '5px',
                                color: "black"
                              }}
                            >
                              {index + 1}
                            </Typography>
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Box>
                  </>
                ))
              }
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setImageDialogOpen(false)} color="secondary">
              Close
            </Button>
            <Box
              sx={{
                flex: 1
              }}
            >
            </Box>
            <Button
              onClick={() => {
                dispatch(updateCreationListingApi({
                  listingId,
                  images
                }));

                dispatch(getCreationListingsApi({
                  page: paginationModel.page + 1,
                  pageSize: paginationModel.pageSize
                }));

                setImageDialogOpen(false)
              }}
              color="secondary"
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={startCreationDialogOpen}
        onClose={() => setStartCreationDialogOpen(false)}
        aria-labelledby="dialog-creation"
        aria-describedby="dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400],
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography fontWeight="bold" variant="h4" p="3px" mb="-10px">
                Creation Start
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                  padding: "15px 0",
                  gap: "25px"
                }}
              >
                <Typography fontWeight="bold" variant="h5" p="3px" mb="-10px">
                  Select Store To Create Listing For
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    id="select-store-label"
                    color="secondary"
                  >Stores</InputLabel>
                  <Select
                    color="secondary"
                    labelId="select-store-label"
                    id="select-store"
                    multiple
                    value={selectedStores}
                    renderValue={(selected) => {
                      return selected.map((id, index) => {
                        // Find the corresponding storeName for each selected ID
                        const store = storeIdPairs.find((store) => store._id === id);
                        return store ? store.storeName : '';
                      }).join(', ');
                    }}
                    label="Stores"
                    onChange={(e) => setSelectedStores(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {storeIdPairs &&
                      storeIdPairs.map((store) => (
                        <MenuItem key={store._id} value={store._id}>
                          <Checkbox
                            checked={selectedStores.indexOf(store._id) > -1}
                            color="secondary"
                          />
                          {store.storeName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={<Switch
                    color="secondary"
                    checked={inventoryCreation}
                    onChange={() => {
                      setInventoryCreation(!inventoryCreation)
                      if (!inventoryCreation) {
                        setCategoriesCreationMethod(false);
                        setSellersSelectionOpen(false)
                      }
                    }}
                  />}
                  label="Inventory Creation"
                />
                {
                  inventoryCreation &&
                  <FormControlLabel
                    control={<Switch
                      color="secondary"
                      checked={sellersSelectionOpen}
                      onChange={() => {
                        setSellersSelectionOpen(!sellersSelectionOpen)
                      }}
                    />}
                    label="Sellers Selection Open"
                  />
                }
                {
                  marketplace === "ebay" && !inventoryCreation &&
                  <FormControlLabel
                    control={<Switch
                      color="secondary"
                      checked={categoriesCreationMethod}
                      onChange={() => setCategoriesCreationMethod(!categoriesCreationMethod)}
                    />}
                    label="Categories Creation Method"
                  />
                }

                {
                  categoriesCreationMethod
                    ?
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "25px"
                      }}
                    >
                      <Typography fontWeight="bold" variant="h5" p="3px" mb="-10px">
                        Select Listing Categories To Create
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel
                          id="select-category-label"
                          color="secondary"
                        >Select Category</InputLabel>
                        <Select
                          color="secondary"
                          labelId="select-category-label"
                          multiple
                          value={selectedCreationCategories}
                          renderValue={(selected) => {
                            return selected.map((id, index) => {
                              return categoryToPartNameMappings.find(({ category }) => category === id)?.categoryName || "";
                            }).join(', ');
                          }}
                          label="Select Category"
                          onChange={(e) => setSelectedCreationCategories(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 48 * 4.5 + 8,
                                width: 250,
                              },
                            },
                          }}
                        >
                          {categoryToPartNameMappings &&
                            categoryToPartNameMappings.map(({ category, categoryName }) => (
                              <MenuItem key={category} value={category}>
                                <Checkbox
                                  checked={selectedCreationCategories.indexOf(category) > -1}
                                  color="secondary"
                                />
                                {categoryName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    :
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "25px"
                      }}
                    >
                      <Typography fontWeight="bold" variant="h5" p="3px" mb="-10px">
                        Select Specific Item's File
                      </Typography>
                      <MuiFileInput
                        color="secondary"
                        value={specificItemsFile}
                        onChange={(file) => setSpecificItemsFile(file)}
                        sx={{
                          width: "100%"
                        }}
                      />
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        sx={{
                          width: "50%"
                        }}
                        onClick={() => dispatch(creationSpecificItemsFormatApi())}
                        startIcon={<Download color="black" />}
                      >
                        Format
                      </LoadingButton>
                    </Box>
                }
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setStartCreationDialogOpen(false)} color="secondary">
              Close
            </Button>
            <Button
              onClick={() => {
                dispatch(startListingsCreationApi({
                  file: specificItemsFile,
                  inventoryCreation,
                  sellersSelectionOpen,
                  categoryIds: selectedCreationCategories,
                  stores: selectedStores,
                  marketplace
                }));

                setSpecificItemsFile(null);
                setSelectedCreationCategories([]);
                setSelectedStores([]);
                setInventoryCreation(false);

                setStartCreationDialogOpen(false);
              }}

              color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <DialogBox
        open={deleteSelectedDialogOpen}
        onClose={() => setDeleteSelectedDialogOpen(false)}
        onConfirm={() => {
          dispatch(deleteCreationListingsApi({ ids: selectedListings }))
          setDeleteSelectedDialogOpen(false)
        }}
        titleText="Delete Confirmation"
        messageText="Are you sure you wish to delete these creation listing?"
        confirmText="Confirm"
      />
      <DialogBox
        open={deleteAllDialogOpen}
        onClose={() => setDeleteAllDialogOpen(false)}
        onConfirm={() => {
          dispatch(deleteCreationListingsApi({ ids: "all" }))
          setDeleteAllDialogOpen(false)
        }}
        titleText="Delete Confirmation"
        messageText="Are you sure you wish to delete all creation listings?"
        confirmText="Confirm"
      />
      <DialogBox
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          dispatch(deleteCreationListingsApi({ ids: [listing._id] }))
          setDeleteDialogOpen(false)
        }}
        titleText="Delete Confirmation"
        messageText="Are you sure you wish to delete this creation listing?"
        confirmText="Confirm"
      />
      <DialogBox
        open={uploadItemsFileDialogOpen}
        onClose={() => setUploadItemsFileDialogOpen(false)}
        onConfirm={() => {
          dispatch(listingUploadCreationApi({
            file: itemsFile
          }));

          dispatch(getCreationUploadsApi());

          setItemsFile(null);
          setUploadItemsFileDialogOpen(false);
        }}
        titleText="Upload Items Confirmation"
        messageText="Are you sure you wish to upload these items?"
        confirmText="Confirm"
      />
      <DialogBox
        open={pushListingDialogOpen}
        onClose={() => setPushListingDialogOpen(false)}
        onConfirm={() => {
          dispatch(listingsMarketplacePushApi(marketplace));
          setPushListingDialogOpen(false);
        }}
        titleText="Push Listings Confirmation"
        messageText="Are you sure you wish to push these listings to there marketplace?"
        confirmText="Confirm"
      />
      <DialogBox
        open={analyzeListingDialogOpen}
        onClose={() => setAnalyzeListingDialogOpen(false)}
        onConfirm={() => {
          dispatch(initializeAnalyzerApi({ creation: true }));

          setAnalyzeListingDialogOpen(false);
        }}
        titleText="Analyze Listing Confirmation"
        messageText="Are you sure you wish to analyze these listings?"
        confirmText="Confirm"
      />
    </Box>
  );
};

export default CreateListings;