import api from "state/api";
import { getConfigWithBearerToken, notifyMsg } from "state/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getShipStationStoreApi = createAsyncThunk("ebay/getShipStationStore", async () => {
  try {
    const response = await api.get(`/orders/ship-station/stores`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getShipStationStoreApi:", error.message);
  }
});
