import { useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import {
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { useSelector } from "react-redux";
import { themeSettings } from "theme";

import LoadingBar from 'react-top-loading-bar'

import socket from "app/socket";

import ProtectedRoute from "auth/ProtectedRoute";
import SignIn from "scenes/signIn";

import Layout from "scenes/layout";

import Dashboard from "scenes/dashboard";
import Account from "scenes/account";
import Users from "scenes/users";
import CreateUser from "scenes/createUser";
import Listings from "scenes/listings";
import ListingEdit from "scenes/listingEdit";
import Suppliers from "scenes/suppliers";
import SupplierEdit from "scenes/supplierEdit";
import Stores from "scenes/stores";
import EditStore from "scenes/editStore";
import StoreEdit from "scenes/storeEdit";
import CreateStore from "scenes/createStore";
import Inventory from "scenes/inventoryTimeline";
import SourcingSetup from "scenes/sourcingSetup";
import Jobs from "scenes/jobs";
import ShipStation from "scenes/shipStation";
import Settings from "scenes/settings";
import CreateListings from "scenes/createListings";
import ListingCreationSettings from "scenes/listingCreationSettings";
import Analyzer from "scenes/analyzer";
import SuppliersSearch from "scenes/suppliersSearch";


function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const [progress, setProgress] = useState(0)

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <CssBaseline />
            <LoadingBar
              color='#ffd166'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route element={<ProtectedRoute setProgress={setProgress} element={<Layout />} />}>
                <Route index element={<Navigate to="/listings" replace />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/listings" element={<Listings setProgress={setProgress} />} />
                <Route path="/listings/:listingId" element={<ListingEdit />} />

                <Route path="/stores" element={<Stores />} />
                <Route path="/stores/:storeId/sourcing setup" element={<SourcingSetup />} />
                <Route path="/stores/:storeId/edit" element={<EditStore />} />
                <Route path="/stores/:storeId" element={<StoreEdit />} />
                <Route path="/stores/create" element={<CreateStore />} />
                <Route path="/stores/create-listings" element={<CreateListings />} />
                <Route path="/stores/create-listings/settings" element={<ListingCreationSettings />} />

                <Route path="/users" element={<Users />} />
                <Route path="/create-user" element={<CreateUser />} />
                <Route path="/account" element={<Account />} />            

                <Route path="/suppliers" element={<Suppliers />} />
                <Route path="/suppliers/:supplierId" element={<SupplierEdit />} />
                <Route path="/suppliers/search" element={<SuppliersSearch />} />

                <Route path="/inventory-timeline" element={<Inventory />} />
                <Route path="/settings" element={<Settings />} />

                <Route path="/ship-station" element={<ShipStation />} />

                <Route path="/automations" element={<Jobs />} />

                <Route path="/analyzer" element={<Analyzer />} />
              </Route>
            </Routes>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
