import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  Box,
  TextField,
  useTheme,
  Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MuiFileInput } from 'mui-file-input';

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";
import {
  getDevConfigApi,
  updateDevConfigApi
} from "features/marketplaces/marketplacesApi";

const Settings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    updateStatus,
    devConfigs
  } = useSelector((state) => state.marketplace);

  const [ebayDevConfig, setEbayDevConfig] = useState({});
  const [amazonDevConfig, setAmazonDevConfig] = useState({});
  const [shipStationDevConfig, setShipStationDevConfig] = useState({});

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getDevConfigApi());
  }, []);

  useEffect(() => {
    if (devConfigs) {
      setEbayDevConfig(devConfigs.ebay);
      setAmazonDevConfig(devConfigs.amazon);
      setShipStationDevConfig(devConfigs.shipStation);
    }
  }, [devConfigs]);

  const handleUpdate = () => {
    if (
      JSON.stringify(devConfigs.ebay) !== JSON.stringify(ebayDevConfig)
      || JSON.stringify(devConfigs.amazon) !== JSON.stringify(amazonDevConfig)
      || JSON.stringify(devConfigs.shipStation) !== JSON.stringify(shipStationDevConfig)
    ) {
      dispatch(updateDevConfigApi({
        credentials: {
          ebay: ebayDevConfig,
          amazon: amazonDevConfig,
          shipStation: shipStationDevConfig
        }
      }));
    }
    
    setSaveDialogOpen(false);
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title="Marketplace Dev" subtitle="Edit And Manage Your eBay Marketplace" />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="eBay Dev Credentials" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <TextField
              id="outlined-textarea"
              label="Client Id"
              placeholder="Client Id..."
              value={ebayDevConfig?.clientId || ""}
              onChange={(e) => setEbayDevConfig({
                ...ebayDevConfig,
                clientId: e.target.value
              })}
              fullWidth
              color="secondary"
            />
            <TextField
              id="outlined-textarea"
              label="Client Secret"
              placeholder="Client Secret..."
              value={ebayDevConfig?.clientSecret || ""}
              onChange={(e) => setEbayDevConfig({
                ...ebayDevConfig,
                clientSecret: e.target.value
              })}
              fullWidth
              color="secondary"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Amazon Dev Credentials" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <TextField
              id="outlined-textarea"
              label="Client Id"
              placeholder="Client Id..."
              value={amazonDevConfig?.clientId || ""}
              onChange={(e) => setAmazonDevConfig({
                ...amazonDevConfig,
                clientId: e.target.value
              })}
              fullWidth
              color="secondary"
            />
            <TextField
              id="outlined-textarea"
              label="Client Secret"
              placeholder="Client Secret..."
              value={amazonDevConfig?.clientSecret || ""}
              onChange={(e) => setAmazonDevConfig({
                ...amazonDevConfig,
                clientSecret: e.target.value
              })}
              fullWidth
              color="secondary"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Ship Station Dev Credentials" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <TextField
              id="outlined-textarea"
              label="API Key"
              placeholder="API Key..."
              value={shipStationDevConfig?.apiKey || ""}
              onChange={(e) => setShipStationDevConfig({
                ...shipStationDevConfig,
                apiKey: e.target.value
              })}
              fullWidth
              color="secondary"
            />
            <TextField
              id="outlined-textarea"
              label="API Secret"
              placeholder="API Secret..."
              value={shipStationDevConfig?.apiSecret || ""}
              onChange={(e) => setShipStationDevConfig({
                ...shipStationDevConfig,
                apiSecret: e.target.value
              })}
              fullWidth
              color="secondary"
            />
          </Box>
        </Box>
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px",
            alignSelf: "flex-end"
          }}
          onClick={() => setSaveDialogOpen(true)}
          loading={updateStatus === "pending"}
        >
          Save
        </LoadingButton>
      </Box>
      <DialogBox
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onConfirm={handleUpdate}
        titleText="Save Confirmation"
        messageText="Are you sure you want to save this change?"
        confirmText="Save"
      />
    </Box>
  );
}

export default Settings;
