import { createSlice } from "@reduxjs/toolkit";
import {
  getListingsApi,
  getListingsXlsxApi,
  getListingApi,
  updateListingApi,
  updateListingsFromXlsxApi,
  deleteListingApi,
  syncListingsApi,
  getCreationListingsApi,
  deleteCreationListingsApi,
  listingUploadCreationApi,
  replaceCreationUploadApi,
  getCreationUploadsApi,
  deleteCreationUploadApi,
  itemSpecificsUploadApi,
  getItemTitleFormatsApi,
  addItemTitleFormatsApi,
  deleteItemTitleFormatsApi,
  getItemPartNamesApi,
  getItemPartNamesFileApi,
  getCategoryToPartNameMappingApi,
  updateCategoryToPartNameMappingApi,
  getItemPartNamesToCategoryMappingFileApi,
  deleteCategoryToPartNameMappingApi,
  getSkuMappingInfoApi,
  uploadSkuMappingApi,
  getInventoryCreationInfoApi,
  downloadInventoryCreationItemsApi,
  externallyAssignedProductIdentifiersDownloadApi
} from "./listingsApi";

const initialState = {
  listingsLoading: false,
  listingsXlsxLoading: false,
  updateStatus: "",
  deleteStatus: "",
  updateFromXlsxStatus: "",
  syncStatus: "",
  listings: [],
  totalResults: 0,
  totalPages: 0,
  filters: {
    marketplace: 'ebay',
    supplier: '',
    store: '',
    price: {
      minimum: '',
      maximum: ''
    },
    quantity: {
      minimum: '',
      maximum: ''
    },
    soldQuantity: {
      minimum: '',
      maximum: ''
    },
    nonSynced: false,
    synced: false,
    fixedPrice: false,
    errorType: ''
  },
  listingInfo: {},
  // creation
  creationListings: [],
  creationListingsLoadingStatus: "",
  creationListingsTotalResults: 0,
  creationListingUpdateStatus: "",
  creationListingsDeleteStatus: "",
  listingUploadCreationStatus: "",
  replaceUploadCreationStatus: "",
  getUploadCreationStatus: "",
  deleteUploadCreationStatus: "",
  creationUploads: [],
  itemSpecificsLastUploadDate: "",
  skuMappingLastUploadDate: "",
  skuMappingUploadStatus: "",
  itemSpecificsUploadStatus: "",
  titleFormats: [],
  getItemTitleFormatStatus: "",
  addItemTitleFormatStatus: "",
  deleteItemTitleFormatStatus: "",
  itemPartNames: [],
  getItemPartNamesFileStatus: "",
  getItemPartNamesToCategoryMappingFileStatus: "",
  updateCategoryToPartNameMappingStatus: "",
  deleteCategoryToPartNameMappingStatus: "",
  categoryToPartNameMappings: [],
  inventoryCreationLastUploadDate: "",
  downloadInventoryCreationItemsStatus: "",
  downloadIdentifiersItemsStatus: "",
};

const listingsSlice = createSlice({
  name: "listingsSlice",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.createUserStatus = "";
    },
    updateFilter: (state, { payload }) => {
      const { type, value } = payload;
      state.filters[type] = value;
    },
    updateRangeFilter: (state, { payload }) => {
      const { type, value } = payload;
      state.filters[type].minimum = value.split('-')[0];
      state.filters[type].maximum = value.split('-')[1];
    },
    clearFilters: (state) => {
      state.filters = {
        supplier: '',
        store: '',
        price: {
          minimum: '',
          maximum: ''
        },
        quantity: {
          minimum: '',
          maximum: ''
        },
        soldQuantity: {
          minimum: '',
          maximum: ''
        },
        nonSynced: false,
        synced: false,
        fixedPrice: false,
        // errorType: ''
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // getListingsApi
      .addCase(getListingsApi.pending, (state) => {
        state.listingsLoading = true;
      })
      .addCase(getListingsApi.fulfilled, (state, { payload }) => {
        if (payload) {
          state.listingsLoading = false;
          state.listings = payload.data.listings;
          state.totalResults = payload.data.totalResults;
          state.totalPages = payload.data.totalPages;
        }
      })
      .addCase(getListingsApi.rejected, (state) => {
        state.listingsLoading = true;
      })
      // getListingsXlsxApi
      .addCase(getListingsXlsxApi.pending, (state) => {
        state.listingsXlsxLoading = true;
      })
      .addCase(getListingsXlsxApi.fulfilled, (state) => {
        state.listingsXlsxLoading = false;
      })
      .addCase(getListingsXlsxApi.rejected, (state) => {
        state.listingsXlsxLoading = false;
      })
      // getListingApi
      .addCase(getListingApi.fulfilled, (state, { payload }) => {
        state.listingInfo = payload.data.listingItem;
      })
      // updateListingApi
      .addCase(updateListingApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateListingApi.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateListingApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // updateListingsFromXlsxApi
      .addCase(updateListingsFromXlsxApi.pending, (state) => {
        state.updateFromXlsxStatus = "pending";
      })
      .addCase(updateListingsFromXlsxApi.fulfilled, (state, { payload }) => {
        state.updateFromXlsxStatus = "fulfilled";
      })
      .addCase(updateListingsFromXlsxApi.rejected, (state) => {
        state.updateFromXlsxStatus = "rejected";
      })
      // syncListingsApi
      .addCase(syncListingsApi.pending, (state) => {
        state.syncStatus = "pending";
      })
      .addCase(syncListingsApi.fulfilled, (state, { payload }) => {
        state.syncStatus = "fulfilled";
      })
      .addCase(syncListingsApi.rejected, (state) => {
        state.syncStatus = "rejected";
      })
      // deleteListingApi
      .addCase(deleteListingApi.pending, (state) => {
        state.deleteStatus = "pending";
      })
      .addCase(deleteListingApi.fulfilled, (state, { payload }) => {
        state.deleteStatus = "fulfilled";
      })
      .addCase(deleteListingApi.rejected, (state) => {
        state.deleteStatus = "rejected";
      })
      // getCreationListingsApi
      .addCase(getCreationListingsApi.pending, (state) => {
        state.creationListingsLoadingStatus = "pending";
      })
      .addCase(getCreationListingsApi.fulfilled, (state, { payload }) => {
        state.creationListingsLoadingStatus = "fulfilled";

        state.creationListings = payload.data.creationListings;
        state.creationListingsTotalResults = payload.data.totalCreationListingsResults;
      })
      .addCase(getCreationListingsApi.rejected, (state) => {
        state.creationListingsLoadingStatus = "rejected";
      })
      // deleteCreationListingsApi
      .addCase(deleteCreationListingsApi.pending, (state) => {
        state.creationListingsDeleteStatus = "pending";
      })
      .addCase(deleteCreationListingsApi.fulfilled, (state) => {
        state.creationListingsDeleteStatus = "fulfilled";
      })
      .addCase(deleteCreationListingsApi.rejected, (state) => {
        state.creationListingsDeleteStatus = "rejected";
      })
      // getItemTitleFormatsApi
      .addCase(getItemTitleFormatsApi.pending, (state) => {
        state.getItemTitleFormatStatus = "pending";
      })
      .addCase(getItemTitleFormatsApi.fulfilled, (state, { payload }) => {
        state.titleFormats = payload.data.titleFormats;
        state.getItemTitleFormatStatus = "fulfilled";
      })
      .addCase(getItemTitleFormatsApi.rejected, (state) => {
        state.getItemTitleFormatStatus = "rejected";
      })
      // addItemTitleFormatsApi
      .addCase(addItemTitleFormatsApi.pending, (state) => {
        state.addItemTitleFormatStatus = "pending";
      })
      .addCase(addItemTitleFormatsApi.fulfilled, (state) => {
        state.addItemTitleFormatStatus = "fulfilled";
      })
      .addCase(addItemTitleFormatsApi.rejected, (state) => {
        state.addItemTitleFormatStatus = "rejected";
      })
      // deleteItemTitleFormatsApi
      .addCase(deleteItemTitleFormatsApi.pending, (state) => {
        state.deleteItemTitleFormatStatus = "pending";
      })
      .addCase(deleteItemTitleFormatsApi.fulfilled, (state) => {
        state.deleteItemTitleFormatStatus = "fulfilled";
      })
      .addCase(deleteItemTitleFormatsApi.rejected, (state) => {
        state.deleteItemTitleFormatStatus = "rejected";
      })
      // listingUploadCreationApi
      .addCase(listingUploadCreationApi.pending, (state) => {
        state.listingUploadCreationStatus = "pending";
      })
      .addCase(listingUploadCreationApi.fulfilled, (state) => {
        state.listingUploadCreationStatus = "fulfilled";
      })
      .addCase(listingUploadCreationApi.rejected, (state) => {
        state.listingUploadCreationStatus = "rejected";
      })
      // replaceCreationUploadApi
      .addCase(replaceCreationUploadApi.pending, (state) => {
        state.replaceUploadCreationStatus = "pending";
      })
      .addCase(replaceCreationUploadApi.fulfilled, (state) => {
        state.replaceUploadCreationStatus = "fulfilled";
      })
      .addCase(replaceCreationUploadApi.rejected, (state) => {
        state.replaceUploadCreationStatus = "rejected";
      })
      // getCreationUploadsApi
      .addCase(getCreationUploadsApi.pending, (state) => {
        state.getUploadCreationStatus = "pending";
      })
      .addCase(getCreationUploadsApi.fulfilled, (state, { payload }) => {
        state.getUploadCreationStatus = "fulfilled";
        state.creationUploads = payload.data.uploads;
        state.itemSpecificsLastUploadDate = payload.data.itemSpecificsLastUploadDate;
      })
      .addCase(getCreationUploadsApi.rejected, (state) => {
        state.getUploadCreationStatus = "rejected";
      })
      // deleteCreationUploadApi
      .addCase(deleteCreationUploadApi.pending, (state) => {
        state.deleteUploadCreationStatus = "pending";
      })
      .addCase(deleteCreationUploadApi.fulfilled, (state) => {
        state.deleteUploadCreationStatus = "fulfilled";
      })
      .addCase(deleteCreationUploadApi.rejected, (state) => {
        state.deleteUploadCreationStatus = "rejected";
      })
      // itemSpecificsUploadApi
      .addCase(itemSpecificsUploadApi.pending, (state) => {
        state.itemSpecificsUploadStatus = "pending";
      })
      .addCase(itemSpecificsUploadApi.fulfilled, (state) => {
        state.itemSpecificsUploadStatus = "fulfilled";
      })
      .addCase(itemSpecificsUploadApi.rejected, (state) => {
        state.itemSpecificsUploadStatus = "rejected";
      })
      // getItemPartNamesApi
      .addCase(getItemPartNamesApi.fulfilled, (state, { payload }) => {
        state.itemPartNames = payload.data.partNames;
      })
      // getItemPartNamesFileApi
      .addCase(getItemPartNamesFileApi.pending, (state) => {
        state.getItemPartNamesFileStatus = "pending";
      })
      .addCase(getItemPartNamesFileApi.fulfilled, (state) => {
        state.getItemPartNamesFileStatus = "fulfilled";
      })
      .addCase(getItemPartNamesFileApi.rejected, (state) => {
        state.getItemPartNamesFileStatus = "rejected";
      })
      // getItemPartNamesToCategoryMappingFileApi
      .addCase(getItemPartNamesToCategoryMappingFileApi.pending, (state) => {
        state.getItemPartNamesToCategoryMappingFileStatus = "pending";
      })
      .addCase(getItemPartNamesToCategoryMappingFileApi.fulfilled, (state) => {
        state.getItemPartNamesToCategoryMappingFileStatus = "fulfilled";
      })
      .addCase(getItemPartNamesToCategoryMappingFileApi.rejected, (state) => {
        state.getItemPartNamesToCategoryMappingFileStatus = "rejected";
      })
      // getCategoryToPartNameMappingApi
      .addCase(getCategoryToPartNameMappingApi.fulfilled, (state, { payload }) => {
        state.categoryToPartNameMappings = payload.data.mapping;
      })
      // updateCategoryToPartNameMappingApi
      .addCase(updateCategoryToPartNameMappingApi.pending, (state) => {
        state.updateCategoryToPartNameMappingStatus = "pending";
      })
      .addCase(updateCategoryToPartNameMappingApi.fulfilled, (state) => {
        state.updateCategoryToPartNameMappingStatus = "fulfilled";
      })
      .addCase(updateCategoryToPartNameMappingApi.rejected, (state) => {
        state.updateCategoryToPartNameMappingStatus = "rejected";
      })
      // deleteCategoryToPartNameMappingApi
      .addCase(deleteCategoryToPartNameMappingApi.pending, (state) => {
        state.deleteCategoryToPartNameMappingStatus = "pending";
      })
      .addCase(deleteCategoryToPartNameMappingApi.fulfilled, (state) => {
        state.deleteCategoryToPartNameMappingStatus = "fulfilled";
      })
      .addCase(deleteCategoryToPartNameMappingApi.rejected, (state) => {
        state.deleteCategoryToPartNameMappingStatus = "rejected";
      })
      // getSkuMappingInfoApi
      .addCase(getSkuMappingInfoApi.fulfilled, (state, { payload }) => {
        state.skuMappingLastUploadDate = payload.data ? payload.data?.skuMappingLastUpload : "";
      })
      // uploadSkuMappingApi
      .addCase(uploadSkuMappingApi.pending, (state) => {
        state.skuMappingUploadStatus = "pending";
      })
      .addCase(uploadSkuMappingApi.fulfilled, (state) => {
        state.skuMappingUploadStatus = "fulfilled";
      })
      .addCase(uploadSkuMappingApi.rejected, (state) => {
        state.skuMappingUploadStatus = "rejected";
      })
      // getInventoryCreationInfoApi
      .addCase(getInventoryCreationInfoApi.fulfilled, (state, { payload }) => {
        state.inventoryCreationLastUploadDate = payload.data ? payload.data?.lastUploadDate : "";
      })
      // downloadInventoryCreationItemsApi
      .addCase(downloadInventoryCreationItemsApi.pending, (state) => {
        state.downloadInventoryCreationItemsStatus = "pending";
      })
      .addCase(downloadInventoryCreationItemsApi.fulfilled, (state) => {
        state.downloadInventoryCreationItemsStatus = "fulfilled";
      })
      .addCase(downloadInventoryCreationItemsApi.rejected, (state) => {
        state.downloadInventoryCreationItemsStatus = "rejected";
      })
      // externallyAssignedProductIdentifiersDownloadApi
      .addCase(externallyAssignedProductIdentifiersDownloadApi.pending, (state) => {
        state.downloadIdentifiersItemsStatus = "pending";
      })
      .addCase(externallyAssignedProductIdentifiersDownloadApi.fulfilled, (state) => {
        state.downloadIdentifiersItemsStatus = "fulfilled";
      })
      .addCase(externallyAssignedProductIdentifiersDownloadApi.rejected, (state) => {
        state.downloadIdentifiersItemsStatus = "rejected";
      })
  },
});

export const { updateFilter, updateRangeFilter, clearFilters } = listingsSlice.actions;
export default listingsSlice.reducer;