import { createSlice } from "@reduxjs/toolkit";
import { getShipStationStoreApi } from "./ordersApi";

const initialState = {
  shipStationStores: [],
};

const ordersSlice = createSlice({
  name: "ordersSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getDevConfig
      .addCase(getShipStationStoreApi.fulfilled, (state, { payload }) => {
        state.shipStationStores = payload.data ? payload.data : {};
      });
  },
});

export default ordersSlice.reducer;