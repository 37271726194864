import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_PREFIX,
});

export const getConfigWithBearerToken = () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "ngrok-skip-browser-warning": true
    },
  };
  return config;
};

const notify = (type, text) => {
  toast[type](<>{text}</>, {
    theme: "dark"
  });
};

export const notifyMsg = (response) => {
  if (response?.success) {
    notify("success", response?.message);
  } else {
    notify("error", response?.message || "Something went wrong");
  }
}

export default api;