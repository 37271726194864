import React, { useState, useEffect } from "react";

import socket from "app/socket";

import {
  Box,
  TextField,
  LinearProgress,
  Typography,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";
import {
  getShipStationStoreApi,
} from "features/orders/ordersApi";

const ShipStation = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    shipStationStores
  } = useSelector((state) => state.orders);

  const [stores, setStores] = useState([]);
  const [progress, setProgress] = useState(0);

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getShipStationStoreApi());
  }, []);

  useEffect(() => {
    socket.on("ordersProgress", (data) => {
      setProgress(data.message);
    });

    return () => {
      socket.off("ordersProgress");
    };
  }, []);

  useEffect(() => {
    if (shipStationStores) {
      setStores(shipStationStores);
    }
  }, [shipStationStores]);

  const handleUpdateOrders = () => {
    socket.emit('startOrdersUpdate');
    setSaveDialogOpen(false);
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title="Orders" subtitle="Manage Your Orders" />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Orders Update Job" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box mb="40px" >
              <Typography fontWeight="bold" variant="h5" p="3px" mb="10px">Job Control</Typography>
              {progress ?
                <Box
                  sx={{
                    width: "45%",
                    height: "140%",
                    position: "relative",
                  }}
                >
                  <LinearProgress
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                    color="secondary"
                    variant="determinate"
                    value={progress}
                  />
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "black",
                    }}
                  >
                    {`${progress}%`}
                  </Typography>
                </Box>
                : <LoadingButton
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: "45%",
                    alignSelf: "flex-end",
                    padding: "15px",
                    marginBottom: "-50px",
                    marginTop: "-20px"
                  }}
                  onClick={() => setSaveDialogOpen(true)}
                >
                  Start Updater
                </LoadingButton>
              }
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Ship Station Stores" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            {
              stores &&
              stores.map(store => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px"
                  }}
                >
                  <TextField
                    label="Store Name"
                    placeholder="Store Name..."
                    value={store?.storeName || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: "45%"
                    }}
                    color="secondary"
                  />
                  <TextField
                    label="Store Id"
                    placeholder="Store Id..."
                    value={store?.storeId || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: "25%"
                    }}
                    color="secondary"
                  />
                  <TextField
                    label="Store Marketplace"
                    placeholder="Store Marketplace..."
                    value={store?.marketplaceName || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: "25%"
                    }}
                    color="secondary"
                  />
                </Box>
              ))
            }
          </Box>
        </Box>
        <DialogBox
          open={saveDialogOpen}
          onClose={() => setSaveDialogOpen(false)}
          onConfirm={handleUpdateOrders}
          titleText="Start Confirmation"
          messageText="Are you sure you want to start the updater?"
          confirmText="Start"
        />
      </Box>
    </Box>
  );
}

export default ShipStation;
