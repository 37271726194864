import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  useTheme,
  IconButton,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  Switch,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import {
  DragIndicatorOutlined,
  PercentOutlined,
  AttachMoneyOutlined,
  NumbersOutlined
} from "@mui/icons-material";

import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";

import { useDispatch, useSelector } from "react-redux";
import { getSourcingSetupApi, updateSourcingSetupApi } from "features/stores/storesApi";

const SourcingSetup = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { storeSourcingSetup, updateStoreSourcingSetupStatus } = useSelector((state) => state.stores);

  const { storeId } = useParams();

  const [supplierSourcingPriority, setSupplierSourcingPriority] = useState([]);
  const [supplierTolerance, setSupplierTolerance] = useState(null);

  const [supplier, setSupplier] = useState("");
  const [suppliers, setSuppliers] = useState([]);

  const [quantitySetup, setQuantitySetup] = useState([]);

  const [quantityOffset, setQuantityOffset] = useState({
    value: 0,
    percentage: false
  });
  const [defaultMaxQuantityLimitEnabled, setDefaultMaxQuantityLimitEnabled] = useState(false);
  const [defaultMaxQuantityLimit, setDefaultMaxAllowedQuantity] = useState(0);

  const [quantityThresholdAdjustments, setQuantityThresholdAdjustments] = useState([]);
  const [quantitiesByPriceRange, setQuantitiesByPriceRange] = useState([]);

  const [selectedPasteFromSupplier, setSelectedPasteFromSupplier] = useState("");

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [pasteQuantitySetupDialogOpen, setPasteQuantitySetupDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getSourcingSetupApi(storeId));
  }, []);

  useEffect(() => {
    if (storeSourcingSetup.supplierSourcingPriority) {
      const mappedSupplierSourcingPriority =
        storeSourcingSetup.supplierSourcingPriority.map((item, index) => ({
          i: `${index + 1}`,
          x: index * 2,
          y: item.priority,
          w: 2,
          h: 1,
          ...item,
        }));

      setSupplierSourcingPriority(mappedSupplierSourcingPriority);
      setSupplierTolerance(storeSourcingSetup.supplierTolerance);

      setSuppliers(storeSourcingSetup.supplierSourcingPriority.map(({ supplier, supplierId }, index) => {
        if (!index) setSupplier(supplierId);
        return { option: supplier, value: supplierId }
      }));

      setQuantitySetup(storeSourcingSetup.quantitySetup);
    }
  }, [storeSourcingSetup]);

  useEffect(() => {
    if (quantitySetup && supplier) {
      const filteredQuantitySetup = quantitySetup.filter(item => item.supplierId === supplier);

      if (filteredQuantitySetup.length > 0) {
        const {
          quantityOffset,
          defaultMaxQuantityLimitEnabled,
          defaultMaxQuantityLimit,
          quantityThresholdAdjustments,
          quantitiesByPriceRange
        } = filteredQuantitySetup[0];

        setQuantityOffset(quantityOffset);
        setDefaultMaxQuantityLimitEnabled(defaultMaxQuantityLimitEnabled);
        setDefaultMaxAllowedQuantity(defaultMaxQuantityLimit);
        setQuantityThresholdAdjustments(quantityThresholdAdjustments);
        setQuantitiesByPriceRange(quantitiesByPriceRange);
      }
    }
  }, [quantitySetup, supplier]);

  const handleSupplierSourcingPriorityChange = (newLayout) => {
    const updatedSupplierSourcingPriority = newLayout.map((item) => {
      const { supplier, supplierId } = supplierSourcingPriority.find(
        (x) => x.i === item.i
      );
      return {
        ...item,
        supplier,
        supplierId,
        priority: item.y + 1,
      };
    });

    setSupplierSourcingPriority(updatedSupplierSourcingPriority);
  };

  const handleOffsetToleranceChange = (value, offsetType, supplierId) => {
    const updatedOffsetTolerance = [...supplierTolerance];

    const supplierIndex = updatedOffsetTolerance.findIndex(
      (item) => item.supplierId === supplierId
    );

    if (supplierIndex !== -1) {
      const updatedSupplierOffsets = { ...updatedOffsetTolerance[supplierIndex] };

      updatedSupplierOffsets[offsetType] = {
        ...updatedSupplierOffsets[offsetType],
        value,
      };

      updatedOffsetTolerance[supplierIndex] = updatedSupplierOffsets;

      setSupplierTolerance(updatedOffsetTolerance);
    }
  };

  const handleStockAvailabilityFallbackChange = (supplierId) => {
    const updatedSupplierTolerance = [...supplierTolerance];

    const supplierIndex = updatedSupplierTolerance.findIndex(
      (item) => item.supplierId === supplierId
    );

    if (supplierIndex !== -1) {
      const updatedSupplierStockAvailabilityFallback = { ...updatedSupplierTolerance[supplierIndex] };

      updatedSupplierStockAvailabilityFallback.stockAvailabilityFallback = !updatedSupplierStockAvailabilityFallback.stockAvailabilityFallback;

      updatedSupplierTolerance[supplierIndex] = updatedSupplierStockAvailabilityFallback;
      setSupplierTolerance(updatedSupplierTolerance);
    }
  };

  const handlePercentageToggle = (supplierId, offsetType) => {
    const updatedSupplierTolerance = [...supplierTolerance];

    const supplierIndex = updatedSupplierTolerance.findIndex(
      (item) => item.supplierId === supplierId
    );

    if (supplierIndex !== -1) {
      const updatedSupplierOffsets = { ...updatedSupplierTolerance[supplierIndex] };

      updatedSupplierOffsets[offsetType] = {
        ...updatedSupplierOffsets[offsetType],
        percentage: !updatedSupplierOffsets[offsetType].percentage,
      };

      updatedSupplierTolerance[supplierIndex] = updatedSupplierOffsets;

      setSupplierTolerance(updatedSupplierTolerance);
    }
  };

  const handleSave = () => {
    dispatch(updateSourcingSetupApi({
      storeId,
      sourcingSetup: {
        supplierSourcingPriority: supplierSourcingPriority.map(({ supplier, ...rest }) => ({
          supplierId: rest.supplierId,
          priority: rest.priority
        })),
        supplierTolerance: supplierTolerance.map(({ supplier, ...rest }) => rest),
        quantitySetup: quantitySetup.map(({ supplier, ...rest }) => rest),
      }
    }));

    setSaveDialogOpen(false);
  };

  // quantityOffset
  const handleQuantityOffsetChange = (type, event) => {
    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      if (type === "value") {
        return [
          ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
          { ...currentSupplierSetUp, quantityOffset: { ...quantityOffset, value: event.target.value } }
        ];
      } else {
        return [
          ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
          { ...currentSupplierSetUp, quantityOffset: { ...quantityOffset, percentage: !quantityOffset.percentage } }
        ];
      }
    });
  };

  // defaultMaxQuantityLimitEnabled
  const defaultMaxQuantityLimitEnabledChange = () => {
    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        { ...currentSupplierSetUp, defaultMaxQuantityLimitEnabled: !defaultMaxQuantityLimitEnabled }
      ];
    });
  };

  // defaultMaxAllowedQuantity
  const defaultMaxQuantityLimitChange = (event) => {
    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        { ...currentSupplierSetUp, defaultMaxQuantityLimit: parseInt(event.target.value) }
      ];
    });
  };

  // quantitiesByPriceRange
  const handlePriceRangeChange = (index, field, value) => {
    const updatedRanges = quantitiesByPriceRange.map((range, i) =>
      i === index ? { ...range, [field]: parseFloat(value) } : range
    );

    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        { ...currentSupplierSetUp, quantitiesByPriceRange: updatedRanges }
      ];
    });
  };

  const handleAddPriceRange = () => {
    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        {
          ...currentSupplierSetUp,
          quantitiesByPriceRange: [
            ...currentSupplierSetUp.quantitiesByPriceRange,
            { minPrice: 0, maxPrice: 0, allowedQuantity: 0 }
          ]
        }
      ];
    });
  };

  const handleRemovePriceRange = (index) => {
    const updatedRanges = quantitiesByPriceRange.filter((_, i) => i !== index);

    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        { ...currentSupplierSetUp, quantitiesByPriceRange: updatedRanges }
      ];
    });
  };

  // quantityThresholdAdjustments
  const handleQuantityThresholdAdjustmentChange = (index, field, value) => {
    const updatedAdjustments = quantityThresholdAdjustments.map((adjustment, i) =>
      i === index
        ? {
          ...adjustment,
          [field]:
            field === "thresholdValue" || field === "adjustedValue"
              ? parseFloat(value)
              : value,
        }
        : adjustment
    );

    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        { ...currentSupplierSetUp, quantityThresholdAdjustments: updatedAdjustments }
      ];
    });
  };

  const handleAddQuantityThresholdAdjustment = () => {
    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        {
          ...currentSupplierSetUp,
          quantityThresholdAdjustments: [
            ...currentSupplierSetUp.quantityThresholdAdjustments,
            { thresholdValue: 0, comparisonOperator: "=", adjustedValue: 0 }
          ]
        }
      ];
    });
  };

  const handleRemoveQuantityThresholdAdjustment = (index) => {
    const updatedAdjustments = quantityThresholdAdjustments.filter((_, i) => i !== index);
    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        { ...currentSupplierSetUp, quantityThresholdAdjustments: updatedAdjustments }
      ];
    });
  };

  const toggleComparisonOperator = (index) => {
    const updatedAdjustments = [...quantityThresholdAdjustments];
    const operators = ['>', '<', '='];
    const currentIndex = operators.indexOf(updatedAdjustments[index].comparisonOperator);

    // Calculate the next operator in the cycle
    const nextIndex = (currentIndex + 1) % operators.length;
    updatedAdjustments[index].comparisonOperator = operators[nextIndex];

    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        { ...currentSupplierSetUp, quantityThresholdAdjustments: updatedAdjustments }
      ];
    });
  };

  // PasteFrom
  const handlePasteFromClick = () => {
    setSelectedPasteFromSupplier(suppliers.filter(({ value }) => value !== supplier)[0].value);
    setPasteQuantitySetupDialogOpen(true);
  };

  const handlePasteFrom = () => {
    setQuantitySetup(prevQuantitySetup => {
      const currentSupplierSetUp = prevQuantitySetup.filter(item => item.supplierId === supplier)[0];
      const selectedSupplierToPasteFrom = prevQuantitySetup.filter(item => item.supplierId === selectedPasteFromSupplier)[0];

      return [
        ...prevQuantitySetup.filter(item => item.supplierId !== supplier),
        { ...selectedSupplierToPasteFrom, supplierId: currentSupplierSetUp.supplierId }
      ];
    });

    setPasteQuantitySetupDialogOpen(false);
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title={storeSourcingSetup.storeName ? `${storeSourcingSetup.storeName}, Sourcing Setup` : "Store Sourcing Setup"} subtitle="Edit And Manage Your Store Sourcing Setup" />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Supplier Priority Sourcing" />
          <div
            className="layout"
            style={{
              width: "60%",
              borderRadius: "10px",
              backgroundColor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem",
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="15px">
              Supplier Priority Sourcing
            </Typography>
            <Typography variant="body2" p="3px" mb="20px">
              This feature allows you to establish a preferred order for suppliers
              so that when the inventory data closely matches for an item, it will
              be sourced from the supplier with the highest priority.
            </Typography>
            <GridLayout
              className="layout"
              layout={supplierSourcingPriority}
              cols={1}
              rowHeight={70}
              width={400}
              isResizable={false}
              isDroppable={true}
              verticalCompact={true}
              onLayoutChange={handleSupplierSourcingPriorityChange}
            >
              {supplierSourcingPriority &&
                supplierSourcingPriority.map((item) => (
                  <div key={item.i}>
                    <Paper
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "5px",
                        border: "1px solid #585672",
                        padding: "8px",
                        background: "transparent",
                      }}
                    >
                      <IconButton
                        aria-label="Drag Item"
                        style={{ marginRight: "8px", cursor: "grab" }}
                      >
                        <DragIndicatorOutlined />
                      </IconButton>
                      {item.priority}. {item.supplier}
                    </Paper>
                  </div>
                ))}
            </GridLayout>
          </div>
        </Box>
        {suppliers.length > 1 &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SubHeader title="Supplier Tolerance" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
                borderRadius: "10px",
                bgcolor: theme.palette.background.alt,
                padding: "2rem",
                gap: "1.5rem",
              }}
            >
              <Box>
                <Typography fontWeight="bold" variant="h5" p="3px" mb="15px">
                  Supplier Tolerance
                </Typography>
                <Typography variant="body2" p="3px" mb="20px">
                  Price offset tolerance is a feature that lets you define acceptable
                  price variations from your suppliers. Specify the maximum allowed
                  differences in fixed currency units or percentages. This feature
                  ensures that sourcing adjustments are only triggered when these
                  limits are exceeded. The offsets work one at a time based on the
                  supplier's priority.
                  <br />
                  <br />
                  Stock availability fallback is a feature that allows the system to
                  fallback to another supplier if the prioritised supplier does not
                  have stock. It will fallback even if the Price offset tolerance is
                  not exceeded.
                </Typography>
              </Box>
              {supplierTolerance &&
                supplierTolerance.map((item, index) => (
                  <Box
                    key={item.supplierId}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "2rem"
                    }}
                  >
                    <Box
                      sx={{
                        width: "full",
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Supplier"
                        defaultValue={item.supplier}
                        InputProps={{
                          readOnly: true,
                        }}
                        color="secondary"
                        sx={{
                          width: "45%",
                        }}
                      />
                      <Box
                        sx={{
                          width: "50%",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <FormControl
                          component="fieldset"
                          sx={{
                            height: "full"
                          }}
                        >
                          <FormControlLabel
                            control={<Switch
                              color="secondary"
                              checked={item.stockAvailabilityFallback}
                              onChange={() => handleStockAvailabilityFallbackChange(item.supplierId)}
                            />}
                            label="Stock Availability Fallback"
                            labelPlacement="end"
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "full",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Price Offset Tolerance"
                        value={item.priceOffsetTolerance.value}
                        onChange={(e) =>
                          handleOffsetToleranceChange(
                            e.target.value,
                            "priceOffsetTolerance",
                            item.supplierId
                          )
                        }
                        color="secondary"
                        type="number"
                        inputProps={{ min: 0 }}
                        sx={{
                          width: "45%",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle percentage"
                                onClick={() =>
                                  handlePercentageToggle(
                                    item.supplierId,
                                    "priceOffsetTolerance"
                                  )
                                }
                                edge="end"
                              >
                                {item.priceOffsetTolerance.percentage ? (
                                  <PercentOutlined />
                                ) : (
                                  <AttachMoneyOutlined />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    {typeof index === 'number' && index !== (supplierTolerance.length - 1) && <Divider middle />}
                  </Box>
                ))}
            </Box>
          </Box>
        }
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              gap: "1.5rem"
            }}
          >
            <SubHeader title="Quantity Adjustments" />
            <FormControl sx={{ width: "50%" }}>
              <InputLabel
                id="select-supplier-range-label"
                color="secondary"
              >Supplier</InputLabel>
              <Select
                labelId="select-supplier-range-label"
                value={supplier}
                label="Supplier"
                color="secondary"
                onChange={(e) => setSupplier(e.target.value)}
              >
                {suppliers.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "1.5rem",
            }}
          >
            <Box>
              <Typography fontWeight="bold" variant="h5" p="3px" mb="15px">Quantity Offset</Typography>
              <Typography variant="body2" p="3px" mb="20px">Adjust inventory listing quantity by subtracting a numerical value, e.g., 2 adjusts a 10-item inventory to list with a quantity of 8</Typography>
              <TextField
                variant="outlined"
                label="Quantity Offset"
                value={quantityOffset.value}
                onChange={(event) => handleQuantityOffsetChange("value", event)}
                color="secondary"
                type="number"
                InputProps={{
                  min: 0,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle percentage"
                        onClick={handleQuantityOffsetChange}
                        edge="end"
                      >
                        {quantityOffset.percentage ? (
                          <PercentOutlined />
                        ) : (
                          <NumbersOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  width: "50%"
                }}
              />
            </Box>
            <Divider middle />
            <Box>
              <FormControl
                component="fieldset"
                sx={{
                  padding: "0 15px"
                }}
              >
                <FormControlLabel
                  control={<Switch
                    color="secondary"
                    checked={defaultMaxQuantityLimitEnabled}
                    onChange={() => defaultMaxQuantityLimitEnabledChange()}
                  />}
                  label="Default Max Quantity Limit"
                  labelPlacement="left"
                />
              </FormControl>
            </Box>
            {(defaultMaxQuantityLimit !== 0 && defaultMaxQuantityLimitEnabled) &&
              <Box>
                <TextField
                  variant="outlined"
                  label="Default Max Allowed Quantity"
                  value={defaultMaxQuantityLimit}
                  onChange={defaultMaxQuantityLimitChange}
                  color="secondary"
                  type="number"
                  inputProps={{ min: 0 }}
                  sx={{
                    width: "50%"
                  }}
                />
              </Box>
            }
            <Divider middle />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Typography fontWeight="bold" variant="h5" p="3px" mb="-15px">Price-Range Dependent Quantities</Typography>
              <Typography variant="body2" p="3px" mb="-10px">Define allowed quantities based on price ranges; each object specifies min and max price, along with the corresponding allowed quantity for listings</Typography>
              {quantitiesByPriceRange.map((range, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Min. Price"
                      value={range.minPrice}
                      onChange={(e) =>
                        handlePriceRangeChange(index, "minPrice", e.target.value)
                      }
                      color="secondary"
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                    <TextField
                      variant="outlined"
                      label="Max. Price"
                      value={range.maxPrice}
                      onChange={(e) =>
                        handlePriceRangeChange(index, "maxPrice", e.target.value)
                      }
                      color="secondary"
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                    <TextField
                      variant="outlined"
                      label="Allowed Quantity"
                      value={range.allowedQuantity}
                      onChange={(e) =>
                        handlePriceRangeChange(
                          index,
                          "allowedQuantity",
                          e.target.value
                        )
                      }
                      color="secondary"
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        padding: "10px",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        transition: "background-color 0.3s",
                        color: theme.palette.secondary.main,
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.main,
                          color: "#000"
                        }
                      }}
                      onClick={() => handleRemovePriceRange(index)}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>
              ))}
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: "30%",
                  padding: "10px",
                }}
                onClick={handleAddPriceRange}
              >
                Add Price Range
              </Button>
            </Box>
            <Divider middle />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Typography fontWeight="bold" variant="h5" p="3px" mb="-15px">Quantity Threshold Adjustment</Typography>
              <Typography variant="body2" p="3px" mb="-10px">{"Set of adjustments to listing quantity based on thresholds; each adjustment has a threshold value, comparison operator (>, < or =), and adjusted value"}</Typography>
              {quantityThresholdAdjustments.map((adjustment, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Threshold Value"
                      value={adjustment.thresholdValue}
                      onChange={(e) =>
                        handleQuantityThresholdAdjustmentChange(
                          index,
                          "thresholdValue",
                          e.target.value
                        )
                      }
                      color="secondary"
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                    <Box
                      sx={{
                        width: "40px",
                        textAlign: "center",
                        cursor: "pointer",
                        color: theme.palette.secondary.main,
                        borderRadius: "4px",
                        fontSize: "20px"
                      }}
                      onClick={() => toggleComparisonOperator(index)}
                    >
                      {adjustment.comparisonOperator}
                    </Box>
                    <TextField
                      variant="outlined"
                      label="Adjusted Value"
                      value={adjustment.adjustedValue}
                      onChange={(e) =>
                        handleQuantityThresholdAdjustmentChange(
                          index,
                          "adjustedValue",
                          e.target.value
                        )
                      }
                      color="secondary"
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        padding: "10px",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        transition: "background-color 0.3s",
                        color: theme.palette.secondary.main,
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.main,
                          color: "#000"
                        }
                      }}
                      onClick={() => handleRemoveQuantityThresholdAdjustment(index)}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>
              ))}
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: "30%",
                  padding: "10px",
                }}
                onClick={handleAddQuantityThresholdAdjustment}
              >
                Add Adjustment
              </Button>
            </Box>
            {suppliers.length >= 2 &&
              <>
                <Divider middle />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      width: "30%",
                      padding: "10px",
                    }}
                    onClick={handlePasteFromClick}
                  >
                    Paste From
                  </Button>
                </Box>
              </>
            }
          </Box>
        </Box>
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px",
            alignSelf: "flex-end"
          }}
          onClick={() => setSaveDialogOpen(true)}
          loading={updateStoreSourcingSetupStatus === "pending"}
        >
          Save
        </LoadingButton>
      </Box>
      <DialogBox
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onConfirm={handleSave}
        titleText="Save Confirmation"
        messageText="Are you sure you want to save this change?"
        confirmText="Save"
      />
      <Dialog
        open={pasteQuantitySetupDialogOpen}
        onClose={() => setPasteQuantitySetupDialogOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">Paste Quantity Adjustments</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              gap: "2rem",
              alignItems: "center"
            }}
          >
            <DialogContentText id="dialog-description">
              Select a supplier and paste the quantity setup specific to that choice.
            </DialogContentText>
            <FormControl sx={{ width: "70%" }}>
              <InputLabel
                id="select-supplier-range-label"
                color="secondary"
              >Supplier</InputLabel>
              <Select
                labelId="select-supplier-range-label"
                value={selectedPasteFromSupplier}
                label="Supplier"
                color="secondary"
                onChange={(e) => setSelectedPasteFromSupplier(e.target.value)}
              >
                {suppliers
                  .filter((option) => option.value !== supplier)
                  .map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.option}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasteQuantitySetupDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <div style={{ flex: 1 }}></div>
          <Button onClick={handlePasteFrom} color="secondary">
            Paste
          </Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
};

export default SourcingSetup;