import React from "react";

import { Box, useTheme } from "@mui/material";

const ProfileImage = ({ profileInfo, handlePfpChange, isPfpHovered, setIsPfpHovered }) => {
  const theme = useTheme();

  return (
    <Box
      position="relative"
      height="150px"
      width="150px"
      borderRadius="50%"
      onMouseEnter={() => setIsPfpHovered(true)}
      onMouseLeave={() => setIsPfpHovered(false)}
    >
      <Box
        component="img"
        alt="profile"
        src={
          profileInfo.profileImage ?
            `${process.env.REACT_APP_BASE_PREFIX}/${profileInfo.profileImage}` :
            (theme.palette.mode === "dark" ?
              process.env.REACT_APP_DEFAULT_PFP_LIGHT :
              process.env.REACT_APP_DEFAULT_PFP_DARK)
        }
        height="150px"
        width="150px"
        borderRadius="50%"
        sx={{
          objectFit: "cover",
          filter: isPfpHovered ? "brightness(60%)" : "none",
        }}
      />
      {isPfpHovered && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
        >
          <label htmlFor="fileInput"
            style={{
              color: theme.palette.secondary[500],
              cursor: "pointer",
              textDecoration: "underline"
            }}>
            Change Image
          </label>
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => handlePfpChange(e.target.files[0])}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProfileImage;
