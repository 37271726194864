import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  useTheme,
  Typography,
  FormControl,
  FormControlLabel,
  Switch,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import {
  KeyboardBackspaceOutlined,
  PercentOutlined
} from "@mui/icons-material";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";
import CustomTextField from "components/CustomTextField";

import { useDispatch, useSelector } from "react-redux";
import {
  getListingApi,
  updateListingApi,
  deleteListingApi
} from "features/listings/listingsApi";

const ListingEdit = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    listingInfo,
    updateStatus,
    deleteStatus
  } = useSelector((state) => state.listings);

  const { listingId } = useParams();

  const [ebayTitle, setEbayTitle] = useState("");
  const [startPrice, setStartPrice] = useState("");
  const [startPriceFixed, setStartPriceFixed] = useState(false);
  const [quantity, setQuantity] = useState("");

  const [freeShipping, setFreeShipping] = useState("");

  const [supplierPrices, setSupplierPrices] = useState({});
  const [inventoryPrices, setInventoryPrices] = useState({});
  const [shippingCost, setShippingCost] = useState(0);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getListingApi(listingId));
  }, [])

  useEffect(() => {
    if (listingInfo) {
      setEbayTitle(listingInfo?.title || "");
      setStartPrice(listingInfo?.startPrice?.value || "");
      setStartPriceFixed(listingInfo?.startPrice?.immutability || false);
      setQuantity(typeof listingInfo?.quantity === "number" ? listingInfo?.quantity : 0);

      // setFreeShipping(listingInfo?.shippingDetails?.freeShipping || false);

      setShippingCost(listingInfo?.inventoryInfo?.shippingCost || 0);
      setInventoryPrices(listingInfo?.inventoryInfo?.prices || {});
    }
  }, [listingInfo]);

  useEffect(() => {
    if (listingInfo?.suppliersPrices?.length) {
      setSupplierPrices(listingInfo.suppliersPrices[0]);
    }
  }, [listingInfo.suppliersPrices]);

  const handleSave = (updateType) => {
    dispatch(updateListingApi({
      listingId,
      updatedListing: {
        ebayTitle,
        startPrice,
        startPriceFixed,
        quantity,
        inventoryPrices,
        shippingCost,
        updateType
      }
    }));

    dispatch(getListingApi(listingId));
    setSaveDialogOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteListingApi([listingId]));
    navigate(-1);
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          alignItems: "center"
        }}
      >
        <IconButton
          color="secondary"
          aria-label="back"
          size="large"
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceOutlined fontSize="inherit" />
        </IconButton>
        <Header
          title={
            <span>
              Listing (
              <a
                href={`https://www.ebay.com/itm/${listingInfo.ebayItemId}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.palette.mode === "dark" ? "#FAFAFC" : "#4F4F4F" }}
              >
                {listingInfo.ebayItemId}
              </a>
              )
            </span>
          }
          subtitle="Edit And Manage Your Listing"
        />
      </Box>
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "85%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="General Info" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <Typography variant="h6" p="3px" mb="10px">ebay Title</Typography>
              <TextField
                id="outlined-read-only-input"
                label={`ebay Title ${ebayTitle.length}/80`}
                value={ebayTitle}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 80) {
                    setEbayTitle(inputValue);
                  }
                }}
                fullWidth
                color="secondary"
              />
            </Box>
            <Box>
              <Typography variant="h6" p="3px" mb="10px">Supplier Description</Typography>
              <TextField
                id="outlined-multiline-static"
                label="Supplier Description"
                multiline
                value={listingInfo?.inventoryInfo?.description || ""}
                rows={4}
                fullWidth
                color="secondary"
                placeholder="Supplier description..."
              />
            </Box>
            <Box>
              <CustomTextField
                label="Synced"
                value={listingInfo?.synced || ""}
                muiProps={{
                  sx: {
                    width: "45%"
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <Box
                sx={{
                  width: "45%"
                }}
              >
                <CustomTextField
                  label="SKU"
                  value={listingInfo?.sku || ""}
                  muiProps={{
                    fullWidth: true
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "45%"
                }}
              >
                <CustomTextField
                  label="OEM"
                  value={listingInfo?.inventoryInfo?.oem || ""}
                  muiProps={{
                    fullWidth: true
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <Box
                sx={{
                  width: "45%"
                }}
              >
                <CustomTextField
                  label="Store"
                  value={listingInfo?.store || ""}
                  muiProps={{
                    fullWidth: true
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "45%"
                }}
              >
                <CustomTextField
                  label="Supplier"
                  value={listingInfo?.supplier || ""}
                  muiProps={{
                    fullWidth: true
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {
          listingInfo.errorLogs && listingInfo.errorLogs.length > 0 &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SubHeader title="Error Logs" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
                borderRadius: "10px",
                bgcolor: theme.palette.background.alt,
                padding: "2rem",
                gap: "2rem"
              }}
            >
              {listingInfo?.errorLogs.map((errorLog, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <Box
                    sx={{
                      width: "15%"
                    }}
                  >
                    <TextField
                      id={`outlined-read-only-input-${index}`}
                      label="Error Id"
                      value={errorLog.id}
                      InputProps={{
                        readOnly: true,
                      }}
                      color="secondary"
                      fullWidth
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "75%"
                    }}
                  >
                    <TextField
                      id={`outlined-read-only-input-message-${index}`}
                      label="Error Message"
                      value={errorLog.message}
                      InputProps={{
                        readOnly: true,
                      }}
                      color="secondary"
                      fullWidth
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        }
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Product Pricing" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between"
                // display: "flex",
              }}
            >
              {/* <CustomTextField
                label="Previous Price"
                value={listingInfo.previousPrice || ""}
                muiProps={{
                  fullWidth: true
                }}
                inputProps={{
                  readOnly: true
                }}
                muiBoxSx={{ width: "35%" }}
              /> */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <CustomTextField
                  label="Start Price"
                  value={startPrice}
                  onChange={(e) => setStartPrice(e.target.value)}
                  muiBoxSx={{ width: "60%" }}
                />
                <FormControl
                  component="fieldset"
                  sx={{
                    width: "35%"
                  }}
                >
                  <FormControlLabel
                    control={<Switch
                      color="secondary"
                      checked={startPriceFixed}
                      onChange={() => setStartPriceFixed(!startPriceFixed)}
                    />}
                    label="Fixed Price"
                    labelPlacement="top"
                  />
                </FormControl>
              </Box>
            </Box>
            <Divider middle />
            {
              listingInfo?.supplierCostAdjustment
                ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    <Box>
                      <Typography fontWeight="bold" variant="h4" p="3px" mb="25px">Supplier Prices</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div style={{ width: "23%", margin: "0 10px 10px 0" }}>
                            <CustomTextField
                              label="Provided Shipping Cost"
                              value={shippingCost}
                              onChange={(e) => setShippingCost(e.target.value)}
                              muiBoxSx={{ width: "100%" }}
                              muiProps={{
                                fullWidth: true,
                                type: "number"
                              }}
                            />
                          </div>
                          {Object.keys(inventoryPrices).length && Object.keys(inventoryPrices).map(price => (
                            <div key={price} style={{ width: "23%", margin: "0 10px 10px 0" }}>
                              <CustomTextField
                                label={price}
                                value={inventoryPrices[price]}
                                onChange={(e) => setInventoryPrices({
                                  ...inventoryPrices,
                                  [price]: e.target.value
                                })}
                                muiBoxSx={{ width: "100%" }}
                                muiProps={{
                                  type: "number"
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "15px",
                        }}
                      >
                        <CustomTextField
                          label="Supplier Cost"
                          value={listingInfo?.competitorInfo?.cost || ""}
                          muiBoxSx={{
                            width: "34%"
                          }}
                          inputProps={{
                            readOnly: true
                          }}
                        />
                        <CustomTextField
                          label="Supplier Cost Formula"
                          value={listingInfo?.competitorInfo?.costFormula || ""}
                          muiBoxSx={{
                            width: "64%"
                          }}
                          inputProps={{
                            readOnly: true
                          }}
                        />
                      </Box>
                    </Box>
                    <Divider middle />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "15px",
                      }}
                    >
                      <CustomTextField
                        label="Competitor Name"
                        value={listingInfo?.competitorInfo?.name || ""}
                        muiBoxSx={{
                          width: "49%"
                        }}
                        inputProps={{
                          readOnly: true
                        }}
                      />
                      {
                        listingInfo?.competitorInfo?.subtractedMargin
                          ? (
                            <CustomTextField
                              label="Start Price Subtracted Margin"
                              value={listingInfo?.competitorInfo?.subtractedMargin || ""}
                              muiBoxSx={{
                                width: "49%"
                              }}
                              inputProps={{
                                readOnly: true,
                                endAdornment: <InputAdornment position="end">
                                  <PercentOutlined />
                                </InputAdornment>
                              }}
                            />
                          ) : (
                            <CustomTextField
                              label="Start Price Added Margin"
                              value={listingInfo?.competitorInfo?.addedMargin || ""}
                              muiBoxSx={{
                                width: "45%"
                              }}
                              inputProps={{
                                readOnly: true,
                                endAdornment: <InputAdornment position="end">
                                  <PercentOutlined />
                                </InputAdornment>
                              }}
                            />
                          )
                      }
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "15px",
                      }}
                    >
                      <CustomTextField
                        label="eBay Cost"
                        value={listingInfo?.competitorInfo?.ebayCost || ""}
                        muiBoxSx={{
                          width: "19%"
                        }}
                        inputProps={{
                          readOnly: true
                        }}
                      />
                      <CustomTextField
                        label="eBay Cost Percent"
                        value={listingInfo?.competitorInfo?.ebayCostPercent || ""}
                        muiBoxSx={{
                          width: "19%"
                        }}
                        inputProps={{
                          readOnly: true,
                          endAdornment: <InputAdornment position="end">
                            <PercentOutlined />
                          </InputAdornment>
                        }}
                      />
                      <CustomTextField
                        label="eBay Plus Supplier Cost"
                        value={listingInfo?.competitorInfo?.totalCost || ""}
                        muiBoxSx={{
                          width: "29%"
                        }}
                        inputProps={{
                          readOnly: true
                        }}
                      />
                      <CustomTextField
                        label="eBay Plus Supplier Cost Percent"
                        value={listingInfo?.competitorInfo?.totalCostPercent || ""}
                        muiBoxSx={{
                          width: "29%"
                        }}
                        inputProps={{
                          readOnly: true,
                          endAdornment: <InputAdornment position="end">
                            <PercentOutlined />
                          </InputAdornment>
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "15px",
                      }}
                    >
                      <CustomTextField
                        label="Net Profit"
                        value={listingInfo?.competitorInfo?.net || ""}
                        muiBoxSx={{
                          width: "49%"
                        }}
                        inputProps={{
                          readOnly: true
                        }}
                      />
                      <CustomTextField
                        label="Net Profit Percent"
                        value={listingInfo?.competitorInfo?.netPercent || ""}
                        muiBoxSx={{
                          width: "49%"
                        }}
                        inputProps={{
                          readOnly: true,
                          endAdornment: <InputAdornment position="end">
                            <PercentOutlined />
                          </InputAdornment>
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}

                    >
                      <CustomTextField
                        label="Price Formula"
                        value={listingInfo?.inventoryInfo?.appliedFormula || ""}
                        inputProps={{
                          readOnly: true
                        }}
                        muiBoxSx={{
                          width: "75%"
                        }}
                      />
                      <CustomTextField
                        label="Price Mark Up"
                        value={listingInfo?.priceMarkUp || ""}
                        inputProps={{
                          readOnly: true,
                          endAdornment: <InputAdornment position="end">
                            <PercentOutlined />
                          </InputAdornment>
                        }}
                        muiBoxSx={{
                          width: "20%"
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography variant="h4" p="3px" mb="25px">Supplier Prices</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div style={{ width: "23%", margin: "0 10px 10px 0" }}>
                            <CustomTextField
                              label="Provided Shipping Cost"
                              value={shippingCost}
                              onChange={(e) => setShippingCost(e.target.value)}
                              muiBoxSx={{ width: "100%" }}
                              muiProps={{
                                fullWidth: true,
                                type: "number"
                              }}
                            />
                          </div>
                          {Object.keys(inventoryPrices).length && Object.keys(inventoryPrices).map(price => (
                            <div key={price} style={{ width: "23%", margin: "0 10px 10px 0" }}>
                              <CustomTextField
                                label={price}
                                value={inventoryPrices[price]}
                                onChange={(e) => setInventoryPrices({
                                  ...inventoryPrices,
                                  [price]: e.target.value
                                })}
                                muiBoxSx={{ width: "100%" }}
                                muiProps={{
                                  type: "number"
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        <CustomTextField
                          label="Supplier Total Cost"
                          value={listingInfo?.suppliersPrices?.find(obj => obj?.supplierId === listingInfo?.supplierId)?.supplierCost}
                          muiBoxSx={{ width: "45%" }}
                          muiProps={{
                            InputLabelProps: {
                              shrink: Boolean(listingInfo?.suppliersPrices?.find(obj => obj?.supplierId === listingInfo?.supplierId)?.supplierCost),
                            }
                          }}
                          inputProps={{
                            readOnly: true
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )
            }
          </Box>
        </Box>
        {
          listingInfo?.suppliersPrices?.length > 0 &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "30%",
                gap: "1.5rem"
              }}
            >
              <SubHeader title="Suppliers Prices" />
              <FormControl sx={{ width: "80%" }}>
                <InputLabel
                  id="select-supplier-range-label"
                  color="secondary"
                >Supplier</InputLabel>
                <Select
                  labelId="select-supplier-range-label"
                  defaultValue={listingInfo.suppliersPrices[0].supplier}
                  value={supplierPrices.supplier}
                  label="Supplier"
                  color="secondary"
                  onChange={(e) => setSupplierPrices(listingInfo.suppliersPrices.find(obj => obj?.supplier === e.target.value))}
                >
                  {listingInfo.suppliersPrices.map(({ supplier: option }) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
                borderRadius: "10px",
                bgcolor: theme.palette.background.alt,
                padding: "2rem",
                gap: "2rem"
              }}
            >
              <Box>
                <Typography fontWeight="bold" variant="h4" p="3px" >{supplierPrices?.supplier} Prices</Typography>
              </Box>
              <Divider middle />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ width: "23%", margin: "0 10px 10px 0" }}>
                    <CustomTextField
                      label="Provided Shipping Cost"
                      value={supplierPrices?.shippingCost}
                      muiProps={{
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: Boolean(typeof supplierPrices?.shippingCost === "number"),
                        }
                      }}
                    />
                  </div>
                  {
                    typeof supplierPrices?.inventoryPrices === "object" &&
                    Object.keys(supplierPrices.inventoryPrices).map(price => (
                      <div key={price} style={{ width: "23%", margin: "0 10px 10px 0" }}>
                        <CustomTextField
                          label={`Supplier ( ${price} )`}
                          value={supplierPrices.inventoryPrices[price]}
                          muiProps={{
                            fullWidth: true,
                            InputLabelProps: {
                              shrink: Boolean(typeof supplierPrices.inventoryPrices[price] === "number" || supplierPrices.inventoryPrices[price]),
                            }
                          }}
                          muiBoxSx={{ width: "100%" }}
                        />
                      </div>
                    ))
                  }
                </div>
                <CustomTextField
                  label="Supplier Total Cost"
                  value={supplierPrices?.supplierCost}
                  muiProps={{
                    fullWidth: true,
                    InputLabelProps: {
                      shrink: Boolean(supplierPrices?.supplierCost),
                    }
                  }}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Box>
              <Divider middle />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "15px",
                }}
              >
                <CustomTextField
                  label="Selling Price"
                  value={supplierPrices?.sellingPrice}
                  muiProps={{
                    fullWidth: true,
                    InputLabelProps: {
                      shrink: Boolean(supplierPrices?.sellingPrice),
                    }
                  }}
                  muiBoxSx={{ width: "18%" }}
                />
                <CustomTextField
                  label="Selling Price Formula"
                  value={supplierPrices?.formula}
                  muiProps={{
                    fullWidth: true,
                    InputLabelProps: {
                      shrink: Boolean(supplierPrices?.formula),
                    }
                  }}
                  muiBoxSx={{ width: "58%" }}
                />
                <CustomTextField
                  label="Price Mark Up (store)"
                  value={supplierPrices?.priceMarkUp}
                  muiProps={{
                    fullWidth: true,
                    InputLabelProps: {
                      shrink: Boolean(supplierPrices?.priceMarkUp),
                    }
                  }}
                  inputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">
                      <PercentOutlined />
                    </InputAdornment>
                  }}
                  muiBoxSx={{ width: "18%" }}
                />
              </Box>
              <Divider middle />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "15px",
                  }}
                >
                  <CustomTextField
                    label="Net Profit"
                    value={supplierPrices?.net}
                    muiProps={{
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: Boolean(supplierPrices?.net),
                      }
                    }}
                    inputProps={{
                      readOnly: true
                    }}
                    muiBoxSx={{ width: "18%" }}
                  />
                  <CustomTextField
                    label="Net Profit Percent"
                    value={supplierPrices?.netPercent}
                    muiProps={{
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: Boolean(supplierPrices?.netPercent),
                      }
                    }}
                    inputProps={{
                      readOnly: true,
                      endAdornment: <InputAdornment position="end">
                        <PercentOutlined />
                      </InputAdornment>
                    }}
                    muiBoxSx={{ width: "18%" }}
                  />
                  <CustomTextField
                    label="Net Formula"
                    value={`Selling Price - (eBay Commission + Supplier Cost)`}
                    muiProps={{
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: true,
                      }
                    }}
                    inputProps={{
                      readOnly: true
                    }}
                    muiBoxSx={{ width: "58%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "15px",
                  }}
                >
                  <CustomTextField
                    label="Supplier Cost"
                    value={supplierPrices?.supplierCost}
                    muiProps={{
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: Boolean(supplierPrices?.supplierCost),
                      }
                    }}
                    inputProps={{
                      readOnly: true
                    }}
                    muiBoxSx={{ width: "23%" }}
                  />
                  <CustomTextField
                    label="Supplier Cost Percent"
                    value={supplierPrices?.supplierCostPercent}
                    muiProps={{
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: Boolean(supplierPrices?.supplierCostPercent),
                      }
                    }}
                    inputProps={{
                      readOnly: true,
                      endAdornment: <InputAdornment position="end">
                        <PercentOutlined />
                      </InputAdornment>
                    }}
                    muiBoxSx={{ width: "23%" }}
                  />
                  <CustomTextField
                    label="eBay Cost"
                    value={supplierPrices?.ebayCost}
                    muiProps={{
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: Boolean(supplierPrices?.ebayCost),
                      }
                    }}
                    inputProps={{
                      readOnly: true
                    }}
                    muiBoxSx={{ width: "23%" }}
                  />
                  <CustomTextField
                    label="eBay Cost Percent"
                    value={supplierPrices?.ebayCostPercent}
                    muiProps={{
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: Boolean(supplierPrices?.ebayCostPercent),
                      }
                    }}
                    inputProps={{
                      readOnly: true,
                      endAdornment: <InputAdornment position="end">
                        <PercentOutlined />
                      </InputAdornment>
                    }}
                    muiBoxSx={{ width: "23%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "15px",
                  }}
                >
                  <CustomTextField
                    label="eBay Plus Supplier Cost"
                    value={supplierPrices?.totalCost}
                    muiProps={{
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: Boolean(supplierPrices?.totalCost),
                      }
                    }}
                    inputProps={{
                      readOnly: true
                    }}
                    muiBoxSx={{ width: "48%" }}
                  />
                  <CustomTextField
                    label="eBay Plus Supplier Cost Percent"
                    value={supplierPrices?.totalCostPercent}
                    muiProps={{
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: Boolean(supplierPrices?.totalCostPercent),
                      }
                    }}
                    inputProps={{
                      readOnly: true,
                      endAdornment: <InputAdornment position="end">
                        <PercentOutlined />
                      </InputAdornment>
                    }}
                    muiBoxSx={{ width: "48%" }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        }
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Product Quantity" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <CustomTextField
                label="Previous Quantity"
                value={listingInfo.previousQuantity || 0}
                muiProps={{
                  fullWidth: true
                }}
                inputProps={{
                  readOnly: true
                }}
              />
            </Box>
            <Divider middle />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
              }}
            >
              <CustomTextField
                label="Available Quantity"
                value={quantity}
                muiProps={{
                  fullWidth: true
                }}
                inputProps={{
                  readOnly: true
                }}
                muiBoxSx={{
                  width: "48%"
                }}
              />
              <CustomTextField
                label="Sold Quantity"
                value={typeof listingInfo?.soldQuantity === "number" ? listingInfo.soldQuantity : 0}
                muiProps={{
                  fullWidth: true
                }}
                inputProps={{
                  readOnly: true
                }}
                muiBoxSx={{
                  width: "48%"
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "40px"
          }}
        >
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{
              width: "20%",
              padding: "10px",
              alignSelf: "flex-end"
            }}
            onClick={() => setDeleteDialogOpen(true)}
            loading={deleteStatus === "pending"}
          >Delete</LoadingButton>
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{
              width: "20%",
              padding: "10px",
              alignSelf: "flex-end"
            }}
            onClick={() => setSaveDialogOpen(true)}
            loading={updateStatus === 'pending'}
          >Save</LoadingButton>
        </Box>
      </Box>
      <DialogBox
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        titleText="Delete Confirmation"
        messageText="Are you sure you want to delete this listing?"
        confirmText="Confirm Delete"
      />
      <DialogBox
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onConfirm={() => handleSave('revise')}
        titleText="Save Confirmation"
        messageText="Are you sure you want to save this change?"
        confirmText="Revise Item"
      />
    </Box >
  );
}

export default ListingEdit;