import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from 'react-router-dom';

import {
  Box,
  Button,
  useTheme,
  FormControl,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  LinearProgress,
  Typography
} from "@mui/material";
import { MuiFileInput } from 'mui-file-input';
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";

import { OpenInNewOutlined, Download } from "@mui/icons-material";

import socket from "app/socket";

import Header from "components/Header";
import Filter from "components/Filter";
import DialogBox from "components/DialogBox";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";

import { useDispatch, useSelector } from "react-redux";
import {
  getListingsApi,
  getListingsXlsxApi,
  updateListingsFromXlsxApi,
  deleteListingApi,
  deleteSpecificListingsApi,
  deleteSpecificListingsFormatApi
} from "features/listings/listingsApi";
import {
  updateFilter,
  updateRangeFilter,
  clearFilters
} from "features/listings/listingsSlice";
import { getSuppliersApi } from "features/suppliers/suppliersApi";
import { getStoresApi } from "features/stores/storesApi";

const quantityFilter = [
  { option: "1 to 5", value: "1-5" },
  { option: "6 to 10", value: "6-10" },
  { option: "11 to 15", value: "11-15" },
  { option: "16 to 30", value: "16-30" },
  { option: "31 to max", value: "31-max" },
];

const priceFilter = [
  { option: "$1 to $10", value: "1-10" },
  { option: "$11 to $50", value: "11-50" },
  { option: "$51 to $100", value: "51-100" },
  { option: "$101 to $1000", value: "101-1000" },
  { option: "$1001 to max", value: "1001-max" },
];

const Listings = ({ setProgress }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    listingsLoading,
    listingsXlsxLoading,
    updateFromXlsxStatus,
    listings,
    totalResults,
    filters,
    deleteStatus
  } = useSelector((state) => state.listings);
  const { supplierIdPairs, syncLoading } = useSelector((state) => state.suppliers);
  const { storeIdPairs } = useSelector((state) => state.stores);

  let [searchParams, setSearchParams] = useSearchParams();

  const [marketplace, setMarketplace] = useState("ebay");

  const [store, setStore] = useState("");
  const [supplier, setSupplier] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [quantityRange, setQuantityRange] = useState("");
  const [soldQuantityRange, setSoldQuantityRange] = useState("");

  const [switchOptionsSelected, setSwitchOptionsSelected] = useState([]);
  const [synced, setSynced] = useState(false);
  const [nonSynced, setNonSynced] = useState(false);
  const [fixedPrice, setFixedPrice] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [syncDialogOpen, setSyncDialogOpen] = useState(false);
  const [blukExportDialogOpen, setBlukExportDialogOpen] = useState(false);
  const [blukUpdateDialogOpen, setBlukUpdateDialogOpen] = useState(false);

  const [customPriceFilterDialogOpen, setCustomPriceFilterDialogOpen] = useState(false);
  const [customQuantityFilterDialogOpen, setCustomQuantityFilterDialogOpen] = useState(false);
  const [customSoldQuantityFilterDialogOpen, setCustomSoldQuantityFilterDialogOpen] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [filterDeleteDialogOpen, setFilterDeleteDialogOpen] = useState(false);
  const [specificDeleteDialogOpen, setSpecificDeleteDialogOpen] = useState(false);

  const [deleteFilter, setDeleteFilter] = useState({
    store: "",
    supplier: "",
  });
  const [specificDeleteFile, setSpecificDeleteFile] = useState(null);

  const [selectedListings, setSelectedListings] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const [searchInput, setSearchInput] = useState("");

  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [minQuantity, setMinQuantity] = useState('');
  const [maxQuantity, setMaxQuantity] = useState('');
  const [minSoldQuantity, setMinSoldQuantity] = useState('');
  const [maxSoldQuantity, setMaxSoldQuantity] = useState('');

  const [initialLoaded, setInitialLoaded] = useState(false);

  // Syncing progress
  const [syncingProgress, setSyncingProgress] = useState(0);

  useEffect(() => {
    dispatch(clearFilters());
    handleSwitchOptionsSelected([]);

    const marketplaceQuery = searchParams.get("marketplace")
    if (marketplaceQuery) handleMarketplaceChange(marketplaceQuery);


    const pageQuery = searchParams.get("page")
    const pageSizeQuery = searchParams.get("pageSize")
    if (pageQuery && pageSizeQuery) setPaginationModel({
      pageSize: parseInt(pageSizeQuery),
      page: parseInt(pageQuery)
    });

    const storeQuery = searchParams.get("store");
    handleStoreChange(storeQuery);

    const supplierQuery = searchParams.get("supplier");
    handleSupplierChange(supplierQuery);

    const syncedQuery = searchParams.get("synced");
    const nonSyncedQuery = searchParams.get("nonSynced");
    const fixedPriceQuery = searchParams.get("fixedPrice");

    const switchOptionsSelectedQuery = [];

    if (syncedQuery === "true") switchOptionsSelectedQuery.push("synced");
    if (nonSyncedQuery === "true") switchOptionsSelectedQuery.push("nonSynced");
    if (fixedPriceQuery === "true") switchOptionsSelectedQuery.push("fixedPrice");

    handleSwitchOptionsSelected(switchOptionsSelectedQuery);

    const priceQuery = searchParams.get("price");
    if (priceQuery && priceQuery !== "-") {
      if (priceFilter.find(({ value }) => value === priceQuery)) handlePriceRange(priceQuery);
      else {
        setPriceRange("custom");

        setMinPrice(priceQuery.split("-")[0]);
        setMaxPrice(priceQuery.split("-")[1]);

        dispatch(updateRangeFilter({ type: "price", value: `${priceQuery.split("-")[0]}-${priceQuery.split("-")[1]}` }));
      }
    };

    const quantityQuery = searchParams.get("quantity");
    if (quantityQuery && quantityQuery !== "-") {
      if (quantityFilter.find(({ value }) => value === quantityQuery)) handleQuantityRange(quantityQuery)
      else {
        setQuantityRange("custom");

        setMinQuantity(quantityQuery.split("-")[0]);
        setMaxQuantity(quantityQuery.split("-")[1]);

        dispatch(updateRangeFilter({ type: "quantity", value: `${quantityQuery.split("-")[0]}-${quantityQuery.split("-")[1]}` }));
      }
    };

    const soldQuantityQuery = searchParams.get("soldQuantity");
    if (soldQuantityQuery && soldQuantityQuery !== "-") {
      if (quantityFilter.find(({ value }) => value === soldQuantityQuery)) handleSoldQuantityRange(soldQuantityQuery)
      else {
        setSoldQuantityRange("custom");

        setMinSoldQuantity(soldQuantityQuery.split("-")[0]);
        setMaxSoldQuantity(soldQuantityQuery.split("-")[1]);

        dispatch(updateRangeFilter({ type: "soldQuantity", value: `${soldQuantityQuery.split("-")[0]}-${soldQuantityQuery.split("-")[1]}` }));
      }
    };

    const searchQuery = searchParams.get("search");
    setSearchInput(searchQuery);

    setInitialLoaded(true);

    // Listen for the "syncingProgress" event
    socket.on("syncingProgress", (data) => {
      // Update the component state with the progress value received from the server
      setSyncingProgress(data.message);
    });

    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off("syncingProgress");
    };
  }, []);

  // useEffect(() => {
  //   dispatch(clearFilters());
  //   handleSwitchOptionsSelected([]);
  // }, []);

  useEffect(() => {
    if (!listingsLoading) setProgress(100);
  }, [listingsLoading]);

  useEffect(() => {
    if (initialLoaded) {
      setSearchParams({
        marketplace: marketplace,
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        search: searchInput ? searchInput : "",
        ...(Object.fromEntries(
          Object.entries(filters)
            .filter(([key, value]) => typeof value !== "object")
        )),
        price: `${filters.price.minimum}-${filters.price.maximum}`,
        quantity: `${filters.quantity.minimum}-${filters.quantity.maximum}`,
        soldQuantity: `${filters.soldQuantity.minimum}-${filters.soldQuantity.maximum}`
      });

      setProgress(0);

      dispatch(getSuppliersApi({ type: 'id-pairs' }));
      dispatch(getStoresApi({ type: 'id-pairs', marketplace }));
      handleGetListings();

      setProgress(36);
    }
  }, [filters, paginationModel, marketplace, initialLoaded]);

  useEffect(() => {
    if (initialLoaded && deleteStatus !== 'pending') {
      handleGetListings();
    };
  }, [deleteStatus, initialLoaded])

  useEffect(() => {
    if (selectedFile) {
      setBlukUpdateDialogOpen(true);
    }
  }, [selectedFile]);

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.6,
    },
    {
      field: "store",
      headerName: "Store",
      flex: 1,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 1,
    },
    {
      field: "itemId",
      headerName: "Item Id",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`https://www.ebay.com/itm/${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: theme.palette.mode === "dark" ? "#FAFAFC" : "#4F4F4F" }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <span>{params.value}</span>
        </a>
      ),
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
    },
    {
      field: "inventoryInfo",
      headerName: "OEM",
      flex: 1,
      renderCell: (params) => params.row.inventoryInfo?.oem || "-",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 3,
    },
    {
      field: "startPrice",
      headerName: "Start Price",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "soldQuantity",
      headerName: "Sold Quantity",
      flex: 1,
    },
    {
      field: "synced",
      headerName: "Synced",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            padding: "6px",
            fontWeight: "semibold",
            color: "white",
            backgroundColor: params.row.syncedStatusColor,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Link to={`/listings/${params.row._id}`}>
          <OpenInNewOutlined color="secondary" />
        </Link>
      ),
    }
  ];

  const handlePriceFilterContinue = () => {
    dispatch(updateRangeFilter({ type: "price", value: `${minPrice}-${maxPrice}` }));
    setCustomPriceFilterDialogOpen(false);
  };

  const handleQuantityFilterContinue = () => {
    dispatch(updateRangeFilter({ type: "quantity", value: `${minQuantity}-${maxQuantity}` }));
    setCustomQuantityFilterDialogOpen(false);
  };

  const handleSoldQuantityFilterContinue = () => {
    dispatch(updateRangeFilter({ type: "soldQuantity", value: `${minSoldQuantity}-${maxSoldQuantity}` }));
    setCustomSoldQuantityFilterDialogOpen(false);
  };

  const handleSwitchOptionsSelected = (options) => {
    let updatedOptions = [...options];

    if (options.includes('synced')) {
      handleSyncedChange(true);
    }
    else handleSyncedChange(false);

    if (options.includes('nonSynced')) {
      handleNonSyncedChange(true);
    }
    else handleNonSyncedChange(false);

    if (options.includes('fixedPrice')) handleFixedPriceChange(true);
    else handleFixedPriceChange(false);

    setSwitchOptionsSelected(updatedOptions);
  };

  const handleSyncedChange = (synced) => {
    setSynced(synced);
    dispatch(updateFilter({ type: "synced", value: synced }));
  };

  const handleNonSyncedChange = (nonSynced) => {
    setNonSynced(nonSynced);
    dispatch(updateFilter({ type: "nonSynced", value: nonSynced }));
  };

  const handleFixedPriceChange = (fixedPrice) => {
    setFixedPrice(fixedPrice);
    dispatch(updateFilter({ type: "fixedPrice", value: fixedPrice }));
  };

  const handleStoreChange = (store) => {
    setStore(store);
    dispatch(updateFilter({ type: "store", value: store }));
  };

  const handleSupplierChange = (supplier) => {
    setSupplier(supplier);
    dispatch(updateFilter({ type: "supplier", value: supplier }));
  };

  const handleGetListings = () => {
    dispatch(getListingsApi({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search: searchInput ? searchInput : "",
      filters
    }));
  };

  const handleSync = () => {
    // dispatch(syncListings());
    socket.emit('startSyncing', { sync: true });
    setSyncDialogOpen(false);
  };

  const handleGetXlsx = () => {
    dispatch(getListingsXlsxApi({ search: searchInput, filters }));
    setBlukExportDialogOpen(false);
  };

  const handleUpdateFromXlsx = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleBlukUpdate = () => {
    if (selectedFile) {
      dispatch(updateListingsFromXlsxApi(selectedFile));
    }

    setSelectedFile(null);
    setBlukUpdateDialogOpen(false);
  };

  const handleDeleteSelected = () => {
    dispatch(deleteListingApi({ type: 'ids', data: selectedListings }));

    setDeleteDialogOpen(false);
  };

  const handleFilterDelete = () => {
    dispatch(deleteListingApi({ type: 'filter', data: deleteFilter }));

    setFilterDeleteDialogOpen(false);
  };

  const handlePriceRange = (value) => {
    if (value === "custom") {
      setPriceRange("custom");
      setCustomPriceFilterDialogOpen(true);
    } else {
      setPriceRange(value);
      dispatch(updateRangeFilter({ type: "price", value: value }));
    }
  };

  const handleQuantityRange = (value) => {
    if (value === "custom") {
      setQuantityRange("custom");
      setCustomQuantityFilterDialogOpen(true);
    } else {
      setQuantityRange(value);
      dispatch(updateRangeFilter({ type: "quantity", value: value }));
    }
  };

  const handleSoldQuantityRange = (value) => {
    if (value === "custom") {
      setSoldQuantityRange("custom");
      setCustomSoldQuantityFilterDialogOpen(true);
    } else {
      setSoldQuantityRange(value);
      dispatch(updateRangeFilter({ type: "soldQuantity", value: value }));
    }
  };

  const handleMarketplaceChange = (marketplace) => {
    setMarketplace(marketplace);
    dispatch(updateFilter({ type: "marketplace", value: marketplace }));
  };

  const handleSearch = () => {
    if (initialLoaded) {
      searchParams.set('search', searchInput ? searchInput : "");
      handleGetListings();
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header title="Listings" subtitle="Manage Store Listings" />
        <FormControl sx={{ width: "20%" }}>
          <InputLabel
            id="select-marketplace-range-label"
            color="secondary"
          >Marketplace</InputLabel>
          <Select
            labelId="select-marketplace-range-label"
            value={marketplace}
            label="Marketplace"
            color="secondary"
            onChange={(e) => handleMarketplaceChange(e.target.value)}
          >
            <MenuItem value="ebay">
              eBay
            </MenuItem>
            <MenuItem value="amazon">
              Amazon
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        m="1.5rem 0 0.5rem 0"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "65%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Filter
            type="store"
            label="Store"
            filter={
              storeIdPairs.map((obj) => {
                return {
                  option: obj.storeName,
                  value: obj._id
                }
              })
            }
            custom={false}
            value={store}
            onChange={(e) => handleStoreChange(e.target.value)}
          />
          <Filter
            type="supplier"
            label="Supplier"
            filter={
              [...supplierIdPairs.map((obj) => {
                return {
                  option: obj.supplierNonCamelCase,
                  value: obj._id
                }
              }),
              {
                option: 'No Supplier',
                value: 'noSupplier'
              }
              ]
            }
            custom={false}
            value={supplier}
            onChange={(e) => handleSupplierChange(e.target.value)}
          />
          <Filter
            type="price"
            label="Price"
            filter={priceFilter}
            custom={true}
            value={priceRange}
            onChange={(e) => handlePriceRange(e.target.value)}
          />
          <Filter
            type="quantity"
            label="Quantity"
            filter={quantityFilter}
            custom={true}
            value={quantityRange}
            onChange={(e) => handleQuantityRange(e.target.value)}
          />
          {
            marketplace === "ebay" &&
            <Filter
              type="soldQuantity"
              label="Sold Quantity"
              filter={quantityFilter}
              custom={true}
              value={soldQuantityRange}
              onChange={(e) => handleSoldQuantityRange(e.target.value)}
            />
          }
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel
              color="secondary"
              id="select-switch-label"
            >Select Option</InputLabel>
            <Select
              color="secondary"
              labelId="select-switch-label"
              value={switchOptionsSelected}
              label="Select Option"
              onChange={(e) => handleSwitchOptionsSelected(e.target.value)}
              multiple
              renderValue={(selected) => `Active ${selected.length}`}
            >
              <MenuItem value="synced">
                <Checkbox
                  color="secondary"
                  checked={synced}
                  readOnly
                />
                <ListItemText primary="Synced" />
              </MenuItem>
              <MenuItem value="nonSynced">
                <Checkbox
                  color="secondary"
                  checked={nonSynced}
                  readOnly
                />
                <ListItemText primary="Non Synced" />
              </MenuItem>
              <MenuItem value="fixedPrice">
                <Checkbox
                  color="secondary"
                  checked={fixedPrice}
                  readOnly
                />
                <ListItemText primary="Fixed Price" />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "35%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{
              width: "32%",
              padding: "15px 15px",
              alignSelf: "flex-end",
              whiteSpace: 'nowrap',
              fontSize: '11px',
            }}
            onClick={() => setBlukExportDialogOpen(true)}
            loading={listingsXlsxLoading}
          >Listings Export
          </LoadingButton>
          <input
            accept=".csv"
            id="import-xlsx"
            type="file"
            style={{ display: 'none' }}
            onChange={handleUpdateFromXlsx}
          />
          <label
            htmlFor="import-xlsx"
            style={{
              display: 'inline-block',
              // margin: '0 25px 0 0',
              width: "32%",
            }}>
            <LoadingButton
              color="secondary"
              variant="contained"
              component="span"
              sx={{
                width: "100%",
                padding: "15px 15px",
                alignSelf: "flex-end",
                whiteSpace: 'nowrap',
                fontSize: '11px',
              }}
              loading={updateFromXlsxStatus === 'pending'}
            >
              Listings Import
            </LoadingButton>
          </label>
          {syncingProgress ?
            <Box
              sx={{
                width: "32%",
                height: "3.2rem",
                position: "relative"
              }}
            >
              <LinearProgress
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5px",
                }}
                color="secondary"
                variant="determinate"
                value={syncingProgress}
              />
              <Typography
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "black",
                }}
              >
                {`${syncingProgress}%`}
              </Typography>
            </Box>
            : <LoadingButton
              color="secondary"
              variant="contained"
              sx={{
                width: "32%",
                padding: "15px 15px",
                alignSelf: "flex-end",
                whiteSpace: 'nowrap',
                fontSize: '12px',
              }}
              onClick={() => setSyncDialogOpen(true)}
              loading={syncLoading}
            >Sync
            </LoadingButton>
          }
        </Box>
      </Box>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              borderColor: "#fff",
            },
            "& .MuiDataGrid-checkboxInput": {
              color: `${theme.palette.secondary[400]} !important`,
            },
          }}
          loading={listingsLoading}
          getRowId={(row) => row._id}
          rows={listings || []}
          rowCount={totalResults}
          columns={
            marketplace === "ebay"
              ? columns
              : columns
                .filter(({ field }) => field !== "soldQuantity")
                .map(({ field, ...rest }) => {
                  if (field === "itemId") {
                    return {
                      field,
                      ...rest,
                      headerName: "ASIN",
                      renderCell: (params) => (
                        <a
                          href={`https://www.amazon.com/dp/${params.value}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: theme.palette.mode === "dark" ? "#FAFAFC" : "#4F4F4F" }}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <span>{params.value}</span>
                        </a>
                      ),
                    };
                  }

                  return { field, ...rest };
                })
          }
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          components={{
            Toolbar: DataGridCustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setDeleteDialogOpen,
              setFilterDeleteDialogOpen,
              setSpecificDeleteDialogOpen,
              handleSearch
            },
          }}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) => {
            setSelectedListings(newSelection);
          }}
          rowSelectionModel={selectedListings}
          disableRowSelectionOnClick
        />
      </Box>
      <DialogBox
        open={syncDialogOpen}
        onClose={() => setSyncDialogOpen(false)}
        onConfirm={handleSync}
        titleText="Sync Confirmation"
        messageText="Are you sure you want to start the synchronization process?"
        confirmText="Confirm Sync"
      />
      <DialogBox
        open={blukUpdateDialogOpen}
        onClose={() => setBlukUpdateDialogOpen(false)}
        onConfirm={handleBlukUpdate}
        titleText="Bluk Update Confirmation"
        messageText="Are you sure you want to do a bulk update?"
        confirmText="Confirm Bluk Update"
      />
      <DialogBox
        open={blukExportDialogOpen}
        onClose={() => setBlukExportDialogOpen(false)}
        onConfirm={handleGetXlsx}
        titleText="Bluk Export Confirmation"
        messageText="Are you sure you want to go ahead with exporting these listings in bulk?"
        confirmText="Confirm Bluk Export"
      />
      <DialogBox
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteSelected}
        titleText="Delete Confirmation"
        messageText="Are you sure you wish to delete these listings?"
        confirmText="Confirm Delete"
      />
      <Dialog
        open={customPriceFilterDialogOpen}
        onClose={() => setCustomPriceFilterDialogOpen(false)}
        aria-labelledby="custom-filter-dialog-title"
        aria-describedby="custom-filter-dialog-description"
      >
        <DialogTitle id="custom-filter-dialog-title">Custom Filter</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              gap: "12px"
            }}
          >
            <DialogContentText id="custom-filter-dialog-description">
              Enter Min and Max price values to filter by price range.
            </DialogContentText>
            <TextField
              color="secondary"
              variant="outlined"
              id="min-price"
              label="Min. Price"
              value={minPrice}
              onChange={(event) => setMinPrice(event.target.value)}
              fullWidth
            />
            <TextField
              color="secondary"
              variant="outlined"
              id="max-price"
              label="Max. Price"
              value={maxPrice}
              onChange={(event) => setMaxPrice(event.target.value)}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomPriceFilterDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePriceFilterContinue} color="secondary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={customQuantityFilterDialogOpen}
        onClose={() => setCustomQuantityFilterDialogOpen(false)}
        aria-labelledby="custom-filter-dialog-title"
        aria-describedby="custom-filter-dialog-description"
      >
        <DialogTitle id="custom-filter-dialog-title">Custom Filter</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              gap: "12px"
            }}
          >
            <DialogContentText id="custom-filter-dialog-description">
              Enter Min and Max quantity values to filter by quantity range.
            </DialogContentText>
            <TextField
              color="secondary"
              variant="outlined"
              id="min-quantity"
              label="Min. Quantity"
              value={minQuantity}
              onChange={(event) => setMinQuantity(event.target.value)}
              fullWidth
            />
            <TextField
              color="secondary"
              variant="outlined"
              id="max-quantity"
              label="Max. Quantity"
              value={maxQuantity}
              onChange={(event) => setMaxQuantity(event.target.value)}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomQuantityFilterDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleQuantityFilterContinue} color="secondary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={customSoldQuantityFilterDialogOpen}
        onClose={() => setCustomSoldQuantityFilterDialogOpen(false)}
        aria-labelledby="custom-filter-dialog-title"
        aria-describedby="custom-filter-dialog-description"
      >
        <DialogTitle id="custom-filter-dialog-title">Custom Filter</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              gap: "12px"
            }}
          >
            <DialogContentText id="custom-filter-dialog-description">
              Enter Min and Max sold quantity values to filter by sold quantity range.
            </DialogContentText>
            <TextField
              color="secondary"
              variant="outlined"
              id="min-sold-quantity"
              label="Min. Sold Quantity"
              value={minSoldQuantity}
              onChange={(event) => setMinSoldQuantity(event.target.value)}
              fullWidth
            />
            <TextField
              color="secondary"
              variant="outlined"
              id="max-sold-quantity"
              label="Max. Sold Quantity"
              value={maxSoldQuantity}
              onChange={(event) => setMaxSoldQuantity(event.target.value)}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomSoldQuantityFilterDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSoldQuantityFilterContinue} color="secondary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={filterDeleteDialogOpen}
        onClose={() => setFilterDeleteDialogOpen(false)}
        aria-labelledby="delete-filter-dialog-title"
        aria-describedby="delete-filter-dialog-description"
      >
        <DialogTitle id="delete-filter-dialog-title">Custom Delete Filter</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              gap: "12px"
            }}
          >
            <DialogContentText id="delete-filter-dialog-description">
              Enter store and supplier to use detele by filtering.
            </DialogContentText>
            <Filter
              type="store"
              label="Store"
              filter={
                storeIdPairs.map((obj) => {
                  return {
                    option: obj.storeName,
                    value: obj._id
                  }
                })
              }
              custom={false}
              value={deleteFilter.store}
              onChange={(e) => setDeleteFilter(prev => ({
                ...prev,
                store: e.target.value
              }))}
            />
            <Filter
              type="supplier"
              label="Supplier"
              filter={
                [...supplierIdPairs.map((obj) => {
                  return {
                    option: obj.supplierNonCamelCase,
                    value: obj._id
                  }
                }),
                {
                  option: 'No Supplier',
                  value: 'noSupplier'
                }
                ]
              }
              custom={false}
              value={deleteFilter.supplier}
              onChange={(e) => setDeleteFilter(prev => ({
                ...prev,
                supplier: e.target.value
              }))}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFilterDeleteDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFilterDelete} color="secondary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={specificDeleteDialogOpen}
        onClose={() => setSpecificDeleteDialogOpen(false)}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle>Delete Specific</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              gap: "12px"
            }}
          >
            <DialogContentText>
              Select Specifics Items File
            </DialogContentText>
            <MuiFileInput
              color="secondary"
              value={specificDeleteFile}
              onChange={(file) => setSpecificDeleteFile(file)}
              sx={{
                width: "100%"
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              sx={{
                width: "40%",
              }}
              onClick={() => dispatch(deleteSpecificListingsFormatApi())}
              endIcon={<Download />}
            >
              Format
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSpecificDeleteDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => {
            dispatch(deleteSpecificListingsApi({ file: specificDeleteFile }));
            
            setSpecificDeleteFile(null);
            setSpecificDeleteDialogOpen(false);

            handleGetListings();
          }} color="secondary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Listings;