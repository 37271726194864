import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Typography,
  LinearProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
} from "@mui/icons-material";

import Header from "components/Header";

import {
  getEbayApiRateLimitApi
} from "features/settings/settingsApi";

import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    ebayApiRateLimitApi
  } = useSelector((state) => state.settings);

  const [tradingApiRateLimit, setTradingApiRateLimit] = useState({
    limit: "------",
    remaining: "------"
  });
  const [browseApiRateLimit, setBrowseApiRateLimit] = useState({
    limit: "------",
    remaining: "------"
  });
  const [apiRateLimitResetTime, setApiRateLimitResetTime] = useState("------");

  useEffect(() => {
    dispatch(getEbayApiRateLimitApi());
  }, []);

  useEffect(() => {
    setTradingApiRateLimit(ebayApiRateLimitApi.tradingApiRateLimit);
    setBrowseApiRateLimit(ebayApiRateLimitApi.browseApiRateLimit);
    setApiRateLimitResetTime(ebayApiRateLimitApi.resetTime);
  }, [ebayApiRateLimitApi]);

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title="Dashboard" subtitle="" />
      <Box
        m="1.5rem 0 0.5rem 0"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "32%",
            backgroundColor: theme.palette.primary[500],
            padding: "50px",
            diplay: "flex",
            borderRadius: "15px"
          }}
        >
          <Typography fontWeight="bold" variant="h3" p="3px" mb="50px">
            eBay API Rate Limit
          </Typography>
          <Typography fontWeight="bold" variant="h4" p="3px" mb="20px">
            Trading API
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              LIMIT :
            </Typography>
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              {tradingApiRateLimit?.limit}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              RAMAINING :
            </Typography>
            <Typography fontWeight="bold" variant="h5" p="3px" mb="10px">
              {tradingApiRateLimit?.remaining}
            </Typography>
          </Box>
          <LinearProgress variant="determinate" color="secondary" value={((tradingApiRateLimit?.limit - tradingApiRateLimit?.remaining) / tradingApiRateLimit?.limit) * 100} />

          <Typography fontWeight="bold" variant="h3" p="3px" mb="20px" mt="20px">
            Browse API
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              LIMIT :
            </Typography>
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              {browseApiRateLimit?.limit}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              RAMAINING :
            </Typography>
            <Typography fontWeight="bold" variant="h5" p="3px" mb="10px">
              {browseApiRateLimit?.remaining}
            </Typography>
          </Box>
          <LinearProgress variant="determinate" color="secondary" value={((browseApiRateLimit?.limit - browseApiRateLimit?.remaining) / browseApiRateLimit?.limit) * 100} />

          <Typography fontWeight="bold" variant="h4" p="3px" mb="20px" mt="20px">
            Reset Date/Time
          </Typography>
          <Typography fontWeight="bold" variant="h5" p="3px" mt="20px">
            {apiRateLimitResetTime}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
