import { createSlice } from "@reduxjs/toolkit";
import {
  getAutomationsApi,
  updateAutomationsApi,
  getAnalzyerInfoApi,
  updateAnalzyerInfoApi,
  getAnalyzerLogsApi,
  deleteAnalyzerLogApi,
  getSearchTitleFormatsApi,
  addSearchTitleFormatsApi,
  deleteSearchTitleFormatsApi,
  getAnalzyerScheduleApi
} from "./jobsApi";

const initialState = {
  updateStatus: "",
  automations: {},
  // ebay analyzer
  analzyerUpdateStatus: "",
  analzyerInfo: {},
  analyzerLogsStatus: "",
  analyzerLogs: [],
  totalAnalyzerLogs: 0,
  deleteAnalyzerLogStatus: "",
  titleFormats: [],
  getSearchTitleFormatStatus: "",
  addSearchTitleFormatStatus: "",
  deleteSearchTitleFormatStatus: "",
  scheduleInfo: {}
};

const jobsSlice = createSlice({
  name: "jobsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getAutomations
      .addCase(getAutomationsApi.fulfilled, (state, { payload }) => {
        state.automations = payload.data ? payload.data : {};
      })
      // updateAutomationsApi
      .addCase(updateAutomationsApi.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateAutomationsApi.fulfilled, (state) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateAutomationsApi.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // getAnalzyerInfoApi
      .addCase(getAnalzyerInfoApi.fulfilled, (state, { payload }) => {
        state.analzyerInfo = payload?.data ? payload.data : {};
      })
      // updateAnalzyerInfoApi
      .addCase(updateAnalzyerInfoApi.pending, (state) => {
        state.analzyerUpdateStatus = "pending";
      })
      .addCase(updateAnalzyerInfoApi.fulfilled, (state) => {
        state.analzyerUpdateStatus = "fulfilled";
      })
      .addCase(updateAnalzyerInfoApi.rejected, (state) => {
        state.analzyerUpdateStatus = "rejected";
      })
      // getAnalyzerLogsApi
      .addCase(getAnalyzerLogsApi.pending, (state) => {
        state.analyzerLogsStatus = "pending";
      })
      .addCase(getAnalyzerLogsApi.fulfilled, (state, { payload }) => {
        state.analyzerLogs = payload.data.analyzerLogs;
        state.totalAnalyzerLogs = payload.data.totalAnalyzerLogs;
        state.analyzerLogsStatus = "fulfilled";
      })
      .addCase(getAnalyzerLogsApi.rejected, (state) => {
        state.analyzerLogsStatus = "rejected";
      })
      // deleteAnalyzerLogApi
      .addCase(deleteAnalyzerLogApi.pending, (state) => {
        state.deleteAnalyzerLogStatus = "pending";
      })
      .addCase(deleteAnalyzerLogApi.fulfilled, (state, { payload }) => {
        state.deleteAnalyzerLogStatus = "fulfilled";
      })
      .addCase(deleteAnalyzerLogApi.rejected, (state) => {
        state.deleteAnalyzerLogStatus = "rejected";
      })
      // getSearchTitleFormatsApi
      .addCase(getSearchTitleFormatsApi.pending, (state) => {
        state.getSearchTitleFormatStatus = "pending";
      })
      .addCase(getSearchTitleFormatsApi.fulfilled, (state, { payload }) => {
        state.titleFormats = payload.data.titleFormats;
        state.getSearchTitleFormatStatus = "fulfilled";
      })
      .addCase(getSearchTitleFormatsApi.rejected, (state) => {
        state.getSearchTitleFormatStatus = "rejected";
      })
      // addSearchTitleFormatsApi
      .addCase(addSearchTitleFormatsApi.pending, (state) => {
        state.addSearchTitleFormatStatus = "pending";
      })
      .addCase(addSearchTitleFormatsApi.fulfilled, (state) => {
        state.addSearchTitleFormatStatus = "fulfilled";
      })
      .addCase(addSearchTitleFormatsApi.rejected, (state) => {
        state.addSearchTitleFormatStatus = "rejected";
      })
      // deleteSearchTitleFormatsApi
      .addCase(deleteSearchTitleFormatsApi.pending, (state) => {
        state.deleteSearchTitleFormatStatus = "pending";
      })
      .addCase(deleteSearchTitleFormatsApi.fulfilled, (state) => {
        state.deleteSearchTitleFormatStatus = "fulfilled";
      })
      .addCase(deleteSearchTitleFormatsApi.rejected, (state) => {
        state.deleteSearchTitleFormatStatus = "rejected";
      })
      // getAnalzyerScheduleApi
      .addCase(getAnalzyerScheduleApi.fulfilled, (state, { payload }) => {
        state.scheduleInfo = payload.data;
      })
  },
});

export default jobsSlice.reducer;
