import { createSlice } from "@reduxjs/toolkit";
import {
  getEbayApiRateLimitApi
} from "./settingsApi";

const initialState = {
  ebayApiRateLimitApi: {
    tradingApiRateLimit: {
      limit: "------",
      remaining: "------"
    },
    browseApiRateLimit: {
      limit: "------",
      remaining: "------"
    },
    resetTime: "------"
  },
};

const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEbayApiRateLimitApi.fulfilled, (state, { payload }) => {
        state.ebayApiRateLimitApi = payload.data;
      })
  }
});

export default settingsSlice.reducer;