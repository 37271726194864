import api from "state/api";
import { getConfigWithBearerToken, notifyMsg } from "state/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListingsApi = createAsyncThunk("listings/getListings", async ({ page, pageSize, search, filters }) => {
  try {
    const response = await api.post(
      `listings?searchTerm=${search}&page=${page}&pageSize=${pageSize}`,
      { filters },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getListingsApi:", error.message);
  }
});

export const getListingApi = createAsyncThunk("listings/getListing", async (listingId) => {
  try {
    const response = await api.get(`listings/${listingId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getListingApi:", error.message);
  }
});

export const updateListingApi = createAsyncThunk("listings/updateListing", async ({ listingId, updatedListing }) => {
  try {
    const response = await api.put(
      `listings/${listingId}`,
      { updatedListing },
      getConfigWithBearerToken()
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateListingApi:", error.message);
  }
});

export const getListingsXlsxApi = createAsyncThunk("listings/getListingsXlsx", async ({ search, filters }) => {
  try {
    const response = await api.post(
      `listings/xlsx?searchTerm=${search}`,
      { filters },
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "listings-export";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error getListingsXlsxApi:", error.message);
  }
});

export const updateListingsFromXlsxApi = createAsyncThunk("listings/updateListingsFromXlsx", async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.put(
      "listings/xlsx",
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateListingsFromXlsxApi:", error.message);
  }
});

export const deleteListingApi = createAsyncThunk("listings/deleteListing", async ({ type, data }) => {
  try {
    const response = await api.post(
      `listings/delete/${type}`,
      { data },
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleteListing:", error.message);
  }
});

export const syncListingsApi = createAsyncThunk("listings/syncListings", async () => {
  try {
    const response = await api.get(`listings/sync`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error syncListingsApi:", error.message);
  }
});

export const getCreationListingsApi = createAsyncThunk("listings/getCreationListings", async ({ page, pageSize }) => {
  try {
    const response = await api.get(
      `/listings/creation?&page=${page}&pageSize=${pageSize}`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getCreationListingsApi:", error.message);
  }
});

export const updateCreationListingApi = createAsyncThunk("listings/updateCreationListing", async ({ listingId, ...payload }) => {
  try {
    const response = await api.put(
      `/listings/creation/${listingId}`,
      payload,
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateCreationListingApi:", error.message);
  }
});

export const deleteCreationListingsApi = createAsyncThunk("listings/deleteCreationListings", async ({ ids }) => {
  try {
    const response = await api.post(
      `/listings/creation/delete`,
      { ids },
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleteCreationListingsApi:", error.message);
  }
});

export const startListingsCreationApi = createAsyncThunk("listings/startListingsCreation", async ({ file, ...payload }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    Object.keys(payload).forEach(key => {
      formData.append(key, payload[key]);
    });

    const response = await api.post(
      `/listings/creation/start`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error startListingsCreationApi:", error.message);
  }
});

export const listingUploadCreationApi = createAsyncThunk("listings/listingUploadCreation", async ({ file }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      `/listings/creation/listing-upload`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error listingUploadCreationApi:", error.message);
  }
});

export const replaceCreationUploadApi = createAsyncThunk("listings/replaceCreationUpload", async ({ file, uploadId }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.put(
      `/listings/creation/listing-upload/${uploadId}`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error replaceCreationUploadApi:", error.message);
  }
});

export const getCreationUploadsApi = createAsyncThunk("listings/getCreationUploads", async () => {
  try {
    const response = await api.get(
      `/listings/creation/listing-upload`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getCreationUploadsApi:", error.message);
  }
});

export const deleteCreationUploadApi = createAsyncThunk("listings/deleteCreationUpload", async (uploadId) => {
  try {
    const response = await api.delete(
      `/listings/creation/listing-upload/${uploadId}`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleteCreationUploadApi:", error.message);
  }
});

export const itemSpecificsUploadApi = createAsyncThunk("listings/itemSpecificsUpload", async ({ file }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      `/listings/creation/item-specifics-upload`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error itemSpecificsUploadApi:", error.message);
  }
});

export const listingsMarketplacePushApi = createAsyncThunk("listings/listingsMarketplacePush", async (marketplace) => {
  try {
    const response = await api.post(
      `/listings/creation/marketplace-push`,
      { marketplace },
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error listingsMarketplacePushApi:", error.message);
  }
});

export const getItemTitleFormatsApi = createAsyncThunk("listings/getItemTitleFormats", async () => {
  try {
    const response = await api.get(
      `/listings/creation/title-formats`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getItemTitleFormatsApi:", error.message);
  }
});

export const addItemTitleFormatsApi = createAsyncThunk("listings/addItemTitleFormats", async (titleFormat) => {
  try {
    const response = await api.post(
      `/listings/creation/title-formats`,
      { titleFormat },
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error addItemTitleFormatsApi:", error.message);
  }
});

export const deleteItemTitleFormatsApi = createAsyncThunk("listings/deleteItemTitleFormats", async ({ categoryId }) => {
  try {
    const response = await api.delete(
      `/listings/creation/title-formats/${categoryId}`,
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleteItemTitleFormatsApi:", error.message);
  }
});

export const getItemPartNamesApi = createAsyncThunk("listings/getItemPartNames", async () => {
  try {
    const response = await api.get(
      `/listings/creation/part-names`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getItemPartNamesApi:", error.message);
  }
});

export const getItemPartNamesFileApi = createAsyncThunk("listings/getItemPartNamesFile", async () => {
  try {
    const response = await api.get(
      `/listings/creation/part-names/file`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "part-names.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error getItemPartNamesFileApi:", error.message);
  }
});

export const getCategoryToPartNameMappingApi = createAsyncThunk("listings/getCategoryToPartNameMapping", async () => {
  try {
    const response = await api.get(
      `/listings/creation/category-to-part-name-mapping`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getCategoryToPartNameMappingApi:", error.message);
  }
});

export const updateCategoryToPartNameMappingApi = createAsyncThunk("listings/updateCategoryToPartNameMapping", async (payload) => {
  try {
    const response = await api.put(
      `/listings/creation/category-to-part-name-mapping`,
      payload,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateCategoryToPartNameMappingApi:", error.message);
  }
});

export const getItemPartNamesToCategoryMappingFileApi = createAsyncThunk("listings/getItemPartNamesToCategoryMappingFile", async () => {
  try {
    const response = await api.get(
      `/listings/creation/category-to-part-name-mapping/file`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "category-to-part-name-mapping.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error getItemPartNamesToCategoryMappingFileApi:", error.message);
  }
});

export const deleteCategoryToPartNameMappingApi = createAsyncThunk("listings/deleteCategoryToPartNameMapping", async (categoryId) => {
  try {
    const response = await api.delete(
      `/listings/creation/category-to-part-name-mapping/${categoryId}`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleteCategoryToPartNameMappingApi:", error.message);
  }
});

export const itemSpecificsUploadFormatApi = createAsyncThunk("listings/itemSpecificsUploadFormat", async () => {
  try {
    const response = await api.get(
      `/listings/creation/item-specifics-upload/format`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "item-specifics-format.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error itemSpecificsUploadFormatApi:", error.message);
  }
});

export const listingsCreationUploadFormatApi = createAsyncThunk("listings/listingsCreationUploadFormat", async () => {
  try {
    const response = await api.get(
      `/listings/creation/listing-upload/format`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "applications-format.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error listingsCreationUploadFormatApi:", error.message);
  }
});

export const creationSpecificItemsFormatApi = createAsyncThunk("listings/creationSpecificItemsFormat", async () => {
  try {
    const response = await api.get(
      `/listings/creation/specific-items-format`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "creation-specific-items-format.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error creationSpecificItemsFormatApi:", error.message);
  }
});

export const getSkuMappingInfoApi = createAsyncThunk("listings/getSkuMappingInfo", async () => {
  try {
    const response = await api.get(`/listings/creation/sku-mapping`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getSkuMappingInfoApi:", error.message);
  }
});

export const uploadSkuMappingApi = createAsyncThunk("listings/uploadSkuMapping", async ({ file, multiple }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('multiple', multiple);

    const response = await api.post(
      `/listings/creation/sku-mapping`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error uploadSkuMappingApi:", error.message);
  }
});

export const getInventoryCreationInfoApi = createAsyncThunk("listings/getInventoryCreationInfo", async () => {
  try {
    const response = await api.get(`listings/creation/inventory-creation`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getInventoryCreationInfoApi:", error.message);
  }
});

export const inventoryCreationFileAnalyzerStartApi = createAsyncThunk("listings/inventoryCreationFileAnalyzerStart", async ({ file }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      `listings/creation/inventory-creation/analyzer`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error inventoryCreationFileAnalyzerStartApi:", error.message);
  }
});

export const downloadInventoryCreationItemsApi = createAsyncThunk("listings/downloadInventoryCreationItems", async () => {
  try {
    const response = await api.get(
      `listings/creation/inventory-creation/items-download`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "inventory-creation-items.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloadInventoryCreationItemsApi:", error.message);
  }
});

export const deleteSpecificListingsApi = createAsyncThunk("listings/deleteSpecificListings", async ({ file }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      `listings/delete/specific`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleteSpecificListingsApi:", error.message);
  }
});

export const deleteSpecificListingsFormatApi = createAsyncThunk("listings/deleteSpecificListingsFormat", async () => {
  try {
    const response = await api.get(
      `listings/delete/specific/format`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "delete-specific-items-format.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error deleteSpecificListingsFormatApi:", error.message);
  }
});

export const externallyAssignedProductIdentifiersDownloadApi = createAsyncThunk("listings/externallyAssignedProductIdentifiersDownload", async () => {
  try {
    const response = await api.get(
      `/listings/creation/externally-assigned-product-identifiers`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "externally-assigned-product-identifiers.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error externallyAssignedProductIdentifiersDownloadApi:", error.message);
  }
});

export const externallyAssignedProductIdentifiersUploadApi = createAsyncThunk("listings/externallyAssignedProductIdentifiersUpload", async ({ file }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      `/listings/creation/externally-assigned-product-identifiers`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error externallyAssignedProductIdentifiersUploadApi:", error.message);
  }
});