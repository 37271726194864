import React, { useState, useEffect } from "react";

import {
  Box,
  IconButton,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { Delete, Download } from "@mui/icons-material";

import Header from "components/Header";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";
import {
  getInventoryDataApi,
  deleteInventoryUploadApi,
  downloadInventoryUploadApi
} from "features/suppliers/suppliersApi";

const InventoryTimeline = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    totalSupplierInventoryResults,
    supplierUploadedInventories,
    inventoriesStatus,
    deletedInventoryStatus
  } = useSelector((state) => state.suppliers);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [selectedDeleteInventoryId, setSelectedDeleteInventoryId] = useState("");
  const [selectedDownloadInventoryInfo, setSelectedDownloadInventoryInfo] = useState({});

  useEffect(() => {
    dispatch(getInventoryDataApi({ page: paginationModel.page + 1, pageSize: paginationModel.pageSize }));
  }, [paginationModel])

  useEffect(() => {
    if (deletedInventoryStatus !== "pending") {
      dispatch(getInventoryDataApi(
        {
          page: paginationModel.page + 1,
          pageSize: paginationModel.pageSize
        }
      ))
    };
  }, [deletedInventoryStatus])

  const handleDelete = () => {
    dispatch(deleteInventoryUploadApi(selectedDeleteInventoryId));

    setDeleteDialogOpen(false);
    setSelectedDeleteInventoryId("");

    dispatch(getInventoryDataApi());
  };

  const handleDownload = () => {
    dispatch(downloadInventoryUploadApi(selectedDownloadInventoryInfo));

    setDownloadDialogOpen(false);
    setSelectedDownloadInventoryInfo("");
  };

  const handleDownloadIcon = (uploadId, supplier, fileName) => {
    setSelectedDownloadInventoryInfo({ uploadId, supplier, fileName });
    setDownloadDialogOpen(true);
  }

  const handleDeleteIcon = (id) => {
    setSelectedDeleteInventoryId(id);
    setDeleteDialogOpen(true);
  };

  const columns = [
    { field: "fileName", headerName: "File", flex: 3 },
    { field: "supplier", headerName: "Supplier", flex: 1.4 },
    { field: "source", headerName: "Source", flex: 1.4 },
    { field: "createdAt", headerName: "Added At", flex: 2 },
    {
      field: "chunked",
      headerName: "Chunked",
      flex: 1.2,
      renderCell: (params) => (
        < div
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            padding: "6px",
            fontWeight: "semibold",
            color: "white",
            backgroundColor: params.value ? "#148F59" : "#BB2722",
          }}
        >
          {params.value ? "Chunked" : "Not Chunked" }
        </div >
      )
    },
    {
      field: "expired",
      headerName: "Expired",
      flex: 1.2,
      renderCell: (params) => (
        < div
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            padding: "6px",
            fontWeight: "semibold",
            color: "white",
            backgroundColor: params.value ? "#BB2722" : "#148F59",
          }}
        >
          {params.value ? "Expired" : "Not Expired"}
        </div >
      ),
    },
    { field: "syncedStores", headerName: "Synced Stores", flex: 2.8 },
    {
      field: "action",
      headerName: "Action",
      flex: 1.2,
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="download"
            size="medium"
            onClick={() => handleDownloadIcon(params.row.id, params.row.supplier, params.row.fileName)}
          >
            <Download color="secondary" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="medium"
            onClick={() => handleDeleteIcon(params.row.id)}
          >
            <Delete color="secondary" />
          </IconButton>
        </Box>
      ),
    }
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Inventory Timeline" subtitle="Manage Your Inventory Uploads" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{
            marginTop: "15px",
            "& .MuiDataGrid-cell": {
              borderColor: "#fff",
            }
          }}
          loading={inventoriesStatus === "pending"}
          rows={supplierUploadedInventories || []}
          rowCount={totalSupplierInventoryResults}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          paginationMode="server"
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
      <DialogBox
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        titleText="Delete Confirmation"
        messageText="Are you sure you want to delete this inventory data?"
        confirmText="Confirm Delete"
      />
      <DialogBox
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        onConfirm={handleDownload}
        titleText="Download Confirmation"
        messageText="Are you sure you want to download this inventory file?"
        confirmText="Confirm Download"
      />
    </Box>
  );
};

export default InventoryTimeline;