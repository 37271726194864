import React, { useState, useEffect } from "react";

import {
  Box,
  TextField,
  Typography,
  Divider,
  Switch,
  FormControl,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import moment from "moment-timezone";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";
import {
  getAutomationsApi,
  updateAutomationsApi
} from "features/jobs/jobsApi";

const Automations = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    automations,
    updateStatus
  } = useSelector((state) => state.jobs);

  const [listingSyncingConfig, setListingSyncingConfig] = useState({
    scheduled: null,
    retryAfter: 1,
    backupInventoryHandling: false
  });

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getAutomationsApi());
  }, []);

  useEffect(() => {
    if (automations?.listingSyncing) {
      setListingSyncingConfig({
        ...automations.listingSyncing,
        scheduled: moment(automations.listingSyncing.scheduled).tz('America/Los_Angeles')
      });
    }
  }, [automations]);

  const handleSave = () => {
    dispatch(updateAutomationsApi({ listingSyncing: listingSyncingConfig }));
    setSaveDialogOpen(false);
  }

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title="Automation Jobs" subtitle="Manage Your Automation Jobs" />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Sync Automation Job" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px">Sync Schedule Control</Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <DesktopTimePicker
                label="Sync Schedule"
                slotProps={{ textField: { color: "secondary" } }}
                value={listingSyncingConfig.scheduled}
                onChange={(newValue) => setListingSyncingConfig({
                  ...listingSyncingConfig,
                  scheduled: newValue
                })}
                sx={{
                  width: "45%"
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
              <TextField
                type="number"
                label="Retry After (hrs)"
                placeholder="Retry After..."
                value={listingSyncingConfig.retryAfter}
                onChange={(e) => setListingSyncingConfig({
                  ...listingSyncingConfig,
                  retryAfter: e.target.value
                })}
                color="secondary"
                sx={{
                  width: "45%"
                }}
                inputProps={{ min: 1, max: 24 }}
              />
            </Box>
            <Divider middle />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Typography fontWeight="bold" variant="h5" p="3px">Backup Inventory Handling</Typography>
              <FormControl
                component="fieldset"
              >
                <Switch
                  color="secondary"
                  checked={listingSyncingConfig.backupInventoryHandling}
                  onChange={() => setListingSyncingConfig({
                    ...listingSyncingConfig,
                    backupInventoryHandling: !listingSyncingConfig.backupInventoryHandling
                  })}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px",
            alignSelf: "flex-end"
          }}
          onClick={() => setSaveDialogOpen(true)}
          loading={updateStatus === "pending"}
        >
          Save
        </LoadingButton>
        <DialogBox
          open={saveDialogOpen}
          onClose={() => setSaveDialogOpen(false)}
          onConfirm={handleSave}
          titleText="Save Confirmation"
          messageText="Are you sure you want to save these changes?"
          confirmText="Save"
        />
      </Box>
    </Box>
  );
}

export default Automations;
