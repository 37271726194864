import api from "state/api";
import { getConfigWithBearerToken, notifyMsg } from "state/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getStoresApi = createAsyncThunk("stores/getStores", async ({ type, marketplace, page, pageSize }) => {
  try {
    const response = await api.get(`/stores/config?type=${type}&page=${page}&pageSize=${pageSize}&marketplace=${marketplace ? marketplace : ""}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getStoresApi:", error.message);
  }
});

export const getStoreInfoApi = createAsyncThunk("stores/getStoreInfo", async (storeId) => {
  try {
    const response = await api.get(`/stores/config/${storeId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getStoreInfoApi:", error.message);
  }
});

export const getSourcingSetupApi = createAsyncThunk("stores/getSourcingSetup", async (storeId) => {
  try {
    const response = await api.get(`/stores/sourcing-setup/${storeId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getSourcingSetupApi:", error.message);
  }
});

export const updateSourcingSetupApi = createAsyncThunk("stores/updateSourcingSetup", async ({ storeId, sourcingSetup }) => {
  try {
    const response = await api.put(
      `/stores/sourcing-setup/${storeId}`,
      { sourcingSetup },
      getConfigWithBearerToken()
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateSourcingSetupApi:", error.message);
  }
});

export const updateStoreInfoApi = createAsyncThunk("stores/updateStoreInfo", async ({ storeId, ...payload }) => {
  try {
    const response = await api.put(
      `/stores/config/${storeId}`,
      payload,
      getConfigWithBearerToken()
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateStoreInfoApi:", error.message);
  }  
});

export const updateStoreListingDescriptionTemplateApi = createAsyncThunk("stores/updateStoreListingDescriptionTemplate", async ({ storeId, file }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.put(
      `/stores/config/template/${storeId}`,
      formData,
      getConfigWithBearerToken({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateStoreListingDescriptionTemplateApi:", error.message);
  }  
});

export const editStoreInfoApi = createAsyncThunk("stores/editStoreInfo", async ({ storeId, info }) => {
  try {
    const response = await api.put(`/stores/config/edit/${storeId}`, info, getConfigWithBearerToken());
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error editStoreInfoApi:", error.message);
  }
});

export const deleteStoreApi = createAsyncThunk("stores/deleteStore", async (storeId) => {
  try {
    const response = await api.delete(
      `/stores/config/${storeId}`,
      getConfigWithBearerToken()
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleteStoreApi:", error.message);
  } 
});

export const updateStoreSyncSwitchApi = createAsyncThunk("stores/updateStoreSyncSwitch", async ({ storeId, syncSwitch }) => {
  try {
    const response = await api.put(
      `/stores/config/sync-switch/${storeId}`,
      { syncSwitch },
      getConfigWithBearerToken()
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateStoreSyncSwitchApi:", error.message);
  }
});

export const createStoreApi = createAsyncThunk("stores/createStore", async (credentials) => {
  try {
    const response = await api.post("/stores/config/create", credentials, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error createStoreApi:", error.message);
  }
});

export const updateFetchSwitchApi = createAsyncThunk("stores/updateFetchSwitch", async ({ storeId, fetchSwitch, specifiedItemIds, itemIdsFile }) => {
  try {
    const formData = new FormData();
    formData.append('fetchSwitch', fetchSwitch);
    formData.append('specifiedItemIds', specifiedItemIds);
    formData.append('file', itemIdsFile);

    const response = await api.put(
      `/stores/config/fetch/${storeId}`,
      formData,
      getConfigWithBearerToken({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );

    return response.data;
  } catch (error) {
    console.error("Error updateFetchSwitchApi:", error.message);
  }
});

export const itemIdFileFormatApi = createAsyncThunk("stores/itemIdFileFormat", async () => {
  try {
    const response = await api.get(`stores/config/fetch/file-format`, {
      responseType: 'blob',
      ...getConfigWithBearerToken()
    });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "itemIds-format.xlsx";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error itemIdFileFormatApi:', error.message);
  }
});